import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Card, CardBody } from 'reactstrap';

import { signInRequest } from '~/store/modules/auth/actions';
import Loader from '~/components/Loader/Loader';

import Highcharts from 'highcharts/highstock'

import Chart from '../../components/charts/Chart'

import api from '~/services/api';

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.loadUsers = this.loadUsers.bind(this);

        this.state = {
            usersData: {},
            loading: true,
            todayData: {},
            options: {
                xAxis: {
                    categories: ['A', 'B', 'C']
                },
                series: [{
                  data: [1, 2, 3]
                },
                {
                  data: [2, 3, 1]
                },
                {
                  data: [3, 2, 1]
                }]
              }
        }

        this.loadUsers();
    }

    loadUsers = async () => {
        const { data } = await api.get('dashboard');

        const { graphDayData, todayData, userActsData, userActTypesData } = data;

        const categories = []

        const androidTotal = { data: [], name: 'Android'}
        const iosTotal = { data: [], name: 'IOS'}
        const finishUserTotal = { data: [], name: 'Finalizados'}
        const newUserTotal = { data: [], name: 'Novos'}
        const stravaTotal = { data: [], name: 'Strava'}
        const hasPushTotal = { data: [], name: 'Notification'}
        const createdTotal = { data: [], name: 'Criados'}

        
        const uActCategories = []
        const uActTotalCreated = { data: [], name: 'Total Criada'}
        const uActExplorerTraining = { data: [], name: 'Via Explorar'}
        const uActStrava = { data: [], name: 'Via Strava'}
        const uActManual = { data: [], name: 'Manual'}
        const uActTrainingPlan = { data: [], name: 'Plano de Treino'}

        const uActTypesCategories = []
        const uActTypeTotalCreated = { data: [], name: 'Total Criada'}
        const uActTypeCanoeing = { data: [], name: 'Canoeing'}
        const uActTypeCardio = { data: [], name: 'Cardio'}
        const uActTypeCrossfit = { data: [], name: 'Crossfit'}
        const uActTypeDrills = { data: [], name: 'Drills'}
        const uActTypeEBikeRide = { data: [], name: 'EBikeRide'}
        const uActTypeElliptical = { data: [], name: 'Elliptical'}
        const uActTypeHandcycle = { data: [], name: 'Handcycle'}
        const uActTypeHike = { data: [], name: 'Hike'}
        const uActTypeRide = { data: [], name: 'Ride'}
        const uActTypeRowing = { data: [], name: 'Rowing'}
        const uActTypeRun = { data: [], name: 'Run'}
        const uActTypeStairStepper = { data: [], name: 'StairStepper'}
        const uActTypeSwim = { data: [], name: 'Swim'}
        const uActTypeVirtualRide = { data: [], name: 'VirtualRide'}
        const uActTypeVirtualRun = { data: [], name: 'VirtualRun'}
        const uActTypeWalk = { data: [], name: 'Walk'}
        const uActTypeWeightTraining = { data: [], name: 'WeightTraining'}
        const uActTypeWheelchair = { data: [], name: 'Wheelchair'}
        const uActTypeWorkout = { data: [], name: 'Workout'}
        const uActTypeYoga = { data: [], name: 'Yoga'}
        

        graphDayData.slice((data.length - 10), data.length).forEach(day => {
            categories.push(day.date)
            androidTotal.data.push(day.android)
            iosTotal.data.push(day.ios)
            finishUserTotal.data.push(day.finishUser)
            newUserTotal.data.push(day.newUser)
            stravaTotal.data.push(day.stravaIntegrate)
            hasPushTotal.data.push(day.hasPush)
            createdTotal.data.push(day.totalCreated)
        });

        userActsData.slice((data.length - 10), data.length).forEach(day => {
            uActCategories.push(day.date)
            uActTotalCreated.data.push(day.totalCreated)
            uActExplorerTraining.data.push(day.explorer_training)
            uActStrava.data.push(day.strava)
            uActManual.data.push(day.manual)
            uActTrainingPlan.data.push(day.training_plan)
        });

        userActTypesData.slice((data.length - 10), data.length).forEach(day => {
            uActTypesCategories.push(day.date)
            uActTypeTotalCreated.data.push(day.totalCreated)
            uActTypeCanoeing.data.push(day.canoeing)
            uActTypeCardio.data.push(day.cardio)
            uActTypeCrossfit.data.push(day.crossfit)
            uActTypeDrills.data.push(day.drills)
            uActTypeEBikeRide.data.push(day.eBikeRide)
            uActTypeElliptical.data.push(day.elliptical)
            uActTypeHandcycle.data.push(day.handcycle)
            uActTypeHike.data.push(day.hike)
            uActTypeRide.data.push(day.ride)
            uActTypeRowing.data.push(day.rowing)
            uActTypeRun.data.push(day.run)
            uActTypeStairStepper.data.push(day.stairStepper)
            uActTypeSwim.data.push(day.swim)
            uActTypeVirtualRide.data.push(day.virtualRide)
            uActTypeVirtualRun.data.push(day.virtualRun)
            uActTypeWalk.data.push(day.walk)
            uActTypeWeightTraining.data.push(day.weightTraining)
            uActTypeWheelchair.data.push(day.wheelchair)
            uActTypeWorkout.data.push(day.workout)
            uActTypeYoga.data.push(day.yoga)
        });

        const options = {
            title: {
                text: 'Usuários criados (últimos 2 meses)'
            },
            xAxis: {
                categories
            },
            series: [ androidTotal, iosTotal, finishUserTotal, newUserTotal, stravaTotal, hasPushTotal, createdTotal]
          }

          const userActsOptions = {
            title: {
                text: 'Atividades de usuários  (últimos 2 meses)'
            },
            xAxis: {
                categories: uActCategories
            },
            series: [ uActExplorerTraining, uActStrava, uActManual, uActTrainingPlan, uActTotalCreated ]
          }

          const userActTypesOptions = {
            title: {
                text: 'Atividades de usuários por tipo  (últimos 2 meses)'
            },
            xAxis: {
                categories: uActTypesCategories
            },
            series: [ uActTypesCategories, uActTypeTotalCreated, uActTypeCanoeing, uActTypeCardio, uActTypeCrossfit, uActTypeDrills, 
                        uActTypeEBikeRide, uActTypeElliptical, uActTypeHandcycle, uActTypeHike, uActTypeRide, uActTypeRowing, uActTypeRun, 
                        uActTypeStairStepper, uActTypeSwim, uActTypeVirtualRide, uActTypeVirtualRun, uActTypeWalk, uActTypeWeightTraining, 
                        uActTypeWheelchair, uActTypeWorkout, uActTypeYoga ]
          }

        // this.setState({usersData: data, loading: false, options, todayData  });
        this.setState({usersData: data, loading: false, options, userActsOptions, userActTypesOptions, todayData  });
    }

    newDate = (strDate) => {
        const thisNewDate = new Date(strDate);
        return thisNewDate.toString('MM/dd/yyyy');
    }

    render() {

        return (
          <React.Fragment>
                  { /* preloader */}
                  {false && <Loader />}
      
                  <Row>
                      <Col>
                          <div className="page-title-box">
                              <Row>
                                  <Col lg={7}>
                                      <h4 className="page-title">Dashboard</h4>
                                  </Col>
                                  <Col lg={5} className="mt-lg-3 mt-md-0">
                                      
                                  </Col>
                              </Row>
                          </div>
                      </Col>
                  </Row>
                  <Row>
                      <Col lg={12}>
                          <Card>
                              <CardBody>

                                <Row>
                                    <Col lg={2}>
                                        <Card> <CardBody> <div className="text-left"> <h3 className="mb-1"> {this.state.todayData.totalCreated} </h3> <p className="text-muted mb-1">Usuários Criados</p> </div> </CardBody> </Card>
                                    </Col>
                                    <Col lg={2}>
                                        <Card> <CardBody> <div className="text-left"> <h3 className="mb-1"> {this.state.todayData.finishUser} </h3> <p className="text-muted mb-1">Usuários Finalizados</p> </div> </CardBody> </Card>
                                    </Col>
                                    <Col lg={2}>
                                        <Card> <CardBody> <div className="text-left"> <h3 className="mb-1"> {this.state.todayData.newUser} </h3> <p className="text-muted mb-1">Usuários Novos</p> </div> </CardBody> </Card>
                                    </Col>
                                    <Col lg={2}>
                                        <Card> <CardBody> <div className="text-left"> <h3 className="mb-1"> {this.state.todayData.stravaIntegrate} </h3> <p className="text-muted mb-1">Integrados com Strava</p> </div> </CardBody> </Card>
                                    </Col>
                                    <Col lg={1}>
                                        <Card> <CardBody> <div className="text-left"> <h3 className="mb-1"> {this.state.todayData.android} </h3> <p className="text-muted mb-1">Android</p> </div> </CardBody> </Card>
                                    </Col>
                                    <Col lg={1}>
                                        <Card> <CardBody> <div className="text-left"> <h3 className="mb-1"> {this.state.todayData.ios} </h3> <p className="text-muted mb-1">IOS</p> </div> </CardBody> </Card>
                                    </Col>
                                    <Col lg={2}>
                                        <Card> <CardBody> <div className="text-left"> <h3 className="mb-1"> {this.state.todayData.hasPush} </h3> <p className="text-muted mb-1">Usuários Push Token</p> </div> </CardBody> </Card>
                                    </Col>
                                </Row>


                                  {this.state.usersData && <Chart options={this.state.options} highcharts={Highcharts} title="Dados de usuário" /> }

                                  {this.state.usersData && <Chart options={this.state.userActsOptions} highcharts={Highcharts} title="Dados de atividades de usuário" /> }

                                  {this.state.usersData && <Chart options={this.state.userActTypesOptions} highcharts={Highcharts} title="Dados de atividades de usuário por tipo" /> }

                                  {/* <Bar data={data} width={50} height={10} options={{ maintainAspectRatio: true }} /> */}

                                  

                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
          </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    // const { user, loading, error } = state.Auth;
    
    // const { signed, loading } = state.auth;

    // return { user, loading, error, signed };
    // return { signed, loading }
    return;
};
export default connect(mapStateToProps, { signInRequest })(Dashboard);