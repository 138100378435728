import React from 'react';

import iconBike from '~/assets/img/training_types/icone-bike.png';
import iconRun from '~/assets/img/training_types/icone-corrida.png';
import iconDrills from '~/assets/img/training_types/icone-educativo.png';
import iconWeight from '~/assets/img/training_types/icone-exercicio.png';
import iconSwin from '~/assets/img/training_types/icone-natacao.png';
import iconOthers from '~/assets/img/training_types/icone-outros.png';

const TRAINING_CATEGORIES = {
  Run: 'Corrida',
  WeightTraining: 'Treino de Força',
  Workout: 'Treino',
  Ride: 'Bicicleta',
  Swim: 'Natação',
  Drills: 'Educativo'
};

export function formatTrainingCategory(categoryStr) {
  const resp = TRAINING_CATEGORIES[categoryStr]
    ? TRAINING_CATEGORIES[categoryStr]
    : 'Outros';

  return resp;
}

export function formatDistance(distance) {
  if (distance) {
    const distanceTotal = distance * 1000

    const result = distanceTotal < 1000 ? `${distanceTotal}m` : `${distance.toFixed(1)}km`

    return result;
  }
  
  return '-';
}

export function formatTrainingPlanRequestStatus(status) {
  switch (status) {
      case 'OPEN':
        return 'Aberto'
      case 'FINISHED':
        return 'Concluído'
      case 'CREATED':
        return 'Novo'
      case 'CANCELED':
        return 'Cancelado'
      case 'PENDING':
        return 'Pendente'
      default:
          return '-'
  }
}

export function formatTrainingIntensity(intensity) {
  switch (intensity) {
      case 1:
          return 'Iniciante'

      case 2:
          return 'Intermediário'

      case 3:
          return 'Avançado'
      default:
          return '-'
  }
}

export function formatZone(zone) {
  if (zone) {
    switch (zone) {
      case 'ZONA-1':
        return `Zona 1 (Leve)`
      case 'ZONA-2':
        return `Zona 2 (Moderado)`
      case 'ZONA-3':
      return `Zona 3 (Pouco Difícil)`
      case 'ZONA-4':
        return `Zona 4 (Difícil)`
      case 'ZONA-5a':
        return `Zona 5a (Muito Difícil)`
      case 'ZONA-5b':
        return `Zona 5b (Extremamente Difícil)`
      case 'ZONA-5c':
        return `Zona 5c (Limite Máximo)`

      default:
        return `Zona`
    }
  }
}

export function formatTrainingIcon(type) {
  let currIcon = null;

  switch (type) {
    case 'Run':
      currIcon = iconRun;
      break;
    case 'WeightTraining':
      currIcon = iconWeight;
      break;
    case 'Workout':
      currIcon = iconWeight;
      break;
    case 'Ride':
      currIcon = iconBike;
      break;
    case 'Swim':
      currIcon = iconSwin;
      break;
    case 'Drills':
      currIcon = iconDrills;
      break;
    default:
      currIcon = iconOthers;
      break;
  }

  return (<img src={currIcon} alt="Treino de Corrida" style={{width: 20, height: 20}} />)
}

export function formatDuration(duration, type = null) {
  let hoursStr = '';
  let minutesStr = '';
  let secondsStr = '';

  let hours = 0;
  let minutes = 0;
  let sec = 0;

  try {
    if (duration && duration > 0) {
      if (duration > 60) {
        hours = duration / 60;
        minutes = (hours % 1) * 60;
      } else {
        minutes = duration;
      }
      sec = (minutes % 1) * 60;

      hoursStr = hours < 10 ? `0${Math.floor(hours)}` : `${Math.floor(hours)}`;
      minutesStr = minutes < 10 ? `0${Math.floor(minutes)}` : `${Math.floor(minutes)}`;
      secondsStr = sec < 10 ? `0${Math.floor(sec)}` : `${Math.floor(sec)}`;

      switch (type) {
        case 'time':
          return `${hoursStr}:${minutesStr}:${secondsStr}`;
        case 'pace':
          return `${minutesStr}:${secondsStr}`;
        default: {
          if (hours > 0) {
            return `${hoursStr}h ${minutesStr}m`;
          }
          return `${minutesStr}m ${secondsStr}s`;
        }
      }
    }
  } catch (error) {
  }

  return '--';
}

export function formatTrainingPlanRequestObjective(value) {
  switch (value) {
      case 'quero melhorar':
          return 'melhorar'
      case 'primeira vez':
          return 'completar'
      default:
          return ''
  }
}

export function formatRequestKeepFitType(value) {
  switch (value) {
      case 'Quero apenas manter minha forma':
          return 'manutenção'
      case 'Tenho um objetivo próximo com data marcada':
          return 'manutenção com objetivo'
      default:
          return ''
  }
}

export const formatBigText = (description) => {
  if (description.length > 30) {
      return `${description.slice(0, 29)}...`
  } 

  return description
}
