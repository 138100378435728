import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import history from '~/services/history';
import api, { api2 } from '~/services/api';

import { signInSuccess, signFailure } from './actions';


export function* signIn({ payload }) {

  try {
    const { email, password } = payload;

    const response = yield call(api.post, 'sessions_adm', {
      email, password
    });

    const { auth, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${auth.token}`;
  
    yield put(signInSuccess(auth.token, user));
  
    history.push('/solicitacoes-plano-treino')
  } catch (error) {
    toast.error('Falha na autenticação, verifique seus dados.')
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { username, email, password } = payload;

    yield call(api.post, 'users', {
      username, email, password, password_confirmation: password
    });
  
    history.push('/')
  } catch (error) {
    toast.error('Falha no cadastro, verifique seus dados.')
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if(token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api2.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/')
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_OUT', signOut),
]); 