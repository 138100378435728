import React, {useState, useEffect} from 'react';

import { Button, Row, Col, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,  Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';

import api from '~/services/api';

export default function TrainingPlanNewModal(props) {

  const [show, setShow] = useState(props.open);
  const [folders, setFolders] = useState([]);
  const [trainingPlan, setTrainingPlan] = useState({id: -1});
  const [showDDFolder, setShowDDFolder] = useState(false);
  const [activityTypes, setActivityTypes] = useState([{id: 'DURATION', name: "Duração"}, {id: 'DISTANCE', name: "Distância"}]);
  const [currentActivityType, setCurrentActivityType] = useState({name: 'Selecionar Tipo', id: -1});
  const [showDDActivityType, setShowDDActivityType] = useState(false);
  const [currentFolder, setCurrentFolder] = useState({name: 'Selecionar Pasta', id: -1});

  const [categories, setCategories] = useState([{id: 'DURATION', name: "Duração"}, {id: 'DISTANCE', name: "Distância"}]);
  const [currentCategory, setCurrentCategory] = useState({name: 'Selecionar Categoria', id: -1});
  const [showDDCategory, setshowDDCategory] = useState(false);

  useEffect(() => {
    loadData();
  }, [])

  async function loadData() {
    Promise.all([api.get('folders'), api.get('training_categories')])
        .then(([foldersResp, categoriesResp])  => {
            setFolders(foldersResp.data);
            setCategories(categoriesResp.data)
        });
    }

  const handleTrainingPlanNew = async () => {
    if (currentActivityType.id !== -1) {  }

    if (currentCategory.id !== -1) 
        trainingPlan.training_category_id = currentCategory.id;

    if (currentFolder.id !== -1) 
        trainingPlan.folder_id = currentFolder.id;

    props.toggle(!show)
    props.loadedItem(trainingPlan, 'trainingPlan');
    props.changeTab('3');
    await props.setTrainingPlan(trainingPlan)
  }

  return (
    <Modal isOpen={show} toggle={() => props.toggle(!show)} style={{maxWidth: '1600px', width: '40%', maxHeight: '600px', height: '60%'}}>
      <ModalHeader toggle={() => props.toggle(!show)}>Criar Plano de Treino</ModalHeader>

      <ModalBody>
              <Row>
                  <Col lg={12}>
                      <Form onSubmit={handleTrainingPlanNew} model={trainingPlan}>
                          <FormGroup>
                              <Label for="exampleAddress2">Nome </Label>
                              <Input type="text" name="address2" id="exampleAddress2" placeholder="Nome" onChange={(e) => setTrainingPlan({...trainingPlan, name: e.target.value})} />
                          </FormGroup>
                          <FormGroup>
                              <Label for="exampleAddress2">Nome interno</Label>
                              <Input type="text" name="address2" id="exampleAddress2" placeholder="Nome interno" onChange={(e) => setTrainingPlan({...trainingPlan, internal_name: e.target.value})} />
                          </FormGroup>
                          {/* <Row form>
                              <Col md={6}>
                                  <FormGroup>
                                      <Label for="exampleDate">Iniciar em</Label>
                                      <Input type="date" name="startDate" id="startDate" placeholder="Iniciar em" onChange={(e) => setTrainingPlan({...trainingPlan, start_date: e.target.value})} />
                                  </FormGroup>
                              </Col>
                              <Col md={6}>
                                  <FormGroup>
                                      <Label for="exampleDate">Finalizar em</Label>
                                      <Input type="date" name="endDate" id="endDate" placeholder="Finalizar em"  onChange={(e) => setTrainingPlan({...trainingPlan, end_date: e.target.value})} />
                                  </FormGroup>
                              </Col>
                          </Row> */}
                          <FormGroup>
                              <Label for="exampleText">Descrição</Label>
                              <Input type="textarea" name="text" id="exampleText" placeholder="Descrição" onChange={(e) => setTrainingPlan({...trainingPlan, description: e.target.value})} />
                          </FormGroup>
                          <Row form>
                              {/* <Col md={4}>
                                  <FormGroup>
                                      <Dropdown isOpen={showDDActivityType} toggle={() => setShowDDActivityType(!showDDActivityType)} >
                                          <DropdownToggle style={{width: 200}}> {currentActivityType.name} </DropdownToggle>
                                          <DropdownMenu
                                              modifiers={{
                                              setMaxHeight: { enabled: true, order: 890, fn: (data) => {
                                                  return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, }}}}}} >
                                                  {activityTypes.map((elem, index) => { return ( <DropdownItem onClick={() => setCurrentActivityType(elem)}>{elem.name}</DropdownItem> ) })}
                                          </DropdownMenu>
                                      </Dropdown>
                                  </FormGroup>
                              </Col>
                              <Col md={4}>
                                  <FormGroup>
                                      <Dropdown isOpen={showDDCategory} toggle={() => setshowDDCategory(!showDDCategory)} >
                                          <DropdownToggle style={{width: 200, marginRight: 10}}> {currentCategory.name} </DropdownToggle>
                                          <DropdownMenu
                                              modifiers={{
                                              setMaxHeight: { enabled: true, order: 890, fn: (data) => {
                                                  return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, }}}}}} >
                                                  {categories.map((elem, index) => { return ( <DropdownItem onClick={() => setCurrentCategory(elem)}>{elem.name}</DropdownItem> ) })}
                                          </DropdownMenu>
                                      </Dropdown>
                                  </FormGroup>
                              </Col> */}
                              <Col md={4}>
                                  <FormGroup>
                                      <Dropdown isOpen={showDDFolder} toggle={() => setShowDDFolder(!showDDFolder)} >
                                          <DropdownToggle style={{width: 200, marginRight: 10}}> {currentFolder.name} </DropdownToggle>
                                          <DropdownMenu
                                              modifiers={{
                                              setMaxHeight: { enabled: true, order: 890, fn: (data) => {
                                                  return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, }}}}}} >
                                                  {folders.map((elem, index) => { return ( <DropdownItem key={`keys_${index}`} onClick={() => setCurrentFolder(elem)}>{elem.name}</DropdownItem> ) })}
                                          </DropdownMenu>
                                      </Dropdown>
                                  </FormGroup>
                              </Col>
                          </Row>
                      </Form>
                  </Col>
              </Row>
      </ModalBody>
      <ModalFooter>
          <Button color="danger" onClick={() => props.toggle(!show)}>Cancelar</Button>{' '}
          <Button color="primary" onClick={() => handleTrainingPlanNew()}>Continuar</Button>
      </ModalFooter>
    </Modal>
  );
}
