import React from 'react';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import TrainingItem from './taskCalendarItem'

const Container = styled.div`
  margin: 0px;
  border: 1px solid lightgray;
  border-radius: 2px;
  height: '100%';
  display: flex;
  flex-direction: column;
  background-color: 'red';
`

const TaskList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? '#e1e1e1' : 'white')};
  flex-grow: 1;
  min-height: 100px;
`

export default class trainingCalendarItem extends React.Component {
  render() {
    const monthLabel = this.props.column.label === 1 && this.props.column.month ? `- ${this.props.column.month}` : ''
    const dayLabel = `${this.props.column.label} ${monthLabel}`

    return (
      <Col style={{ backgroundColor: '#e1e1e1', minHeight: 150, padding: 0 }}>
        <div style={{ backgroundColor: '#3987FF', width: '100%', height: 25, padding: 3 }}><p style={{ color: '#f1f1f1', fontWeight: 'bold' }}>  {dayLabel} </p></div>
        <div style={{ backgroundColor: '#d9d9d9', padding: 2, margin: 1 }}>
          <Container>
            <Droppable
              droppableId={this.props.column.id}
              trainingGroupID='666777'>
              {(provided, snapshot) => (
                <TaskList
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}>

                  {
                    this.props &&
                    this.props.trainings &&
                    this.props.trainings.length > 0 &&
                    this.props.column.id &&
                    this.props.trainingDetailAction &&
                    this.props.removeItemAction &&
                    this.props.showItemAction &&
                    this.props.trainings.map((training, index) => (
                      <TrainingItem
                        key={index}
                        training={training}
                        columnID={this.props.column.id}
                        trainingDetailAction={this.props.trainingDetailAction}
                        removeItemAction={this.props.removeItemAction}
                        showItemAction={this.props.showItemAction}
                        index={index}
                      />
                    ))
                  }

                  {provided.placeholder}
                </TaskList>
              )}
            </Droppable>
          </Container>
        </div>
      </Col>
    )
  }
}
