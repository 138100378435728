/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { StyledTrack } from './styles';

function Track(props, state) {
  return (
    <StyledTrack {...props} index={state.index} />
  );
}

export default Track;
