import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, CustomInput, FormFeedback, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap';
import api from '~/services/api';
import Loader from '~/components/Loader/Loader'

function ModalCreate({ showModal, toggle, loadCoupons }) {
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [vindiPlanIds, setVindiPlanIds] = useState('');
  const [type, setType] = useState('MONETARY');
  const [value, setValue] = useState('');
  const [usageLimit, setUsageLimit] = useState('');
  const [quantityPaymentsApplicable, setQuantityPaymentsApplicable] = useState('1');
  const [restrictedEmails, setRestrictedEmails] = useState('');
  const [active, setActive] = useState(true);
  const [emptyCode, setEmptyCode] = useState(false)
  const [loading, setLoading] = useState(false);

  async function handleSubmit() {
    try {
      setLoading(true)

      const data = {
        code: code,
        description: description,
        vindi_plan_ids: vindiPlanIds,
        type: type,
        value: parseFloat(value),
        usage_limit: parseInt(usageLimit),
        quantity_payments_applicable: parseInt(quantityPaymentsApplicable),
        restricted_emails: restrictedEmails,
        active: active
      }

      if (!data.code) setEmptyCode(true)

      if (!data.code) {
        setLoading(false)
        return
      }

      await api.post('coupons', data)

      setEmptyCode(false)

      setLoading(false)

      toast.success('Cupom cadastrado com sucesso')

      loadCoupons(1)
    } catch (error) {
      setLoading(false)

      if (error.response.data?.length > 0 && error.response.data[0]?.message === 'code_already_exists') {
        toast.error('Código já cadastrado, tente outro')
      } else {
        toast.error('Ocorreu um erro ao cadastrar o cupom')
      }
    }
  }

  return (
    <div>
      <Modal isOpen={showModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Cadastrar cupom de desconto</ModalHeader>
        <ModalBody>
          {loading && <Loader />}
          <Form>
            <FormGroup>
              <Label for="code">Digite o código</Label>
              <Input type="text" name="code" id="code" value={code} onChange={e => setCode(e.target.value.trim().toUpperCase())} invalid={emptyCode} />
              <FormFeedback>Código é obrigatório</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="description">Descrição do cupom (Apenas para informações internas)</Label>
              <Input type="textarea" name="description" id="description" value={description} onChange={e => setDescription(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label for="vindi_plan_ids">Códigos dos planos da Vindi aplicáveis (Separar por vírgula*. Deixe em branco para aplicar a qualquer pagamento)</Label>
              <Input type="text" name="vindi_plan_ids" id="vindi_plan_ids" value={vindiPlanIds} onChange={e => setVindiPlanIds(e.target.value.trim())} />
            </FormGroup>
            <FormGroup>
              <Label for="type">Tipo de cupom</Label>
              <Input type="select" name="type" id="type" value={type} onChange={e => setType(e.target.value)}>
                <option value="MONETARY">Monetário</option>
                <option value="PERCENTAGE">Percentual</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="value">Valor do cupom (O cupom percentual pode chegar a até 100%, para casos de cortesias)</Label>
              <InputGroup>
                <Input type="number" name="value" id="value" value={value} onChange={e => setValue(e.target.value)} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>{type === 'MONETARY' ? 'R$' : '%'}</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="usage_limit">Limite de uso</Label>
              <Input type="number" name="usage_limit" id="usage_limit" value={usageLimit} onChange={e => setUsageLimit(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label for="quantity_payments_applicable">Aplicável a quantos pagamentos (Deixe em branco para aplicar em todos os pagamentos da assinatura)</Label>
              <Input type="number" name="quantity_payments_applicable" id="quantity_payments_applicable" value={quantityPaymentsApplicable} onChange={e => setQuantityPaymentsApplicable(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label for="restricted_emails">Restrição por e-mail? (Separar por vírgula*. Deixe em branco para não restringir o uso)</Label>
              <Input type="textarea" name="restricted_emails" id="restricted_emails" value={restrictedEmails} onChange={e => setRestrictedEmails(e.target.value.trim())} />
            </FormGroup>
            <FormGroup>
              <CustomInput type="switch" id="active" name="active" label="Cupom ativo?" checked={active} onChange={() => setActive(prev => !prev)} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancelar</Button>{' '}
          <Button color="primary" onClick={handleSubmit}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ModalCreate;