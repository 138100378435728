import moment from 'moment';


const MONTHS = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

export default async function buildData(trainings, weeksCount, trainingPlan = null, userActivities = null, contextType = 'DEFAULT', initDate = null) {
  const today = new Date();


  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }


  let firstDay = getMonday(today);

  if (!initDate && (trainingPlan && trainingPlan.length > 0)) {
    firstDay = getMonday(new Date(trainingPlan[0].start_date))
  } else if (initDate) {
    firstDay = getMonday(new Date(initDate))
  }

  const DAYS_ON_WEEK_INDEX = (weeksCount * 7) - 1;
  const TOTAL_COLUMNS = DAYS_ON_WEEK_INDEX + 1; // mais a coluna de treinos
  const result = {}

  const [trainingsToSelectJSON, trainingIds] = await formatTrainingJSON(trainings, true)

  let trainingsPTScaffold = undefined;

  if ((trainingPlan && trainingPlan.length > 0) || (userActivities && userActivities.length > 0)) {

    let trainingsPT = {}
    let trainingsUsr = {}

    if (trainingPlan && trainingPlan.length > 0) {
      const { trainings, trainingsScaffold } = await buildTrainingsFromTP(trainingPlan)
      trainingsPTScaffold = trainingsScaffold;

      const [trainingsJSON, tIds] = await formatTrainingJSON(trainings, false)

      trainingsPT = JSON.parse(trainingsToSelectJSON.toString());
      trainingsUsr = JSON.parse(trainingsJSON.toString());
    }

    if (userActivities && userActivities.length > 0) {
      const [usrActivitiesToSelectJSON, usrActivitiesIds, usrActivitiesScaffoldStr] = await formatUserActivityJSON(userActivities, false, trainingsPTScaffold)

      trainingsPTScaffold = usrActivitiesScaffoldStr

      const usrActivities = JSON.parse(usrActivitiesToSelectJSON.toString().replaceAll("\n", ""));

      result.trainings = { ...trainingsPT, ...trainingsUsr, ...usrActivities }
    } else {
      result.trainings = { ...trainingsPT, ...trainingsUsr }
    }


  } else {
    result.trainings = JSON.parse(trainingsToSelectJSON.toString());
  }

  let lastDateOfPT = null
  if (trainingsPTScaffold) {
    let trainingsPTScaffoldKeys = Object.keys(trainingsPTScaffold)

    trainingsPTScaffoldKeys = trainingsPTScaffoldKeys.sort((a, b) => (moment(a).isAfter(moment(b))) ? 1 : -1)

    lastDateOfPT = moment(trainingsPTScaffoldKeys[trainingsPTScaffoldKeys.length - 1]).add(7, 'days')
  }

  let columnsJsonStr = `{`;
  let finishBuildDays = false;
  for (let index = 0; index <= DAYS_ON_WEEK_INDEX; index++) {

    const currentDate = firstDay.addDays(index);

    if (lastDateOfPT && moment(currentDate).isAfter(lastDateOfPT)) {
      finishBuildDays = true
    }

    let trainingIdsStr = ''

    if (trainingsPTScaffold) {
      const currentDateStr = formatDate(currentDate)

      if (trainingsPTScaffold[currentDateStr]) {
        trainingsPTScaffold[currentDateStr].forEach((trn, tIdx) => {
          const comma = (tIdx + 1) === trainingsPTScaffold[currentDateStr].length ? '' : ', '

          const label = trn.obj_type === 'USR_ACT' ? 'ua-' : 't-'
          trainingIdsStr += `"${label}${trn.id}" ${comma}`
        });
      }
    }

    const currDay = currentDate.getDate();
    const currMonth = currentDate.getMonth() + 1;
    const currYear = currentDate.getFullYear();

    const currDateSrt = currYear + '-' + currMonth + '-' + currDay;

    const label = contextType === 'DEFAULT' ? `${index + 1}` : `${currDay}`;
    const month = contextType === 'DEFAULT' ? `` : `${MONTHS[currentDate.getMonth()]}`;

    if ((index) === DAYS_ON_WEEK_INDEX || finishBuildDays) {
      columnsJsonStr += `"d-${index}": {"id": "d-${index}", "day": ${currDay}, "label": ${label}, "month": "${month}", "date": "${currDateSrt}", "trainingIds": [${trainingIdsStr}], "title": "Title-${currDay}" }`;
    } else {
      columnsJsonStr += `"d-${index}": {"id": "d-${index}", "day": ${currDay}, "label": ${label}, "month": "${month}", "date": "${currDateSrt}", "trainingIds": [${trainingIdsStr}], "title": "Title-${currDay}" }, `;
    }

    if (finishBuildDays) {
      break;
    }
  }

  columnsJsonStr += `}`

  result.columns = JSON.parse(columnsJsonStr.toString());

  result.columns['tcolumns'] = JSON.parse(`{ "id": "tcolumns", "trainingIds": ${trainingIds} }`);

  let columnOrderSrt = `[ `;
  Object.keys(result.columns).forEach((col, cidx) => {
    const currItem = result.columns[col];

    if ((cidx) === Object.keys(result.columns).length - 1) {
      columnOrderSrt += `"${currItem.id}" `;
    } else {
      columnOrderSrt += `"${currItem.id}", `;
    }
  })
  columnOrderSrt += ` ]`;

  result.columnOrder = JSON.parse(columnOrderSrt);

  return { dndData: result, calendarInitAt: firstDay };
}

export function formatTrainingJSON(trainings, visible = true) {

  let trainingsToSelectJSON = `{`;
  let trainingIds = `[ `;

  trainings.forEach((training, index) => {
    const comma = (index + 1) === trainings.length ? '' : ', '

    const currT = {
      id: training.id, name: training.name, activity_type: training.activity_type, clone_type: training.clone_type, calorie: training.calorie, altimetry: training.altimetry,
      duration: training.duration, distance: training.distance, group_order: training.group_order, week_day: training.week_day, estimated_init: training.estimated_init,
      order_day: training.order_day, due_date: training.due_date, type: training.type, visible_type: training.visible_type, executed_status: training.executed_status,
      cps: training.cps
    }

    trainingsToSelectJSON += `"t-${training.id}": {"id": "t-${training.id}", "tId": ${training.id}, "tName": "${training.name}", "tIName": "${training.internal_name}", "show": ${visible}, "content": "CONTEUDO-${training.id}", "currTraining": ${JSON.stringify(currT)} } ${comma}`;
    trainingIds += `"t-${training.id}" ${comma}`;
  });

  trainingsToSelectJSON += `}`
  trainingIds += ` ]`;

  return [trainingsToSelectJSON, trainingIds];
}

function formatUserActivityJSON(userActivities, visible = true, trainingsPTScaffold) {
  let usrActivitiesScaffoldStr = '{ ';
  let usrActivitiesToSelectJSON = `{`;
  let usrActivitiesIds = `[ `;

  let addTheFirstObj = false;

  userActivities.forEach((usrAct, index) => {
    const comma = (index + 1) === userActivities.length ? '' : ', ';
    const comma2 = index === 0 || !addTheFirstObj ? '' : ', ';

    const { start_date, title } = usrAct;

    const currUActivity = { ...usrAct, obj_type: 'USR_ACT', start_date: start_date ? start_date.replaceAll(' ', '') : null };

    const nTitle = title ? title.replaceAll('\"', '\'') : null;

    usrActivitiesToSelectJSON += `"ua-${usrAct.id}": {"id": "ua-${usrAct.id}", "tId": ${usrAct.id}, "tName": "${nTitle}", "tIName": "${nTitle}", "show": ${visible}, "content": "CONTEUDO-${usrAct.id}", "currTraining": {}, "currUsrActivity": ${JSON.stringify(currUActivity)} } ${comma}`;
    usrActivitiesIds += `"ua-${usrAct.id}" ${comma}`;

    const dateFormatted = formatDate(usrAct.start_date);

    if (trainingsPTScaffold && trainingsPTScaffold[dateFormatted]) {
      trainingsPTScaffold[dateFormatted].push(currUActivity);
    } else {
      addTheFirstObj = true;
      const resultStr = `${comma2} "${dateFormatted}": [${JSON.stringify(currUActivity)}] `;
      usrActivitiesScaffoldStr += resultStr;
    }
  });

  usrActivitiesToSelectJSON += `}`;
  usrActivitiesIds += ` ]`;
  usrActivitiesScaffoldStr += ' }';

  const trainingsScaffold = JSON.parse(usrActivitiesScaffoldStr);

  trainingsPTScaffold = { ...trainingsPTScaffold, ...trainingsScaffold };

  return [usrActivitiesToSelectJSON, usrActivitiesIds, trainingsPTScaffold];
}

async function buildTrainingsFromTP(trainingPlan) {
  let trainings = [];

  let trainingsScaffoldStr = '{ '

  await trainingPlan[0].trainingPlanWeeks.forEach(async (tpw, tpwIdx) => {
    const isLastTP = (tpwIdx + 1) === trainingPlan[0].trainingPlanWeeks.length

    await tpw.trainingPlanDays.forEach((tpd, tpdIdx) => {
      const isLast = isLastTP && (tpdIdx + 1) === tpw.trainingPlanDays.length

      const date = tpd.date;
      const day = formatTrainingPlanTrainings(date, tpd.trainings, isLast);
      trainingsScaffoldStr += day;

      trainings = trainings.concat(tpd.trainings)
    });
  });

  trainingsScaffoldStr += ' }'

  trainingsScaffoldStr = trainingsScaffoldStr.replace(', }', '}')
  const trainingsScaffold = JSON.parse(trainingsScaffoldStr);

  return { trainings, trainingsScaffold };
}

function formatTrainingPlanTrainings(date, trainings, isLast) {
  const dateFormated = formatDate(date)
  const resultStr = `"${dateFormated}": ${JSON.stringify(trainings)} ${isLast ? '' : ','}`;

  return resultStr;
}

function formatDate(date) {
  try {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  } catch (error) {
    date = new Date(date);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }
}