import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Loader from '~/components/Loader/Loader';

import { signOut } from '~/store/modules/auth/actions';
import { updateProfileRequest } from '~/store/modules/user/actions';

import { Container } from './styles';

export default function Profile() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);

  function handleSubmit(data) {
    data.id = profile.id;
    dispatch(updateProfileRequest(data));
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <React.Fragment>
                {false && <Loader />}

                <Row>
                    <Col>
                        <div className="page-title-box">
                            <Row>
                                <Col lg={7}>
                                    <h4 className="page-title">Atividades de Treino</h4>
                                </Col>
                                <Col lg={5} className="mt-lg-3 mt-md-0">
                                    
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p>TrainingActivity content</p>
                                <>
                                  <Container>
                                    <Form initialData={profile} onSubmit={handleSubmit}>
                                      <Input name="username" placeholder="Username" />
                                      <Input name="email" type="email" placeholder="Seu email" />

                                      <hr />

                                      <Input name="oldPassword" type="password" placeholder="Senha atual" />
                                      <Input name="password" type="password" placeholder="Nova senha" />
                                      <Input name="confirmPassword" type="password" placeholder="Confirmação de senha" />
                                      
                                      <button type="submit">Atualizar Perfil</button>
                                    </Form>

                                    <button type="button" onClick={handleSignOut}>SAIR</button>
                                  </Container>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
        </React.Fragment>
  );
}
