import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Pagination from "react-reactstrap-pagination";
import { toast } from 'react-toastify';
import api from '~/services/api';
import Loader from '~/components/Loader/Loader';
import './styles.css'
import ModalEdit from './ModalEdit';
import ModalCreate from './ModalCreate';
import { formatBigText } from '~/services/util';

export default function Products() {
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedPage, setSelectedPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [perPage, setPerPage] = useState(20)
    const [lastPage, setLastPage] = useState(1)
    const [modalEdit, setModalEdit] = useState(false);
    const [productToShow, setProductToShow] = useState(null)
    const [modalCreate, setModalCreate] = useState(false)

    const loadProducts = useCallback(async (selectedPage) => {
        setLoading(true)

        const URL = `products?page=${selectedPage}`

        try {
            const response = await api.get(URL)

            setProducts(response.data?.data)
            setTotalItems(response.data?.total)
            setPerPage(response.data?.perPage)
            setLastPage(response.data?.lastPage)
            setSelectedPage(response.data?.page)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Erro ao carregar produtos!')
        }
    }, [])

    useEffect(() => {
        loadProducts(1);
    }, [loadProducts])

    const formatType = (type) => {
        switch (type) {
            case 'PLAN':
                return 'Plano'
            case 'PRODUCT':
                return 'Produto'
            default:
                return '-'
        }
    }

    return (
        <>
            {loading && <Loader />}

            {productToShow && <ModalEdit product={productToShow} showModal={modalEdit} toggle={() => setModalEdit(prev => !prev)} loadProducts={loadProducts} />}

            <ModalCreate showModal={modalCreate} toggle={() => setModalCreate(prev => !prev)} loadProducts={loadProducts} />

            <Row>
                <Col>
                    <div className="page-title-box">
                        <Row>
                            <Col lg={7}>
                                <h4 className="page-title">Produtos</h4>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card style={{ overflow: 'auto' }}>
                        <CardBody>
                            <div className="card-body-header">
                                <p>Produtos ({totalItems})</p>
                                <p className="ml-2 btn btn-primary" onClick={() => setModalCreate(true)}>Cadastrar novo produto</p>
                            </div>

                            <table className="table table-centered table-hover mb-0" id="btn-editable">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nome</th>
                                        <th>Descrição</th>
                                        <th>Tipo</th>
                                        <th>Gateway</th>
                                        <th>Id externo</th>
                                        <th>Identificador de URL</th>
                                        <th>Preço</th>
                                        <th>Parcelamentos possíveis</th>
                                        <th>Recorrência</th>
                                        <th>Permite cupom?</th>
                                        <th>Permite boleto?</th>
                                        <th>Permite pix?</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {products &&
                                        products.length > 0 &&
                                        products.map((product, pIdx) => (
                                            <tr key={`pro_${pIdx}`} className="table-tbody-tr" onClick={() => {
                                                setProductToShow(product)
                                                setModalEdit(true)
                                            }}>
                                                <td>
                                                    <span className="tabledit-span tabledit-identifier">{product.id}</span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {product.name}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {product.description ? formatBigText(product.description) : 'n/a'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {formatType(product.type)}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {product.gateway}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {product.external_id}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {product.url_identifier}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(product.price)}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {product.possible_installments}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {product.recurrence}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {product.allow_coupon === 1 ? 'Sim' : 'Não'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {product.allow_bank_slip === 1 ? 'Sim' : 'Não'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {product.allow_pix === 1 ? 'Sim' : 'Não'}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{ overflow: 'auto' }}>
                    <Pagination
                        firstPageText="Início"
                        lastPageText="Fim"
                        previousPageText="Anterior"
                        nextPageText="Próximo"
                        totalItems={totalItems}
                        pageSize={perPage}
                        defaultActivePage={selectedPage}
                        onSelect={loadProducts}
                    />
                </Col>
            </Row>
        </>

    );
}
