import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, InputGroupAddon, InputGroupText, InputGroup, CustomInput } from 'reactstrap';
import api from '~/services/api';
import Loader from '~/components/Loader/Loader'

function ModalEdit({ product, showModal, toggle, loadProducts }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('PLAN');
  const [typeSubscription, setTypeSubscription] = useState('');
  const [gateway, setGateway] = useState('VINDI');
  const [externalId, setExternalId] = useState('');
  const [urlIdentifier, setUrlIdentifier] = useState('');
  const [price, setPrice] = useState('');
  const [possibleInstallments, setPossibleInstallments] = useState('');
  const [recurrence, setRecurrence] = useState('');
  const [allowCoupon, setAllowCoupon] = useState(true)
  const [allowBankSlip, setAllowBankSlip] = useState(true)
  const [allowPix, setAllowPix] = useState(true)
  const [emptyName, setEmptyName] = useState(false)
  const [emptyDescription, setEmptyDescription] = useState(false)
  const [emptyGateway, setEmptyGateway] = useState(false)
  const [emptyExternalId, setEmptyExternalId] = useState(false)
  const [emptyUrlIdentifier, setEmptyUrlIdentifier] = useState(false)
  const [emptyPrice, setEmptyPrice] = useState(false)
  const [emptyPossibleInstallments, setEmptyPossibleInstallments] = useState(false)
  const [emptyRecurrence, setEmptyRecurrence] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [discountValue, setDiscountValue] = useState('')
  const [allowDiscount, setAllowDiscount] = useState(false)

  useEffect(() => {
    setName(product.name)
    setDescription(product.description)
    setType(product.type)
    setTypeSubscription(product.subscription_type)
    setGateway(product.gateway)
    setExternalId(product.external_id)
    setUrlIdentifier(product.url_identifier)
    setPrice(product.price)
    setPossibleInstallments(product.possible_installments)
    setRecurrence(product.recurrence)
    setAllowCoupon(product.allow_coupon === 1 ? true : false)
    setAllowBankSlip(product.allow_bank_slip === 1 ? true : false)
    setAllowPix(product.allow_pix === 1 ? true : false)
    setAllowDiscount(product.apply_discount === 1 ? true : false)
    setDiscountValue(product.discount_value)
  }, [product])

  async function validateProduct() {
    try {
      await api.get(`validateProduct/${type}/${externalId}/${gateway}`)
      return true
    } catch (error) {
      setLoading(false)
      toast.error(`Código externo não encontrado na ${gateway}`);
      return false
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true)

      const data = {
        name: name,
        description: description,
        type: type,
        gateway: gateway,
        external_id: externalId,
        subscription_type: typeSubscription,
        url_identifier: urlIdentifier,
        price: parseFloat(price),
        possible_installments: possibleInstallments,
        recurrence: parseInt(recurrence),
        allow_coupon: allowCoupon,
        allow_bank_slip: allowBankSlip,
        apply_discount: allowDiscount,
        discount_value: discountValue
      }

      if (!data.name) setEmptyName(true)
      if (!data.description) setEmptyDescription(true)
      if (!data.gateway) setEmptyGateway(true)
      if (!data.external_id) setEmptyExternalId(true)
      if (!data.url_identifier) setEmptyUrlIdentifier(true)
      if (!data.price) setEmptyPrice(true)
      if (!data.possible_installments) setEmptyPossibleInstallments(true)
      if (!data.recurrence) setEmptyRecurrence(true)

      if (
        !data.name ||
        !data.description ||
        !data.gateway ||
        !data.external_id ||
        !data.url_identifier ||
        !data.price ||
        !data.possible_installments ||
        !data.recurrence
      ) {
        setLoading(false)
        return
      }

      const validated = await validateProduct()

      if (!validated) return

      await api.put(`products/${product.id}`, data)

      setEmptyName(false)
      setEmptyDescription(false)
      setEmptyGateway(false)
      setEmptyExternalId(false)
      setEmptyUrlIdentifier(false)
      setEmptyPrice(false)
      setEmptyPossibleInstallments(false)
      setEmptyRecurrence(false)
      setLoading(false)

      toast.success('Produto atualizado com sucesso')

      loadProducts(1)
    } catch (error) {
      setLoading(false)

      if (error.response.data?.message === 'external_id_already_exists') {
        toast.error('Código externo já cadastrado')
      } else if (error.response.data?.message === 'url_identifier_already_exists') {
        toast.error('Identificador de URL já cadastrado')
      } else {
        toast.error('Ocorreu um erro ao atualizar o produto')
      }
    }
  }

  async function handleDelete() {
    try {
      setDeleteLoading(true)

      await api.delete(`products/${product.id}`)

      setDeleteLoading(false)

      toast.success('Produto apagado com sucesso')

      setModalDelete(prev => !prev)
      toggle()

      loadProducts(1)
    } catch (error) {
      setDeleteLoading(false)
      toast.error('Ocorreu um erro ao apagar o produto')
    }
  }

  return (
    <div>
      <Modal isOpen={modalDelete} toggle={() => setModalDelete(prev => !prev)}>
        <ModalHeader toggle={() => setModalDelete(prev => !prev)}>Apagar produto #{product.id}</ModalHeader>
        <ModalBody>
          {deleteLoading && <Loader />}
          Tem certeza?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalDelete(prev => !prev)}>Não</Button>{' '}
          <Button color="danger" onClick={handleDelete}>Sim</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar produto #{product.id}</ModalHeader>
        <ModalBody>
          {loading && <Loader />}
          <Form>
            <FormGroup>
              <Label for="name">Nome</Label>
              <Input type="text" name="name" id="name" value={name} onChange={e => setName(e.target.value)} invalid={emptyName} />
              <FormFeedback>Nome é obrigatório</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="description">Descrição</Label>
              <Input type="textarea" name="description" id="description" value={description} onChange={e => setDescription(e.target.value)} invalid={emptyDescription} />
              <FormFeedback>Descrição é obrigatório</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="type">Tipo</Label>
              <Input type="select" name="type" id="type" value={type} onChange={e => setType(e.target.value)}>
                <option value="PLAN">Plano</option>
                <option value="PRODUCT">Produto</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="type">Tipo da Assinatura</Label>
              <Input type="select" name="type" id="type" value={typeSubscription} onChange={e => setTypeSubscription(e.target.value)}>
                <option value=""></option>
                <option value="BASIC">BASIC</option>
                <option value="START">START</option>
                <option value="ESSENTIAL">ESSENTIAL</option>
                <option value="PERSONAL">PERSONAL</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="gateway">Gateway</Label>
              <Input type="text" name="gateway" id="gateway" value={gateway} onChange={e => setGateway(e.target.value.toUpperCase())} invalid={emptyGateway} />
              <FormFeedback>Gateway é obrigatório</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="external_id">Código externo</Label>
              <Input type="text" name="external_id" id="external_id" value={externalId} onChange={e => setExternalId(e.target.value)} invalid={emptyExternalId} />
              <FormFeedback>Código externo é obrigatório</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="url_identifier">Identificador de URL</Label>
              <Input type="text" name="url_identifier" id="url_identifier" value={urlIdentifier} onChange={e => setUrlIdentifier(e.target.value.replace(' ', '-'))} invalid={emptyUrlIdentifier} />
              <FormFeedback>Identificador de URL é obrigatório</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="price">Preço</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>R$</InputGroupText>
                </InputGroupAddon>
                <Input type="number" name="price" id="price" value={price} onChange={e => setPrice(e.target.value)} invalid={emptyPrice} />
                <FormFeedback>Preço é obrigatório</FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="possible_installments">Parcelamentos possíveis (Separar por vírgula*)</Label>
              <Input type="text" name="possible_installments" id="possible_installments" value={possibleInstallments} onChange={e => setPossibleInstallments(e.target.value.trim())} invalid={emptyPossibleInstallments} />
              <FormFeedback>Parcelamentos possíveis é obrigatório</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="recurrence">Recorrência</Label>
              <Input type="number" name="recurrence" id="recurrence" value={recurrence} onChange={e => setRecurrence(e.target.value)} invalid={emptyRecurrence} />
              <FormFeedback>Recorrência é obrigatório</FormFeedback>
            </FormGroup>
            <FormGroup>
              <CustomInput type="switch" id="allow_coupon" name="allow_coupon" label="Permitir cupom?" checked={allowCoupon} onChange={() => setAllowCoupon(prev => !prev)} />
            </FormGroup>
            <FormGroup>
              <CustomInput type="switch" id="allow_bank_slip" name="allow_bank_slip" label="Permitir boleto?" checked={allowBankSlip} onChange={() => setAllowBankSlip(prev => !prev)} />
            </FormGroup>
            <FormGroup>
              <CustomInput type="switch" id="allow_pix" name="allow_pix" label="Permitir PIX?" checked={allowPix} onChange={() => setAllowPix(prev => !prev)} />
            </FormGroup>
            <FormGroup>
              <CustomInput type="switch" id="allow_discount_pix" name="allow_discount_pix" label="Possui desconto no pix?" checked={allowDiscount} onChange={() => setAllowDiscount(prev => !prev)} />
            </FormGroup>

            <FormGroup>
              <Label for="discountPix">Percentual Desconto PIX</Label>
              <Input type="number" name="discountPix" id="discountPix" value={discountValue} onChange={e => setDiscountValue(e.target.value)} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancelar</Button>{' '}
          <Button color="danger" onClick={() => setModalDelete(prev => !prev)}>Apagar</Button>{' '}
          <Button color="primary" onClick={handleSubmit}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ModalEdit;