import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Loader from '../../../components/Loader/Loader'

export default function TrainingPlanDetailsNotesModal({
    show,
    toggle,
    currentCoach,
    currentStatus,
    internalNotes,
    updateRequest,
    loading
}) {
    const [coachNotes, setCoachNotes] = useState("");

    const handleChangeCoachNotes = e => {
        setCoachNotes(e.target.value);
    };

    const handleUpdateRequest = async () => {
        updateRequest({ currentCoach, currentStatus, internalNotes, coachNotes, withEmail: true });
    };

    return (
        <Modal isOpen={show} toggle={toggle}>
            {loading && <Loader />}
            <ModalHeader>AVISO POR EMAIL</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label>Observações</Label>
                        <Input
                            type="textarea"
                            value={coachNotes}
                            onChange={handleChangeCoachNotes}
                            placeholder="MENSAGEM DO TREINADOR"
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={handleUpdateRequest}>
                    Enviar Email
                </Button>
                <Button color="danger" onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
}
