export function translateStatus(status) {
    switch (status) {
      case "FINISHED":
        return "Finalizado";
      case "CANCELED":
        return "Cancelado";
      case "PENDING":
        return "Pendente";
      default:
        return status;
    }
  };

export function applyColorToStatus(status) {
    switch (status) {
        case "FINISHED":
        return "text-success";
        case "CANCELED":
        return "text-danger";
        case "PENDING":
        return "text-warning";
        default:
        return status;
    }
}

export function translateObjectKeys(key) {
    switch (key) {
        case "gender":
        return "Gênero";
        case "size":
        return "Tamanho";
        default:
        return key;
    }
};
