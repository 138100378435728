import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Card, CardBody, Table, Button } from 'reactstrap';
import moment from 'moment'
import api from '~/services/api'
import Loader from '~/components/Loader/Loader';
import Pagination from "react-reactstrap-pagination";
import { toast } from 'react-toastify'
import { Container } from './styles'
import NewLectureModal from './NewLectureModal'

function VideoAoVivoScreen() {
    const [loading, setLoading] = useState(false)
    const [liveLectures, setLiveLectures] = useState([])
    const [selectedPage, setSelectedPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [lastPage, setLastPage] = useState(1)
    const [newLectureModal, setNewLectureModal] = useState(false)

    const loadLiveLectures = useCallback(async (page) => {
        try {
            setLoading(true)

            const url = `live_lectures?page=${page}&limit=${perPage}&clone_type=SCHEMA&order=DESC&isRecord=true&obj_type=LECTURE`

            const { data } = await api.get(url)

            setLiveLectures(data.data)
            setTotalItems(data.total)
            setLastPage(data.lastPage)
            setSelectedPage(data.page)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Erro ao carregar gravações!')
        }
    }, [perPage])

    useEffect(() => {
        loadLiveLectures(1)
    }, [loadLiveLectures])

    return (
        <Container>
            <NewLectureModal loadLiveLectures={loadLiveLectures} open={newLectureModal} toggle={() => setNewLectureModal(false)} />

            <Row>
                <Col>
                    <div className="page-title-box">
                        <Row>
                            <Col lg={7}>
                                <h4 className="page-title">Gravações das aulas funcionais ao vivo</h4>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            {loading && <Loader />}
                            <Button style={{ marginBottom: 10 }} color="success" onClick={() => setNewLectureModal(true)}>Cadastrar nova gravação</Button>
                            <Row>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nome</th>
                                            <th>Data</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {liveLectures.length > 0 && liveLectures.map(lec => (
                                            <tr>
                                                <th scope="row">{lec.id}</th>
                                                <td>{lec.name}</td>
                                                <td>{moment(lec.date).format('DD/MM/YYYY')}</td>
                                                <td className="tabledit-view-mode"><Button color="primary" onClick={() => alert(`Edit lec: ${lec.id}`)}>Editar</Button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{ overflow: 'auto' }}>
                    <Pagination
                        firstPageText="Início"
                        lastPageText="Fim"
                        previousPageText="Anterior"
                        nextPageText="Próximo"
                        totalItems={totalItems}
                        pageSize={perPage}
                        defaultActivePage={selectedPage}
                        onSelect={loadLiveLectures}
                    />
                </Col>
            </Row>
        </Container >
    );
}

export default VideoAoVivoScreen;