import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, Button, ModalHeader, Table } from 'reactstrap';
import pt from 'date-fns/locale/pt';
import { format, isAfter } from 'date-fns'
import api from '~/services/api'
import Loader from '~/components/Loader/Loader'
import { toast } from 'react-toastify';

export default function SignatureModal({ show = true, toggle, user, signature, handleSearchUser }) {
  const [loading, setLoading] = useState(true)
  const [subscription, setSubscription] = useState(null)
  const [hasAccess, setHasAccess] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  useEffect(() => {
    api.get(`/subscriptions?userId=${user.id}`)
      .then(response => {
        if (response.data.length > 0) {
          setSubscription(response.data[0])

          if (response.data[0].bills &&
            response.data[0].bills.length > 0 &&
            response.data[0].bills[0].status === 'paid' &&
            response.data[0].current_period &&
            response.data[0].current_period.end_at &&
            isAfter(new Date(response.data[0].current_period.end_at), new Date())
          ) {
            setHasAccess(true)
          } else if (
            signature.name &&
            signature.name.toLowerCase().includes('personal') &&
            response.data[0].current_period &&
            response.data[0].current_period.end_at &&
            isAfter(new Date(response.data[0].current_period.end_at), new Date())
          ) {
            setHasAccess(true)
          }
        }

        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [signature.name, user, user.id])

  function formatSubscriptionStatus(status) {
    switch (status) {
      case 'active':
        return 'ATIVA';

      case 'canceled':
        return 'CANCELADA';

      case 'finished':
        return 'FINALIZADA';

      case 'pending':
        return 'PENDENTE';
      default:
        return '--';
    }
  }

  async function deleteSignature() {
    setDeleteLoading(true)

    try {
      await api.delete(`/subscriptions/${signature.id}`)

      setDeleteLoading(false)

      toast.success('Assinatura apagada com sucesso')

      handleSearchUser()

      toggle(!show)
    } catch (error) {
      setDeleteLoading(false)

      toast.error('Erro ao apagar assinatura, tente novamente')
    }
  }

  return (
    <>
      <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(!showDeleteModal)}>
        {deleteLoading && <Loader />}
        <ModalHeader>Apagar assinatura</ModalHeader>
        <ModalBody>
          Confirma a exclusão?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowDeleteModal(!showDeleteModal)}>Não</Button>
          <Button color="danger" onClick={deleteSignature}>Sim</Button>
        </ModalFooter>
      </Modal>

      <Modal style={{ maxWidth: '2600px', width: '95%', maxHeight: '1600px', height: '95%' }} isOpen={show} toggle={() => toggle(!show)}>
        <ModalHeader>{user.name}</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Button className="mb-2" color="danger" onClick={() => setShowDeleteModal(true)}>Apagar assinatura</Button>
              {
                loading ?
                  <Loader />
                  :
                  <Table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nome</th>
                        <th>Status</th>
                        <th>Tem acesso?</th>
                        <th>Desde de</th>
                        <th>Expiração</th>
                        <th>Id externo</th>
                        <th>Método de pagamento</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{signature.id}</th>
                        <td>{signature.name ? signature.name : '--'}</td>
                        <td>{subscription.status ? formatSubscriptionStatus(subscription.status) : '--'}</td>
                        <td>{hasAccess ? 'SIM' : "NÃO"}</td>
                        <td>{subscription.current_period && subscription.current_period.start_at ? format(new Date(subscription.current_period.start_at), "dd/MM/yyyy HH:mm", { locale: pt }) : '--'}</td>
                        <td>{subscription.current_period && subscription.current_period.end_at ? format(new Date(subscription.current_period.end_at), "dd/MM/yyyy HH:mm", { locale: pt }) : '--'}</td>
                        <td>{subscription.id}</td>
                        <td>{subscription.payment_method && subscription.payment_method.public_name ? subscription.payment_method.public_name : '--'}</td>
                      </tr>
                    </tbody>
                  </Table>
              }
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => toggle(!show)}>Fechar</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
