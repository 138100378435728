import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';

function ModalDistance({ showModal, toggle, action }) {
    const [distance, setDistance] = useState(0);
    const [emptyDistance, setEmptyDistance] = useState(false);

    function handleSubmit() {
        if (!distance) {
            setEmptyDistance(true)
            return
        }

        if (action) action(distance)

        setEmptyDistance(false)
    }

    return (
        <div>
            <Modal isOpen={showModal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Adicionar</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="distance">Distância</Label>
                            <Input type="number" min="0" name="distance" id="distance" value={distance} onChange={e => setDistance(parseInt(e.target.value))} invalid={emptyDistance} />
                            <FormFeedback>Deve ser maior que 0</FormFeedback>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>{' '}
                    <Button color="primary" onClick={handleSubmit}>Salvar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ModalDistance;