import React, { Component, Suspense } from "react";
import { Container } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import profilePic from '~/assets/img/logo-cp-y-sm.png';

import Topbar from './components/Topbar';
import Sidebar from './components/Sidebar';
import RightSidebar from './components/RightSidebar';
import Footer from './components/Footer';

const loading = () => <div className="text-center"></div>;

const RightSidebarContent = () => {
    const profile = useSelector(state => state.user.profile);

    return <div className="user-box">
        <div className="user-img">
            <img src={profile.s_photo ? profile.s_photo : profilePic} alt="user-img" title="Profile"
                className="rounded-circle img-fluid" />
            
            <Link className="user-edit" to="/perfil"><i className="mdi mdi-pencil"></i></Link>
        </div>

        <h5>
            <Link to="/perfil">{profile.username}</Link>
        </h5>
        
        <p className="text-muted mb-0"><small>{profile.name}</small></p>
    </div>
}

class AuthLayout extends Component {

    constructor(props) {
        super(props);

        this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.state = {
            isCondensed: false
        }
    }

    signOut(e) {
        e.preventDefault();
        this.props.history.push("/login");
    }

    /**
     * toggle Menu
     */
    toggleMenu = (e) => {
        e.preventDefault();
        this.setState({ isCondensed: !this.state.isCondensed });
    }

    /**
     * Toggle right side bar
     */
    toggleRightSidebar = () => {
        document.body.classList.toggle("right-bar-enabled");
    }

    render() {
        // get the child view which we would like to render
        const children = this.props.children || null;
        return (
            <div className="app">
                <div id="wrapper">
                    <Suspense fallback={loading()}>
                        <Topbar rightSidebarToggle={this.toggleRightSidebar} menuToggle={this.toggleMenu} />
                        <Sidebar isCondensed={this.state.isCondensed} {...this.props} />
                    </Suspense>
                    <div className="content-page">
                        <div className="content">

                            <Container fluid>
                                <Suspense fallback={loading()}>
                                    {children}
                                </Suspense>
                            </Container>
                        </div>
                    </div>

                    {/* <Footer /> */}
                </div>

                <RightSidebar title={"Configuração"}>
                    <RightSidebarContent />
                </RightSidebar>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.profile
    }
}
export default connect(mapStateToProps, null)(AuthLayout);
