import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom'
import { toast } from 'react-toastify';

import { Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Alert, CardTitle, CardText, Spinner } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';

import { signInRequest } from '~/store/modules/auth/actions';
import Loader from '~/components/Loader/Loader';
import logo from '~/assets/img/logo-cp-y-sm.png';

import api, { BASE_API_URL, BASE_FRONT_URL, BASE_APP_URL } from '~/services/api';

import queryString from 'query-string';

class ExchangeToken extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        const urlParsed = queryString.parse(this.props.location.search);

        const hasCode = urlParsed.code ? true : false;

        this.state = {
            platform: this.props.match.params.platform,
            userID: this.props.match.params.userID,
            code: urlParsed.code,
            userActivitiesLoad: false,
            hasCode
        }
    }
    
    async componentDidMount() {
        if (this.state.code) {
            await api.post(`${BASE_API_URL}/s/${this.state.userID}?code=${this.state.code}`)

            this.setState({...this.state, userActivitiesLoad: true})
        }
    }

    _handleStravaIntegrate = () => {
        const stravaURL = `${process.env.REACT_APP_STRAVA_API_URL}oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${BASE_FRONT_URL}/exchange-token/${this.state.userID}/ios&approval_prompt=force&scope=activity:read_all`;
        window.location.href = stravaURL;
    }

    _handleBackToApp = () => {
        if (typeof window !== 'undefined') {
            window.location.href = BASE_APP_URL;
        } else {
            alert("Integração realizada com sucesso, você já pode voltar pro aplicativo")
        }
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="account-pages mt-5 mb-5">
                    <Container>
                        <Row className="justify-content-center">
                            {/* <div style={{display: 'flex', flexDirection: 'row'}}> */}

                                {/* <img src={logo} style={{width: 100, height: 100}} alt="Devtime" /> */}

                                {!this.state.hasCode && <Col lg={5}>
                                    <Card body className="text-center">
                                        <CardTitle>Associação com Strava</CardTitle>
                                        <CardText>Estamos quase lá! Para continuar, clique no botão abaixo.</CardText>
                                        <Button onClick={() => this._handleStravaIntegrate()} style={{backgroundColor: '#ff3d00'}}>Associar</Button>
                                    </Card>
                                </Col>}

                                {this.state.hasCode && !this.state.userActivitiesLoad && <Col lg={5}>
                                    <Card body className="text-center">
                                    <CardBody>
                                        <CardTitle>Aguarde...</CardTitle>
                                        <Spinner style={{ width: '3rem', height: '3rem', marginBottom: 20 }} />{' '}
                                        <CardText>Estamos sincronizando suas atividades</CardText>
                                    </CardBody>
                                    </Card>
                                </Col>}

                                {this.state.userActivitiesLoad && <Col lg={5}>
                                    <Card body className="text-center">
                                        <CardTitle>Sucesso</CardTitle>
                                        <CardText>As atividades do Strava foram sincronizadas. Atualize seu feed de atividades para vê-las!</CardText>
                                        <Button onClick={() => this._handleBackToApp()} style={{backgroundColor: '#2C9E32'}}>Voltar pra APP</Button>
                                    </Card>
                                </Col>}
                            {/* </div> */}
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    // const { user, loading, error } = state.Auth;
    
    // const { signed, loading } = state.auth;

    // return { user, loading, error, signed };
    // return { signed, loading }
    return;
};

export default connect(mapStateToProps, { signInRequest })(ExchangeToken);
 