/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { StyledThumb } from './styles';

function CustomThumb(props, state) {
  return (
    <StyledThumb {...props} index={state.index}>
      {state.valueNow === 42 ? '42+' : state.valueNow}
    </StyledThumb>
  );
}

export default CustomThumb;
