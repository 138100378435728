import React from 'react';
import ReactSlider from 'react-slider';
import Thumb from './Thumb';
import Track from './Track';

function RangeSlider({
  values, callback, min, max, customThumb,
}) {
  return (
    <>
      <ReactSlider
        renderTrack={Track}
        renderThumb={customThumb || Thumb}
        min={min}
        max={max}
        step={1}
        pearling
        onChange={callback}
        value={values}
      />
    </>
  );
}

export default RangeSlider;
