import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import Pagination from "react-reactstrap-pagination";

import { toast } from 'react-toastify';

import TrainingPlanDetailsModal from '../TrainingPlanDetailsModal';

import { formatTrainingPlanRequestStatus } from '~/services/util';

import api from '~/services/api';

import Loader from '~/components/Loader/Loader';
import { formatTrainingPlanRequestObjective, formatRequestKeepFitType } from '~/services/util'

import { debounce } from 'lodash'

export default function TrainingPlanRequests() {
  const [requests, setRequests] = useState([])
  const [loading, setLoading] = useState(false)
  const [trainingPlanRequestModal, setTrainingPlanRequestModal] = useState(false)
  const [tpRequestToShow, setTpRequestToShow] = useState(null)
  const [selectedPage, setSelectedPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [lastPage, setLastPage] = useState(1)
  const [coachsList, setCoachsList] = useState([])
  const [showDDCoach, setShowDDCoach] = useState(false)
  const [currentCoach, setCurrentCoach] = useState({ name: 'Todos', id: -1 })
  const [showDDStatus, setShowDDStatus] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState({ name: 'Todos', id: 'all' })
  const [statuses, setStatuses] = useState([])

  const loadTPRequests = useCallback(async (selectedPage) => {
    setLoading(true)

    let tpsURL = `get_requests_by_statuses_and_coach?page=${selectedPage}&status=${selectedStatus.id}&coachId=${currentCoach.id >= 0 ? currentCoach.id : 0}`

    if (currentCoach.id === -2) {
      tpsURL = `get_requests_by_statuses_and_coach?page=${selectedPage}&status=${selectedStatus.id}&withoutCoach=true`
    }

    Promise.all([
      api.get(tpsURL),
      api.get(`user_coaches`)])
      .then(([tpRequestsResp, coachsResp]) => {
        const allCoachs = [{ name: 'Todos', id: -1 }, { name: '<vazio>', id: -2 }, ...coachsResp.data]
        const allStatuses = [
          { name: 'Todos', id: 'all' },
          { name: 'Novos', id: 'CREATED' },
          { name: 'Abertos', id: 'OPEN' },
          { name: 'Concluídos', id: 'FINISHED' },
          { name: 'Cancelados', id: 'CANCELED' },
          { name: 'Pendentes', id: 'PENDING' }
        ]

        setRequests(tpRequestsResp.data?.data)
        setStatuses(allStatuses)
        setCoachsList(allCoachs)
        setTotalItems(tpRequestsResp.data?.total)
        setPerPage(tpRequestsResp.data?.perPage)
        setLastPage(tpRequestsResp.data?.lastPage)
        setSelectedPage(tpRequestsResp.data?.page)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        toast.error('Erro ao carregar solicitações!')
      });
  }, [currentCoach.id, selectedStatus.id])

  useEffect(() => {
    loadTPRequests(1);
  }, [loadTPRequests])

  const searchTrainingPlanRequestsByUserEmail = async (email) => {
    setSelectedStatus({ name: 'Todos', id: 'all' })
    setCurrentCoach({ name: 'Todos', id: -1 })

    if (email.length > 0) {
      setLoading(true)

      const tpsURL = `training_plan_requests?email=${email}`

      Promise.all([api.get(tpsURL)])
        .then(([tpRequestsResp]) => {
          setRequests(tpRequestsResp.data?.data)
          setTotalItems(tpRequestsResp.data?.total)
          setPerPage(tpRequestsResp.data?.perPage)
          setLastPage(tpRequestsResp.data?.lastPage)
          setSelectedPage(tpRequestsResp.data?.page)
          setLoading(false)
        })
        .catch(e => {
          setLoading(false)
          toast.error('Erro ao buscar solicitações!')
        });
    } else if (email.length === 0) {
      loadTPRequests(1)
    }
  }

  const delayedQuery = useRef(
    debounce(e => {
      searchTrainingPlanRequestsByUserEmail(e)
    }, 1000)
  ).current

  const _chooseCoach = async (coach) => {
    setCurrentCoach(coach)
    setLoading(true)

    let url = `get_requests_by_statuses_and_coach?page=1&status=${selectedStatus.id}`

    if (coach.id >= 0) {
      url = `get_requests_by_statuses_and_coach?page=1&coachId=${coach.id}&status=${selectedStatus.id}`
    } else if (coach.id === -2) {
      url = `get_requests_by_statuses_and_coach?page=1&withoutCoach=true&status=${selectedStatus.id}`
    }

    Promise.all([api.get(url)])
      .then(([tpRequestsResp]) => {
        setRequests(tpRequestsResp.data?.data)
        setTotalItems(tpRequestsResp.data?.total)
        setPerPage(tpRequestsResp.data?.perPage)
        setLastPage(tpRequestsResp.data?.lastPage)
        setSelectedPage(tpRequestsResp.data?.page)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        toast.error('Erro ao carregar solicitações!')
      });
  }

  const _chooseStatus = async (status) => {
    setSelectedStatus(status)
    setLoading(true)

    let url = `get_requests_by_statuses_and_coach?page=1&status=${status.id}`

    if (currentCoach.id >= 0) {
      url = `get_requests_by_statuses_and_coach?page=1&status=${status.id}&coachId=${currentCoach.id}`
    } else if (currentCoach.id === -2) {
      url = `get_requests_by_statuses_and_coach?page=1&withoutCoach=true&status=${status.id}`
    }

    Promise.all([api.get(url)])
      .then(([tpRequestsResp]) => {
        setRequests(tpRequestsResp.data?.data)
        setTotalItems(tpRequestsResp.data?.total)
        setPerPage(tpRequestsResp.data?.perPage)
        setLastPage(tpRequestsResp.data?.lastPage)
        setSelectedPage(tpRequestsResp.data?.page)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        toast.error('Erro ao carregar solicitações!')
      });
  }

  return (
    <>
      {loading && <Loader />}

      <Row>
        <Col>
          <div className="page-title-box">
            <Row>
              <Col lg={7}>
                <h4 className="page-title">Solicitações de plano de treino</h4>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <p>Solicitações ({totalItems})</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
                    <span>Buscar pelo email do usuário</span>
                    <input style={{ padding: 2 }} type="text" name="search" id="search" onChange={e => delayedQuery(e.target.value)} />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Filtrar por responsável</span>
                    <Dropdown size="sm" isOpen={showDDCoach} toggle={() => setShowDDCoach(!showDDCoach)} >
                      <DropdownToggle style={{ width: 200, marginRight: 10 }}> {currentCoach?.name} </DropdownToggle>
                      <DropdownMenu
                        modifiers={{
                          setMaxHeight: {
                            enabled: true, order: 890, fn: (data) => {
                              return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                            }
                          }
                        }} >
                        {coachsList.map((elem, index) => {
                          return (
                            <DropdownItem
                              key={`co_${index}`}
                              onClick={() => _chooseCoach(elem)}>{elem.name}</DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Filtrar por status</span>
                    <Dropdown size="sm" isOpen={showDDStatus} toggle={() => setShowDDStatus(!showDDStatus)} >
                      <DropdownToggle style={{ width: 200 }}> {selectedStatus?.name} </DropdownToggle>
                      <DropdownMenu
                        modifiers={{
                          setMaxHeight: {
                            enabled: true, order: 890, fn: (data) => {
                              return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                            }
                          }
                        }} >
                        {statuses.map((elem, index) => {
                          return (
                            <DropdownItem
                              key={`st_${index}`}
                              onClick={() => _chooseStatus(elem)}>{elem.name}</DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>

              </div>

              <>
                <table className="table table-centered table-hover mb-0" id="btn-editable">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Usuário</th>
                      <th scope="col">Coach do Usuário</th>
                      <th scope="col">Objetivo</th>
                      <th scope="col">Data/hora solicitada</th>
                      <th scope="col">Responsável</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {requests.map((request, rIdx) => (
                      <tr key={`req_${rIdx}`} style={{ cursor: 'pointer' }} onClick={() => {
                        setTpRequestToShow(request)
                        setTrainingPlanRequestModal(true)
                      }}>
                        <th scope="row">
                          <span className="tabledit-span tabledit-identifier">{request.id}</span>
                        </th>

                        <td className="tabledit-view-mode"><span className="tabledit-span">
                          {request.user?.name}<br />
                          {request.user?.email}
                        </span></td>
                        <td className="tabledit-view-mode"><span className="tabledit-span">
                          {request.user?.coach?.name}
                        </span></td>

                        <td className="tabledit-view-mode"><span className="tabledit-span">{request.distance ? `${request.distance}km` : ''} {request.type === 'KEEP_FIT' ? formatRequestKeepFitType(request.trainingType) : formatTrainingPlanRequestObjective(request.distance_history)} {request.type === 'SLIMMING' || request.type === 'RETURN_INITIATION' || request.type === 'ALTERNATIVE' ? request.title : ''}</span></td>
                        <td className="tabledit-view-mode"><span className="tabledit-span">{moment(request.created_at).format('DD/MM/YYYY HH:mm:ss')}</span></td>
                        <td className="tabledit-view-mode"><span className="tabledit-span">{request.coach?.name || '--'}</span></td>
                        <td className="tabledit-view-mode"><span className={`tabledit-span badge ${request.status === 'FINISHED' ? 'badge-success' : (request.status === 'CANCELED' ? 'badge-danger' : (request.status === 'CREATED' ? 'badge-primary' : (request.status === 'PENDING' ? 'badge-warning text-dark' : 'badge-secondary')))}`}>{formatTrainingPlanRequestStatus(request.status)}</span></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{ overflow: 'auto' }}>
          <Pagination
            firstPageText="Início"
            lastPageText="Fim"
            previousPageText="Anterior"
            nextPageText="Próximo"
            totalItems={totalItems}
            pageSize={perPage}
            defaultActivePage={1}
            onSelect={loadTPRequests}
          />
        </Col>
      </Row>

      {trainingPlanRequestModal && <TrainingPlanDetailsModal open={trainingPlanRequestModal} toggle={() => setTrainingPlanRequestModal(false)} tpRequest={tpRequestToShow} loadTPRequests={loadTPRequests} />}
    </>

  );
}
