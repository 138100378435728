import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input, FormText } from 'reactstrap';
import Loader from '~/components/Loader/Loader';
import { toast } from 'react-toastify'
import { api2 } from '~/services/api'
import moment from 'moment';

export default function NewBenefitModal(props) {
  const [loading, setLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [url, setUrl] = useState('')
  const [type, setType] = useState('')
  const [expirationDate, setExpirationDate] = useState('')
  const [image, setImage] = useState('')
  const [coupon, setCoupon] = useState('')

  const modalTitle = props.editId ? 'Editar Benefício' : 'Novo Benefício'

  const getBenefit = useCallback(async()  =>{
    if (props.editId) {
      api2.get(`benefit/${props.editId}`)
      .then((response) => {
        const { data } = response
        
        setUrl(data.action_url)
        setExpirationDate(data.expiration_date ? moment(data.expiration_date).format('yyyy-MM-DD') : '')
        setImage(data.image)
        setTitle(data.title)
        setDescription(data.description)
        setType(data.type)
        setCoupon(data.coupon)
      })
      .catch(() => {
        toast.error('Erro ao editar o benefício')
      })
    }
  }, [props.editId])

  const handleBenefitImage = async (event) => {
    if (event.target.files.length) {
      const file = event.target.files[0];

      const benefitImage = new FormData();

      benefitImage.append('file', file);

      setImageLoading(true)

      api2.post('/file/benefit-image', benefitImage)
      .then((response) => {
        const { data } = response;
        
        if (data.Location) {
          setImage(data.Location)
        }
      }).finally(() => {
        setImageLoading(false)
      });
    }
  };

  async function handleSaveLecture() {
    if (title === '' || description === '' || url === '' || image === '') {
      toast.warn('Preencha todos os campos corretamente')
      return
    }

    const data = {
      title,
      description,
      action_url: url,
      type,
      expiration_date: moment(expirationDate),
      image,
      coupon,
    }

    setLoading(true)

    try {
      if (props.editId) {
        await api2.put(`/benefit/${props.editId}`, data)
      } else {
        await api2.post('/benefit', data)
      }

      props.loadBenefits()

      setLoading(false)

      props.toggle()
    } catch (error) {
      setLoading(false)
      if (props.editId) {
        toast.error('Erro ao editar o conteúdo')
      } else {
        toast.error('Erro ao cadastrar novo conteúdo')
      }
    }
  }

  useEffect(() => {
    if(props.editId) {
      getBenefit()
    }
  }, [props.editId, getBenefit])

  return (
    <div>
      <Modal size="xl" isOpen={props.open} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          {loading && <Loader />}
          <Form>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="title">Título</Label>
                  <Input type="text" name="title" id="title" placeholder="Título" value={title} onChange={e => setTitle(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="title">Descrição</Label>
                  <Input type="text" name="description" id="description" placeholder="Descrição" value={description} onChange={e => setDescription(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="link">Link</Label>
                  <Input type="text" name="link" id="link" placeholder="Link do benefício" value={url} onChange={e => setUrl(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row className="align-items-center">
            <Col md={3}>
                <FormGroup>
                  <Label for="order">Cupom</Label>
                  <Input type="text" name="coupon" id="coupon" placeholder="Cupom" value={coupon} onChange={e => setCoupon(e.target.value)} />
                </FormGroup>
              </Col>
              <Col md={3}>
              <FormGroup>
                  <Label for="type">Tipo</Label>
                  <Input type="text" name="type" id="type" placeholder="Tipo" value={type} onChange={e => setType(e.target.value)} />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="expiration">
                    Data de validade
                  </Label>
                  <Input id="expiration" name="expiration" type="date" value={expirationDate} onChange={(e) => setExpirationDate(e.target.value)}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="fileImage">
                    Imagem
                  </Label>
                  <Input
                    id="fileImage"
                    name="fileImage"
                    type="file"
                    onChange={handleBenefitImage}
                  />
                  <FormText>
                    Adicione a imagem relacionada ao conteúdo.
                  </FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                {imageLoading && <Loader/>}
                {image && <img alt={`${title}`} src={image} height={300}/>}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={props.toggle}>Cancelar</Button>{' '}
          <Button color="success" onClick={handleSaveLecture}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}