import React, { useState, useEffect } from 'react';

import moment from 'moment';

import {
  Button, Row, Col, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledCollapse, Card, CardBody,
  InputGroup, InputGroupAddon, FormGroup, Label
} from 'reactstrap';

import { AvForm, AvField, AvInput, AvFeedback } from 'availity-reactstrap-validation';

import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { toast } from 'react-toastify';

import api from '~/services/api';

export default function TrainingPlanDetailModal(props) {

  const profile = useSelector(state => state.user.profile);

  const [show, setShow] = useState(props.open);
  const [trainingPlan, setTrainingPlan] = useState(props.trainingPlan);
  const [totalDuration, setTotalDuration] = useState(props.trainingPlan);
  const [totalDistance, setTotalDistance] = useState(props.trainingPlan);
  const [trainingPlanDate, setTrainingPlanDate] = useState();

  const [users, setUsers] = useState([]);
  const [usersToAssociate, setUsersToAssociate] = useState([]);
  const [associatedTrainingPlans, setAssociatedTrainingPlans] = useState([]);

  const [folders, setFolders] = useState([]);
  const [showDDFolder, setShowDDFolder] = useState(false);
  const [currentFolder, setCurrentFolder] = useState({ name: 'Selecionar Pasta', id: -1 });

  const [startEndDateOptions, setStartEndDateOptions] = useState([{ name: 'Iniciar', id: 1 }, { name: 'Finalizar', id: 2 }]);
  const [showDDStartEndDate, setShowDDStartEndDate] = useState(false);
  const [currentStartEndDate, setCurrentStartEndDate] = useState({ name: 'Iniciar', id: 1 });

  const [searchUserQuery, setSearchUserQuery] = useState();

  const [formVisible, setFormVisible] = useState(false);

  useEffect(() => {
    Promise.all([api.get(`/folders`), api.get(`/folders/${trainingPlan.folder_id}`), api.get(`/users`), api.get(`/training_plans/${trainingPlan.id}/associated-users`)])
      .then(async ([foldersResp, currFolderResp, usersResp, associatedTPResp]) => {
        setFolders(foldersResp.data)
        setUsers(usersResp.data.data)
        setAssociatedTrainingPlans(associatedTPResp.data)

        if (currFolderResp && currFolderResp.data && currFolderResp.data.id > 0) {
          setCurrentFolder(currFolderResp.data)
        }
      });

    let totalDuration = 0;
    let totalDistance = 0;
    trainingPlan.trainingPlanWeeks.forEach(tpw => {
      totalDuration += tpw.duration_total;
      totalDistance += tpw.distance_total;
    });


    setTotalDistance(totalDistance);
    setTotalDuration(totalDuration);

  }, [trainingPlan])

  const chooseFolder = async (elem) => {
    try {
      const trainingPlanToUpdate = { folder_id: elem.id }


      const response = await api.put(`/training_plans/${trainingPlan.id}`, trainingPlanToUpdate)

      if (response.status === 200) {
        setCurrentFolder(elem)
        toast.success('Grupo de planos de treino atualizada com sucesso')
      } else {
        toast.warn('Erro ao atualizar o grupo de planos de treino')
      }
    } catch (error) {
      toast.error('Aconteceu um erro ao atualizar o grupo de planos de treino')
    }
  }

  const selectUser = async (elem) => {
    var index = usersToAssociate.indexOf(elem);

    if (index > -1) {
      usersToAssociate.splice(index, 1);
    } else {
      usersToAssociate.push(elem)
    }

    await setUsersToAssociate(usersToAssociate)
  }

  const associateTPToUsers = async () => {
    if (!usersToAssociate || usersToAssociate.length <= 0) {
      toast.error('Selecione ao menos um atleta para vincular ao plano de treino')
      return;
    }

    if (!trainingPlanDate) {
      toast.error('Selecione uma data de início ou fim')
      return;
    }

    try {
      const tpAssociate = {
        training_plan_id: trainingPlan.id,
        coach_id: profile.id,
        users: usersToAssociate,
      }

      if (currentStartEndDate.id === 1) {
        tpAssociate.start_date = trainingPlanDate;
      } else {
        tpAssociate.end_date = trainingPlanDate;
      }

      const response = await api.post(`/training_plans/associate`, tpAssociate)

      if (response.status === 200) {
        toast.success('Plano de Treino associado com sucesso ao usuários')
      } else {
        toast.warn('Erro ao associar o plano de treino aos usuários')
      }
    } catch (error) {
      toast.error('Aconteceu um erro ao associar o plano de treino aos usuários')
    }
  }

  const searchUsers = async () => {
    const { data } = await api.get(`users?searchQuery=${searchUserQuery}`)
    setUsers(data)
  }

  const desassociate = async (userID) => {
    try {
      const resp = await api.post(`/training_plans/1/deassociate_user?user_id=${userID}`, {})

      if (resp.status === 200) {
        toast.success('Plano de treino desaplicado com sucesso')
        setShow(!show)
      } else {
        toast.warn('Erro ao desaplicar plano de treino')
      }
    } catch (error) {
      toast.error('Erro ao desaplicar plano de treino')
    }
  }

  const deleteTrainingPlan = async () => {
    var result = window.confirm("Tem certeza que deseja remover permanentemente esse plano de treino?");
    if (result) {
      const resp = await api.delete(`training_plans/${trainingPlan.id}`)

      if (resp && resp.status === 204) {
        toast.success('Sucesso ao deletar plano de treino')
        props.toggle(!show)
      } else {
        toast.error('Erro ao deletar plano de treino')
      }
    }
  }

  const cloneTrainingPlan = async () => {
    var result = window.confirm("Tem certeza que deja fazer um clone desse plano de treino?");
    if (result) {
      const resp = await api.post(`training_plans/${trainingPlan.id}/clone`)

      if (resp && resp.status === 200) {
        toast.success('Plano de treino clonado com sucesso!')
        props.toggle(!show)
        // window.location.reload(true);
      } else {
        toast.error('Erro ao clonar o plano de treino')
      }
    }
  }

  const editTrainingPlan = async (event, errors, values) => {
    try {
      if (formVisible) {
        const resp = await api.put(`training_plans/${trainingPlan.id}`, values)

        if (resp && resp.status === 200) {
          toast.success('Plano de treino atualizado com sucesso!')
          props.toggle(!show)
          setFormVisible(!formVisible)
        } else {
          toast.warn('Erro ao atualizar plano de treino')
        }
      } else {
        setFormVisible(!formVisible)
      }

    } catch (error) {
      toast.error('Aconteceu um erro ao atualizar o plano de treino')
    }
  }

  const handle = async () => {
    setFormVisible(!formVisible)
  }

  return (
    <Modal isOpen={show} toggle={() => props.toggle(!show)} style={{ maxWidth: '1600px', width: '40%', maxHeight: '600px', height: '60%' }}>
      <AvForm onSubmit={editTrainingPlan} model={trainingPlan}>
        <ModalHeader toggle={() => props.toggle(!show)}>Plano de Treino</ModalHeader>

        <ModalBody>
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={2}>
                  <Avatar alt={trainingPlan.name} ariant="rounded" style={{ width: 80, height: 80, color: '#fff', backgroundColor: 'gray' }} >
                    <AssignmentIcon style={{ fontSize: 50 }} />
                  </Avatar>

                </Col>
                <Col lg={10}>
                  <span>ID: #{trainingPlan.id}</span> <br />

                  <span style={{ display: 'flex', flexDirection: 'row' }}>
                    <p style={{ marginRight: 15 }}>Nome:</p>
                    {formVisible ?
                      <AvField style={{ height: 24, fontSize: 14 }} placeholder="Nome" name="name" />
                      :
                      <p>{trainingPlan.name}</p>}
                  </span>


                  <span style={{ display: 'flex', flexDirection: 'row' }}>
                    <p style={{ marginRight: 15 }}>Nome Interno:</p>
                    {formVisible ?
                      <AvField style={{ height: 24, fontSize: 14 }} placeholder="Nome interno" name="internal_name" />
                      :
                      <p>{trainingPlan.internal_name}</p>}
                  </span>

                  <span style={{ display: 'flex', flexDirection: 'row' }}>
                    <p style={{ marginRight: 15 }}>Descição:</p>
                    {formVisible ?
                      <AvField style={{ height: 24, width: '100%', fontSize: 14 }} placeholder="Descição" name="description" />
                      :
                      <p>{trainingPlan.description}</p>}
                  </span>

                  <span>Data de criação: {moment(trainingPlan.created_at).format('DD/MM/YYYY')}</span> <br />

                  <span>{trainingPlan.trainingPlanWeeks && trainingPlan.trainingPlanWeeks ? `${trainingPlan.trainingPlanWeeks.length} semanas` : ''} </span> <br />
                  <Button color="primary" outline size="sm" style={{ marginTop: 10 }} onClick={() => cloneTrainingPlan()}>Clonar</Button>
                  <Button color="danger" size="sm" style={{ marginTop: 10, marginLeft: 15 }} onClick={() => deleteTrainingPlan()}>Apagar Plano de Treino</Button>
                </Col>
              </Row>
              <br />


              <span>Autor: Andrei</span> <br />

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                Pasta/Grupo:&nbsp;&nbsp;
                <Dropdown size="sm" isOpen={showDDFolder} toggle={() => setShowDDFolder(!showDDFolder)} >
                  <DropdownToggle style={{ width: 150, marginRight: 10 }}> {currentFolder.name} </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true, order: 890, fn: (data) => {
                          return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                        }
                      }
                    }} >
                    {folders.map((elem, index) => {
                      return (
                        <DropdownItem key={`fold_${index}`} onClick={() => chooseFolder(elem)}>{elem.name}</DropdownItem>
                      )
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div> <br /><br />

              <table className="table table-bordered table-sm mb-0">
                <thead className="">
                  <tr>
                    <th>Modalidade</th>
                    <th>Duração</th>
                    <th>Distância</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Corrida</td>
                    <td>{totalDuration} hrs/wk</td>
                    <td>{totalDistance}</td>
                  </tr>
                </tbody>
              </table>

              <br /><br />

              <p style={{ fontWeight: 'bold' }}>Aplicar Plano</p>
              <div>
                <Button color="secondary" outline id="toggler" style={{ marginBottom: '0rem', width: '100%' }}> Selecionar Atletas  </Button>
                <UncontrolledCollapse toggler="#toggler">
                  <Card>
                    <CardBody style={{ padding: 0 }}>

                      {/* <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <div>
                                                    <Button color="secondary" size="sm" outline style={{ margin: 5 }}>Todos</Button>
                                                    <Button color="secondary" size="sm" outline style={{ margin: 5 }}>Nenhum</Button>
                                                </div>

                                                <div>
                                                    <Button color="success" size="sm" outline style={{ margin: 5 }} onClick={() => {}}>Selecionar</Button>
                                                </div>
                                            </div> */}

                      <InputGroup style={{ margin: 5, width: '98%' }}>
                        <Input state={{ width: '90%' }} onChange={(e) => setSearchUserQuery(e.target.value)} />
                        <InputGroupAddon addonType="append">
                          <Button color="info" onClick={() => searchUsers()}>pesquisar</Button>
                        </InputGroupAddon>
                      </InputGroup>

                      <PerfectScrollbar style={{ maxHeight: 200 }}>
                        <table className="table table-sm mb-0">
                          <tbody>

                            {users.map((usr, index) => {
                              return (
                                <tr key={`idd_${index}`}>
                                  <td>
                                    <FormGroup check>
                                      <Label check>
                                        <Input type="checkbox" onClick={() => selectUser(usr.id)} />{' '} #{usr.id} - {usr.name} ({usr.email})
                                      </Label>
                                    </FormGroup>
                                  </td>
                                </tr>

                              )
                            })}
                          </tbody>
                        </table>
                      </PerfectScrollbar>

                      <hr style={{ borderColor: '#cccccc' }} />

                      <div style={{ display: 'flex', margin: 5, width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                          <Dropdown size="sm" isOpen={showDDStartEndDate} toggle={() => setShowDDStartEndDate(!showDDStartEndDate)} >
                            <DropdownToggle style={{ width: 150, marginRight: 10 }}> {currentStartEndDate.name} </DropdownToggle>
                            <DropdownMenu
                              modifiers={{
                                setMaxHeight: {
                                  enabled: true, order: 890, fn: (data) => {
                                    return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                                  }
                                }
                              }} >
                              {startEndDateOptions.map((elem, index) => {
                                return (
                                  <DropdownItem key={`opt_${index}`} onClick={() => setCurrentStartEndDate(elem)}>{elem.name}</DropdownItem>
                                )
                              })}
                            </DropdownMenu>
                          </Dropdown>

                          <span> em </span>

                          <Input type="date" size="sm" name="date" id="exampleDate" onChange={(e) => setTrainingPlanDate(e.target.value)} placeholder="date placeholder" style={{ marginLeft: 10 }} />
                        </div>

                        <div>
                          <Button color="secondary" size="sm" outline style={{ marginRight: 10 }} onClick={() => associateTPToUsers()}>Aplicar</Button>
                        </div>
                      </div>

                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </div>

              <br /><br />

              <p style={{ fontWeight: 'bold' }}>Aplicado Para</p>
              <PerfectScrollbar style={{ maxHeight: 200, height: 'auto' }}>
                <table className="table table-sm mb-0">
                  <tbody>
                    {associatedTrainingPlans.map((elem, index) => {
                      return (
                        <tr key={`ask_${index}`}>
                          <td>
                            <div style={{ display: 'flex', marginTop: 10, width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                              <span>#{elem.owner_id} - {elem.owner.name}</span>
                              <span>{moment(elem.start_date).format('DD/MM/YY')} - {moment(elem.end_date).format('DD/MM/YY')}
                                <Link onClick={() => desassociate(elem.owner_id)} className="text-muted ml-1">Desaplicar</Link>
                              </span>
                            </div>
                          </td>
                        </tr>
                      )
                    })}

                  </tbody>
                </table>
              </PerfectScrollbar>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <Button color="danger" onClick={() => props.toggle(!show)}>Fechar</Button>
            </div>
            <div>
              <Button type="submit" color={formVisible ? 'success' : 'info'} outline={!formVisible} style={{ marginRight: 10 }}>{formVisible ? 'Salvar' : 'Editar'}</Button>

              <Button color="secondary" onClick={() => {
                props.loadedItem(trainingPlan, 'trainingPlan')
                props.toggle(!show)
                props.changeTab('3');
              }} style={{ marginRight: 10 }}>Visualizar</Button>
            </div>
          </div>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}
