import React, { useState, useEffect } from 'react';

import {
  Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
  Card, CardHeader, CardBody, FormGroup,
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from 'classnames';


import { toast } from 'react-toastify';

import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';

import { MdDirectionsRun } from 'react-icons/md';

import api from '~/services/api';

import { formatZone } from '~/services/util'

const ACTIVITY_TYPE_OPTIONS = {
  DURATION: { id: 'DURATION', name: "Tempo", labelType: 'Min', fieldName: 'duration' },
  DISTANCE: { id: 'DISTANCE', name: "Distância", labelType: 'Km', fieldName: 'distance' },
}

function orderTrainingGroupsAndTrainingActivities(training) {
  let ordered = training

  if (ordered.trainingGroups && ordered.trainingGroups.length > 0) {
    ordered.trainingGroups = ordered.trainingGroups.sort((a, b) => {
      if (a.group_order && b.group_order && a.group_order < b.group_order) return -1

      return 0
    })

    for (let index = 0; index < ordered.trainingGroups.length; index++) {
      if (ordered.trainingGroups[index].trainingActivities && ordered.trainingGroups[index].trainingActivities.length > 0) {
        ordered.trainingGroups[index].trainingActivities = ordered.trainingGroups[index].trainingActivities.sort((a, b) => {
          if (a.group_order && b.group_order && a.group_order < b.group_order) return -1

          return 0
        })
      }
    }
  }

  return ordered
}

export default function TrainingNewModal(props) {

  // WeightTraining, Workout, Run, Ride, Swim, Drills, Cardio, Others
  const [show, setShow] = useState(props.open);
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  const [training, setTraining] = useState(orderTrainingGroupsAndTrainingActivities(props.training));
  const [isTrainingEdit, setIsTrainingEdit] = useState(props.isTrainingEdit);
  const [folders, setFolders] = useState([]);
  const [trainingGroupTemplates, setTrainingGroupTemplates] = useState([]);
  const [showDDFolder, setShowDDFolder] = useState(false);
  const [activityTypes, setActivityTypes] = useState([]);
  const [currentFolder, setCurrentFolder] = useState({ name: 'Selecionar', id: -1 });
  const [currentActivityType, setCurrentActivityType] = useState({ name: 'Selecionar', id: -1 });
  const [showDDActivityType, setShowDDActivityType] = useState(false);

  const [trainingTypes, setTrainingTypes] = useState([{ id: 'Run', name: 'Corrida' }, { id: 'WeightTraining', name: 'Treino de Força' }, { id: 'Ride', name: 'Bicicleta' }, { id: 'Swim', name: 'Natação' }, { id: 'Drills', name: 'Educativos' }, { id: 'Cardio', name: 'Cardio' }, { id: 'Others', name: 'Outros' }, { id: 'Workout', name: 'Workout' }]);
  const [showDDType, setShowDDType] = useState(false);
  const [currentType, setCurrentType] = useState({ id: -1, name: 'Selecionar' });

  const [trainingType, setTrainingType] = useState(''); // NONE, EXPLORE, RUN 

  const [trainingIntencity, setTrainingIntencity] = useState([{ id: 1, name: 'Iniciante' }, { id: 2, name: 'Intermediário' }, { id: '3', name: 'Avançado' }]);
  const [showDDIntencity, setShowDDIntencity] = useState(false);
  const [currentTrainingIntencity, setCurrentTrainingIntencity] = useState({ id: -1, name: 'Selecionar' });

  const [trainingCoverLink, setTrainingCoverLink] = useState('');

  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    const activityTypeOptions = [ACTIVITY_TYPE_OPTIONS.DISTANCE, ACTIVITY_TYPE_OPTIONS.DURATION]
    setActivityTypes(activityTypeOptions)

    Promise.all([api.get('folders'), api.get('training_groups?type=TEMPLATE')])
      .then(([folderResp, trainingGroupsResp]) => {
        setFolders(folderResp.data);
        setTrainingGroupTemplates(trainingGroupsResp.data)
      });


  }, [])

  useEffect(() => {
    if (training) {
      if (training.folder) {
        setCurrentFolder(training.folder);
      }

      if (training.activity_type === ACTIVITY_TYPE_OPTIONS.DURATION.id) {
        setCurrentActivityType(ACTIVITY_TYPE_OPTIONS.DURATION);
      } else {
        setCurrentActivityType(ACTIVITY_TYPE_OPTIONS.DISTANCE);
      }

      switch (training.intencity) {
        case 1:
          setCurrentTrainingIntencity({ id: 1, name: 'Iniciante' })
          break;
        case 2:
          setCurrentTrainingIntencity({ id: 2, name: 'Intermediário' })
          break;
        case 3:
          setCurrentTrainingIntencity({ id: 3, name: 'Avançado' })
          break;
        default:
          setCurrentTrainingIntencity({ id: 1, name: 'Iniciante' })
          break;
      }

      if (isTrainingEdit && currentType.id < 1) {
        let trainingTypeTmp = '';
        switch (training.type) {
          case 'Run':
            trainingTypeTmp = { id: 'Run', name: 'Corrida' }
            break;
          case 'WeightTraining':
            trainingTypeTmp = { id: 'WeightTraining', name: 'Treino de Força' };
            break;
          case 'Ride':
            trainingTypeTmp = { id: 'Ride', name: 'Bicicleta' };
            break;
          case 'Swim':
            trainingTypeTmp = { id: 'Swim', name: 'Natação' };
            break;
          case 'Drills':
            trainingTypeTmp = { id: 'Drills', name: 'Educativos' };
            break;
          case 'Cardio':
            trainingTypeTmp = { id: 'Cardio', name: 'Cardio' };
            break;
          case 'Others':
            trainingTypeTmp = { id: 'Others', name: 'Outros' };
            break;
          case 'Workout':
            trainingTypeTmp = { id: 'Workout', name: 'Workout' };
            break;
          default:
            trainingTypeTmp = { id: 'Others', name: 'Outros' };
            break;
        }

        setCurrentType(trainingTypeTmp)
        setTrainingType(trainingTypeTmp.id === 'Run' ? 'RUN' : 'EXPLORE')
      }
    }
  }, [currentType, isTrainingEdit, training, trainingType])

  const validateData = async (training) => {
    let trainingTotal = 0;
    let hasError = false;

    if (currentType.id < 1) {
      toast.error('Selecione um tipo para o treino.')
      hasError = true;
    }

    if (currentActivityType.id < 1) {
      toast.error('Selecione um tipo de atividade para prosseguir (Duração ou Distância)')
      hasError = true;
    }

    if (!training.name) {
      toast.error('Atribua um nome para o seu treino')
      hasError = true;
    }

    if (currentFolder.id > 0) {
      training.folder_id = currentFolder.id
    }

    if (trainingType === 'EXPLORE' && currentTrainingIntencity.id < 1) {
      toast.error('Selecione a intencidade do treino')
      hasError = true;
    }

    training.activity_type = currentActivityType.id

    // if (!training.trainingGroups || training.trainingGroups.length < 1) {
    //   toast.error('Selecione algumas atividades para compor seu treino')
    //   hasError = true;
    // }

    if (training && training.trainingGroups) {
      training.trainingGroups.forEach((tgroup, tgIdx) => {
        tgroup.activity_type = currentActivityType.id
        tgroup.group_order = (tgIdx + 1)

        let trainingGroupTotal = 0;

        tgroup.trainingActivities.forEach((tactivity, taidx) => {
          tactivity.group_order = (taidx + 1)

          trainingGroupTotal += tactivity.distance

          if (trainingType === 'EXPLORE') {
            if (tactivity.activity_type === 'Tempo') {
              if (!tactivity.duration || tactivity.duration === '') {
                toast.error("Preencha o campo de 'Tempo' da atividade " + tactivity.name)
                hasError = true;
              }
              tactivity.duration = parseFloat(tactivity.duration)
              delete tactivity.distance
              delete tactivity.repetition
              tactivity.activity_type = 'DURATION'
            } else if (tactivity.activity_type === 'Deslocamento') {
              if (!tactivity.distance || tactivity.distance === '') {
                toast.error("Preencha o campo de 'Deslocamento' da atividade " + tactivity.name)
                hasError = true;
              }
              tactivity.distance = parseFloat(tactivity.distance)
              delete tactivity.duration
              delete tactivity.repetition
              tactivity.activity_type = 'DISTANCE'
            } else {
              if (!tactivity.repetition || tactivity.repetition === '') {
                toast.error("Preencha o campo de 'Repetições' da atividade " + tactivity.name)
                hasError = true;
              }
              tactivity.repetition = parseInt(tactivity.repetition)
              delete tactivity.duration
              delete tactivity.distance
              tactivity.activity_type = 'REPETITION'
            }

            switch (tactivity.execution_speed) {
              case 'Rápido':
                tactivity.execution_speed = 3
                break;
              case 'Moderado':
                tactivity.execution_speed = 2
                break;
              case 'Lento':
                tactivity.execution_speed = 1
                break;
              default:
                tactivity.execution_speed = 1
            }
          } else {
            if (currentActivityType.id === 'DURATION') {
              if (!tactivity.duration || tactivity.duration === '') {
                toast.error("Preencha os minutos de duração da atividade " + tactivity.name)
                hasError = true;
              }

              tactivity.duration = parseFloat(tactivity.duration)
              delete tactivity.distance
              delete tactivity.repetition
              tactivity.activity_type = 'DURATION'
            } else if (currentActivityType.id === 'DISTANCE') {
              if (!tactivity.distance || tactivity.distance === '') {
                toast.error("Preencha os kms de distância da atividade " + tactivity.name)
                hasError = true;
              }

              tactivity.distance = parseFloat(tactivity.distance)
              delete tactivity.duration
              delete tactivity.repetition
              tactivity.activity_type = 'DISTANCE'
            }
          }
        })

        const totalGroup = (trainingGroupTotal * tgroup.repetition)
        trainingTotal += totalGroup;

        if (currentActivityType.id === 'DURATION') {
          tgroup.duration_total = totalGroup;
        } else {
          tgroup.distance_total = totalGroup;
        }
      });
    }

    training.type = currentType.id
    training.intensity = currentTrainingIntencity.id

    return hasError ? null : training
  }

  const handleTrainingSubmit = async (event, errors, values) => {
    const trainingToSave = await validateData(values);

    if (trainingToSave && trainingToSave.name) {
    } else {
      return;
    }

    try {
      let resp = null;
      let successResp = 'Treino Criado com sucesso!';
      let errorResp = 'Erro ao criar o treino!';

      if (!isTrainingEdit) {
        resp = await api.post('trainings', trainingToSave)
        successResp = 'Treino Criado com sucesso!'
        errorResp = 'Erro ao criar o treino!'
      } else {
        resp = await api.put(`trainings/${training.id}`, trainingToSave)
        successResp = 'Treino Atualizado com sucesso!'
        errorResp = 'Erro ao atualizar o treino!'
      }

      if (resp && resp.status === 200) {
        toast.success(successResp)
        // setShow(!show)
        props.toggle(false)

        if (props.refreshTrainings) {
          props.refreshTrainings()
        }

      } else {
        toast.warn(errorResp)
      }
    } catch (error) {
      toast.error('Aconteceu um erro ao salvar seu treino')
    }
  }

  const chooseTrainingTemplate = (trainingGroup) => {
    let groups = [...training.trainingGroups];

    groups.push(trainingGroup);

    const newTraining = { ...training, trainingGroups: groups }

    setTraining(orderTrainingGroupsAndTrainingActivities(newTraining));
    setShowGroupsModal(!showGroupsModal);
  }

  const removeTrainingGroup = async (tgIdx) => {

    const groups = [...training.trainingGroups];

    if (isTrainingEdit) {
      const trainingGroup = groups[tgIdx]

      if (trainingGroup && trainingGroup.clone_type !== 'TEMPLATE') {
        try {
          await api.delete(`training_groups/${trainingGroup.id}`)
        } catch (error) {
          toast.error('Não foi possível remover a etapa !')
          return
        }

      }

    }

    groups.splice(tgIdx, 1)

    const newTraining = { ...training, trainingGroups: groups }

    setTraining(orderTrainingGroupsAndTrainingActivities(newTraining));
  }

  const chooseTrainingType = async (ttype) => {
    if (ttype.id === 'WeightTraining' || ttype.id === 'Drills' || ttype.id === 'Cardio' || ttype.id === 'Others') {
      await setTrainingType('EXPLORE')
    } else if (ttype.id === 'Run') {
      await setTrainingType('RUN')
    }

    await setCurrentType(ttype)
  }

  const _handleLinkImageChange = (link) => {
    setTrainingCoverLink(link)
  }

  const _handleAddExploreTrainingGroup = async () => {
    const taToAdd = { name: '', description: '', activity_type: '', clone_type: 'SCHEMA', group_order: 1, repetition: '', execution_speed: '', media_link: '', thumb_link: '' }

    const tgToAdd = { name: '', activity_type: '', clone_type: 'SCHEMA', trainingActivities: [taToAdd] }

    let groups = [...training.trainingGroups];

    groups.push(tgToAdd);

    const newTraining = { ...training, trainingGroups: groups }

    await setTraining(orderTrainingGroupsAndTrainingActivities(newTraining));
  }

  const _handleAddActivity = async (tgIdx) => {
    let groups = [...training.trainingGroups];

    const taToAdd = { name: '', description: '', activity_type: '', clone_type: 'SCHEMA', group_order: 1, repetition: '', execution_speed: '', media_link: '', thumb_link: '' }

    groups[tgIdx].trainingActivities.push(taToAdd);

    const newTraining = { ...training, trainingGroups: groups }

    await setTraining(orderTrainingGroupsAndTrainingActivities(newTraining));
  }

  const _handleRemoveActivity = (tgIdx, actIdx) => {
    const currTraining = { ...training }

    const currTg = currTraining.trainingGroups[tgIdx];

    if (currTg && currTg.trainingActivities && currTg.trainingActivities.length > 1) {
      currTg.trainingActivities.splice(actIdx, 1)
    } else if (currTg && currTg.trainingActivities) {
      currTraining.trainingGroups.splice(tgIdx, 1)
    }

    setTraining(orderTrainingGroupsAndTrainingActivities(currTraining));
  }

  function drawActivityInfos(act, groupItem, actIdx) {
    const zoneFormated = formatZone(act.zone);

    let description = ''

    if (groupItem.repetition && groupItem.repetition > 1) {

      if (currentActivityType.id === 'DISTANCE') {
        description = `${act.distance} km @ ${zoneFormated}`
      } if (currentActivityType.id === 'DURATION') {
        description = `${act.duration} min @ ${zoneFormated}`
      }
    } else {
      if (currentActivityType.id === 'DISTANCE') {
        description = `${act.distance} km @ ${zoneFormated}`
      } if (currentActivityType.id === 'DURATION') {
        description = `${act.duration} min @ ${zoneFormated}`
      }
    }

    return (
      <div>
        {groupItem.repetition && groupItem.repetition > 1 ?
          <div>
            <div style={{ marginLeft: 20, marginTop: 5, alignSelf: 'flex-start', flexDirection: 'row', }} >
              <span style={{ fontWeight: '500' }}>{actIdx + 1}. </span>
              <span style={{ fontWeight: '500' }}>{act.name}</span>
            </div>
            <div>
              <span style={{ color: 'black', marginLeft: 40 }}>{description}</span>
            </div>
          </div>
          :
          <div>
            <div style={{ marginLeft: 20, marginTop: 5, alignSelf: 'flex-start', flexDirection: 'row' }} >
              <span style={{ fontWeight: '500' }}>{actIdx + 1}. </span>
              <span style={{ color: 'black', marginLeft: 5 }}>{description}</span>
            </div>
          </div>}



      </div>
    )
  }

  const isRun = currentType && currentType.id === 'Run';
  return (
    <Modal style={{ maxWidth: '2600px', width: '95%', maxHeight: '1600px', height: '95%' }} isOpen={show} toggle={() => props.toggle(false)}>
      <Modal style={{ maxWidth: '700', height: '95%' }} isOpen={showGroupsModal}>
        <ModalHeader toggle={() => setShowGroupsModal(!showGroupsModal)}>Selecione um template de treino</ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
            {trainingGroupTemplates.map((elem, index) => {
              return (
                <Card className="shadow" onClick={() => chooseTrainingTemplate(elem)} key={index} style={{ width: 180, height: 50, margin: 5 }}>
                  <CardBody style={{ padding: 5, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    {elem.name}
                  </CardBody>
                </Card>
              )
            })}

          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => setShowGroupsModal(!showGroupsModal)}>Cancelar</Button>
        </ModalFooter>
      </Modal>

      <AvForm onSubmit={handleTrainingSubmit} model={training}>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Nav tabs>
                <NavItem>
                  <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }} >
                    Criação de treino
                    </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                    Etapas do treino
                    </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col lg={4}>
                          <span style={{ fontWeight: '500', fontSize: 15 }}>Categoria</span><br />
                          <Dropdown isOpen={showDDType} toggle={() => setShowDDType(!showDDType)} style={{ marginTop: 10, marginBottom: 15 }} >
                            <DropdownToggle style={{ width: 150, marginRight: 10 }}> {currentType.name} </DropdownToggle>
                            <DropdownMenu
                              modifiers={{
                                setMaxHeight: {
                                  enabled: true, order: 890, fn: (data) => {
                                    return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                                  }
                                }
                              }} >
                              {trainingTypes.map((elem, index) => {
                                return (
                                  <DropdownItem key={`key_${index}`} onClick={() => chooseTrainingType(elem)}>{elem.name}</DropdownItem>
                                )
                              })}
                            </DropdownMenu>
                          </Dropdown>
                        </Col>
                        <Col lg={4}>
                          <span style={{ fontWeight: '500', fontSize: 15 }}>Pasta</span><br />
                          <Dropdown isOpen={showDDFolder} toggle={() => setShowDDFolder(!showDDFolder)} style={{ marginTop: 10, marginBottom: 15, paddingRight: 10 }} >
                            <DropdownToggle style={{ width: 150, marginRight: 10 }}> {currentFolder.name} </DropdownToggle>
                            <DropdownMenu
                              modifiers={{
                                setMaxHeight: {
                                  enabled: true, order: 890, fn: (data) => {
                                    return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                                  }
                                }
                              }} >
                              {folders.map((elem, index) => {
                                return (
                                  <DropdownItem key={`keyy_${index}`} onClick={() => setCurrentFolder(elem)}>{elem.name}</DropdownItem>
                                )
                              })}
                            </DropdownMenu>
                          </Dropdown>
                        </Col>
                        {trainingType === 'RUN' &&
                          <Col lg={4}>
                            <span style={{ fontWeight: '500', fontSize: 15 }}>Duração</span><br />
                            <Dropdown isOpen={showDDActivityType} toggle={() => setShowDDActivityType(!showDDActivityType)} style={{ marginTop: 10, marginBottom: 15 }} >
                              <DropdownToggle style={{ width: 150 }}> {currentActivityType.name} </DropdownToggle>
                              <DropdownMenu
                                modifiers={{
                                  setMaxHeight: {
                                    enabled: true, order: 890, fn: (data) => {
                                      return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                                    }
                                  }
                                }} >
                                {activityTypes.map((elem, index) => {
                                  return (
                                    <DropdownItem key={`keyyy_${index}`} onClick={() => { setCurrentActivityType(elem) }}>{elem.name}</DropdownItem>
                                  )
                                })}
                              </DropdownMenu>
                            </Dropdown>
                          </Col>}
                        {trainingType === 'EXPLORE' &&
                          <Col lg={4}>
                            <span style={{ fontWeight: '500', fontSize: 15 }}>Intencidade</span><br />
                            <Dropdown isOpen={showDDIntencity} toggle={() => setShowDDIntencity(!showDDIntencity)} style={{ marginTop: 10, marginBottom: 15 }} >
                              <DropdownToggle style={{ width: 150, marginRight: 10 }}> {currentTrainingIntencity.name} </DropdownToggle>
                              <DropdownMenu
                                modifiers={{
                                  setMaxHeight: {
                                    enabled: true, order: 890, fn: (data) => {
                                      return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                                    }
                                  }
                                }} >
                                {trainingIntencity.map((elem, index) => {
                                  return (
                                    <DropdownItem key={`keys_${index}`} onClick={() => setCurrentTrainingIntencity(elem)}>{elem.name}</DropdownItem>
                                  )
                                })}
                              </DropdownMenu>
                            </Dropdown>
                          </Col>}
                      </Row>
                      <Row>
                        <Col lg={7}>

                          <span style={{ fontWeight: '500', fontSize: 15 }}>Nome do Treino</span><br />
                          <AvField style={{ height: 44, fontSize: 20, fontWeight: 'bold', marginTop: 15 }} placeholder="Nome visível pro usuário" name="name" />
                          <AvField style={{ height: 24, fontSize: 14 }} placeholder="Nome interno" name="internal_name" />
                          {trainingType === 'RUN' && currentActivityType.id === 'DURATION' &&
                            <Row>
                              <Col lg={3}>Duração (min)</Col>
                              <Col lg={5}><AvField style={{ width: '95%', height: 20 }} name="duration" /></Col>
                            </Row>}
                          {trainingType === 'RUN' && currentActivityType.id === 'DISTANCE' &&
                            <Row>
                              <Col lg={3}>Distância (km)</Col>
                              <Col lg={5}><AvField style={{ width: '95%', height: 20 }} name="distance" /></Col>
                            </Row>}
                          {trainingType === 'RUN' &&
                            <Row>
                              <Col lg={2}><MdDirectionsRun color="gray" size={18} style={{ marginRight: 10 }} /></Col>
                              <Col lg={3}><strong>--:--:--</strong></Col>
                              <Col lg={3}><strong>-- km</strong></Col>
                            </Row>}

                          {trainingType === 'EXPLORE' &&
                            <Row>
                              <Col lg={12}>
                                <Row>
                                  <Col lg={6}>
                                    <AvField label="Estimativa início" style={{ height: 25 }} name="estimated_init" />
                                  </Col>
                                  <Col lg={6}>
                                    <AvField label="Estimativa fim" style={{ height: 25 }} name="estimated_end" />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={12}>
                                    <AvField label="Equipamentos (por extenso)" style={{ height: 25 }} name="equipments" />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={12}>
                                    <AvField label="Imagem (link)" onChange={(e) => _handleLinkImageChange(e.target.value)} style={{ height: 25 }} name="cover_link" />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={12}>
                                    <span>Image Preview</span> <br /><br />
                                    <img src={trainingCoverLink} alt="" height="100" />
                                  </Col>
                                </Row>

                              </Col>
                            </Row>}
                        </Col>
                        <Col lg={5}>
                          <span style={{ fontWeight: '500', fontSize: 15 }}>Descrição</span><br />
                          <AvField type="textarea" style={{ height: 150 }} name="description" />
                        </Col>
                      </Row>


                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    {isRun &&
                      <Col lg={4}>
                        <h4>Detalhes</h4>
                        <hr style={{ backgroundColor: 'gray', height: 1 }} />


                        {training && training.trainingGroups.map((item, tgIDX) => {
                          return (
                            <div style={{ alignItems: 'center', marginTop: 10 }}>
                              <div style={{ alignItems: 'flex-start', width: '80%' }}>
                                <div>
                                  <div style={{ flexDirection: 'row' }}>
                                    <span>{tgIDX + 1}. </span>
                                    <span style={{ fontWeight: '500', fontSize: 15 }}>
                                      {item.repetition > 1 ? `${item.name} | Repetir ${item.repetition} vezes` : `${item.name}`}
                                    </span>
                                  </div>
                                  {item.trainingActivities.map((act, aIdx) => {
                                    return drawActivityInfos(act, item, aIdx);
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Col>}
                    <Col lg={isRun ? 8 : 12} style={{ backgroundColor: 'white', padding: 5 }}>
                      <Row style={{ marginBottom: 15 }}>
                        <Col lg={3}>
                          <h4>Etapas do Treino</h4>
                        </Col>
                        <Col lg={9}>
                          <Button color="info" onClick={() => {
                            if (currentType && (currentType.id === 'Run' || currentType.id === 'Ride' || currentType.id === 'Swim' || currentType.id === 'Others')) {
                              setShowGroupsModal(!showGroupsModal)
                            } else if (currentType && (currentType.id === 'WeightTraining' || currentType.id === 'Drills' || currentType.id === 'Cardio' || currentType.id === 'Workout')) {
                              _handleAddExploreTrainingGroup()
                            } else {
                              alert('Selecione um tipo para o treino')
                            }
                          }}>Adicionar Etapa</Button>{' '}
                        </Col>
                      </Row>

                      <div style={{ overflowY: 'scroll', borderLeft: '1px solid gray', paddingLeft: '10px', paddingRight: '5px', width: '100%', float: 'left', height: '600px', position: 'relative' }}>
                        {training.trainingGroups.map((tgroup, gidx) => {
                          const trainingGroupID = "trainingGroups[" + gidx + "].id";
                          const trainingGroupName = "trainingGroups[" + gidx + "].name";
                          const trainingGroupDesc = "trainingGroups[" + gidx + "].description";
                          const trainingGroupRepetition = "trainingGroups[" + gidx + "].repetition";

                          return (
                            <Card className="shadow" key={gidx}>
                              <CardHeader style={{ padding: 5 }}>
                                <div style={{ 'display': '-webkit-box' }}>
                                  {trainingType === 'EXPLORE' ?
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <AvInput placeholder="ID" style={{ height: 30, width: '100%', display: 'none' }} name={trainingGroupID} />
                                        <span style={{ width: '100%' }}>Nome da Etapa</span>
                                        <AvInput value={tgroup.name} placeholder="Nome da Etapa" style={{ height: 25, width: '100%', marginLeft: 10, marginRight: 10 }} name={trainingGroupName} />
                                        <span style={{ width: '100%' }}>Descrição da Etapa</span>
                                        <AvInput value={tgroup.repetition} placeholder="Descrição da Etapa" style={{ height: 25, width: '100%', marginLeft: 10, marginRight: 10 }} name={trainingGroupDesc} />
                                      </div>
                                      <button type="button" onClick={() => removeTrainingGroup(gidx)} className="btn btn-danger btn-sm">Remover</button>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <AvInput placeholder="ID" style={{ height: 30, width: '100%', display: 'none' }} name={trainingGroupID} />
                                        <span style={{ width: '100%' }}>Nome da Etapa</span>
                                        <AvInput value={tgroup.name} placeholder="Nome da Etapa" style={{ height: 25, width: '100%', marginLeft: 10, marginRight: 10 }} name={trainingGroupName} />
                                        <span style={{ width: '100%' }}>Repetir</span>
                                        <AvInput value={tgroup.repetition} style={{ height: 20, width: 100, marginLeft: 10, marginRight: 10 }} name={trainingGroupRepetition} />
                                        <span>vezes</span>
                                      </div>
                                      <button type="button" onClick={() => removeTrainingGroup(gidx)} className="btn btn-danger btn-sm">Remover</button>
                                    </div>
                                  }

                                </div>
                              </CardHeader>

                              <CardBody style={{ padding: 0 }}>
                                <ListGroup style={{ padding: 0, margin: 0 }}>
                                  {tgroup.trainingActivities.map((input, aidx) => {
                                    const trainingActivityIdLabel = "trainingGroups[" + gidx + "].trainingActivities[" + aidx + "].id";
                                    const trainingActivityNameLabel = "trainingGroups[" + gidx + "].trainingActivities[" + aidx + "].name";
                                    const trainingActivityDescriptionLabel = "trainingGroups[" + gidx + "].trainingActivities[" + aidx + "].description";
                                    const trainingActivitySpeedLabel = "trainingGroups[" + gidx + "].trainingActivities[" + aidx + "].execution_speed";
                                    const trainingActivityActivityTypeLabel = "trainingGroups[" + gidx + "].trainingActivities[" + aidx + "].activity_type";
                                    const trainingActivityRepetitionLabel = "trainingGroups[" + gidx + "].trainingActivities[" + aidx + "].repetition";
                                    const trainingActivityDurationLabel = "trainingGroups[" + gidx + "].trainingActivities[" + aidx + "].duration";
                                    const trainingActivityDistanceLabel = "trainingGroups[" + gidx + "].trainingActivities[" + aidx + "].distance";
                                    const trainingActivityGifLabel = "trainingGroups[" + gidx + "].trainingActivities[" + aidx + "].media_link";
                                    const trainingActivityThumbLabel = "trainingGroups[" + gidx + "].trainingActivities[" + aidx + "].thumb_link";
                                    const trainingActivityDistance = `trainingGroups[${gidx}].trainingActivities[${aidx}].${currentActivityType.fieldName}`;
                                    const trainingActivityZone = "trainingGroups[" + gidx + "].trainingActivities[" + aidx + "].zone";
                                    const trainingActivityName = training.trainingGroups[gidx].trainingActivities[aidx].name;

                                    let trainingActivityType = training.trainingGroups[gidx].trainingActivities[aidx].activity_type;
                                    let trainingActivityExecutionSpeed = training.trainingGroups[gidx].trainingActivities[aidx].execution_speed;

                                    trainingActivityType = trainingActivityType === 'REPETITION' ? 'Repetição' : 'Tempo'

                                    trainingActivityExecutionSpeed = trainingActivityExecutionSpeed === 1 ? 'Lento' : (trainingActivityExecutionSpeed === 2 ? 'Moderado' : 'Rápido');

                                    if (trainingType === 'EXPLORE') {
                                      return (
                                        <ListGroupItem key={`key_act_${aidx}`} style={{ padding: 2, paddingBottom: 10, backgroundColor: (aidx % 2 === 0 ? 'rgba(100, 100, 100, 0.2)' : 'rgba(200, 200, 200, 0.2)') }}>
                                          <ListGroupItemHeading style={{ padding: 5, margin: 0 }}>{trainingActivityName}</ListGroupItemHeading>
                                          <div style={{ padding: 5, margin: 0 }}>
                                            <AvInput placeholder="ID" style={{ height: 30, width: '100%', display: 'none' }} name={trainingActivityIdLabel} />

                                            <Row>
                                              <Col lg={12}>
                                                <Row>
                                                  <Col lg={4}>
                                                    <AvInput value={input.name} style={{ height: 40, width: '100%', marginRight: 10 }} placeholder="Nome do Exercício" name={trainingActivityNameLabel} />
                                                  </Col>
                                                  <Col lg={2}>
                                                    <AvField type="select" value={trainingActivityType} name={trainingActivityActivityTypeLabel} style={{ height: 40, width: 120, marginLeft: 0, marginRight: 10 }}>
                                                      <option>Repetição</option>
                                                      <option>Tempo</option>
                                                      <option>Deslocamento</option>
                                                    </AvField>
                                                  </Col>
                                                  <Col lg={2}>
                                                    <AvField type="select" value={trainingActivityExecutionSpeed} name={trainingActivitySpeedLabel} style={{ height: 40, width: 120, marginLeft: 0, marginRight: 10 }}>
                                                      <option>Lento</option>
                                                      <option>Moderado</option>
                                                      <option>Rápido</option>
                                                    </AvField>
                                                  </Col>
                                                  <Col lg={3}>
                                                    <AvInput value={input.description} style={{ height: 40, width: '100%' }} placeholder="Descrição do Exercício" name={trainingActivityDescriptionLabel} />
                                                  </Col>
                                                  <Col lg={1} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                                                    <button className="btn btn-danger btn-sm" type="button" style={{ width: 20, height: 20, marginRight: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                      onClick={() => _handleRemoveActivity(gidx, aidx)}>X</button>
                                                  </Col>
                                                </Row>

                                                <Row>
                                                  <Col lg={2}>
                                                    <AvInput value={input.repetition} placeholder="Repetições" style={{ height: 30, width: '100%' }} name={trainingActivityRepetitionLabel} />
                                                  </Col>
                                                  <Col lg={2}>
                                                    <AvInput value={input.duration} placeholder="Tempo (min)" style={{ height: 30, width: '100%' }} name={trainingActivityDurationLabel} />
                                                  </Col>
                                                  <Col lg={2}>
                                                    <AvInput value={input.distance} placeholder="Deslocamento" style={{ height: 30, width: '100%' }} name={trainingActivityDistanceLabel} />
                                                  </Col>
                                                  <Col lg={3}>
                                                    <AvInput value={input.media_link} placeholder="Gif (link)" style={{ height: 30, width: '100%' }} name={trainingActivityGifLabel} />
                                                  </Col>
                                                  <Col lg={3}>
                                                    <AvInput value={input.thumb_link} placeholder="Thumb (link)" style={{ height: 30, width: '100%' }} name={trainingActivityThumbLabel} />
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          </div>
                                        </ListGroupItem>
                                      )
                                    } else if (trainingType === 'RUN') {
                                      return (
                                        <ListGroupItem key={`key_act_${aidx}`} style={{ padding: 3 }}>
                                          <ListGroupItemHeading style={{ padding: 5, margin: 0 }}>
                                            <AvInput value={input.name} style={{ height: 40, width: '50%', marginRight: 10 }} placeholder="Nome do Exercício" name={trainingActivityNameLabel} />
                                          </ListGroupItemHeading>
                                          <AvInput placeholder="ID" style={{ height: 30, width: '100%', display: 'none' }} name={trainingActivityIdLabel} />
                                          <ListGroupItemText style={{ padding: 5, margin: 0 }}>
                                            <div style={{ 'display': '-webkit-box' }}>
                                              <AvInput value={input[`${currentActivityType.fieldName}`]} style={{ height: 35, width: 100, marginRight: 10 }} name={trainingActivityDistance} /> {currentActivityType.labelType}
                                              <AvField value={input.zone} type="select" name={trainingActivityZone} style={{ height: 35, width: 120, marginLeft: 40, marginRight: 10 }}>
                                                <option>ZONA-1</option>
                                                <option>ZONA-2</option>
                                                <option>ZONA-3</option>
                                                <option>ZONA-4</option>
                                                <option>ZONA-5a</option>
                                                <option>ZONA-5b</option>
                                                <option>ZONA-5c</option>
                                              </AvField>Zona
                                            </div>
                                          </ListGroupItemText>
                                        </ListGroupItem>
                                      )
                                    }
                                  }
                                  )}
                                  {trainingType !== 'RUN' &&
                                    <Button color="info" style={{ width: 200, height: 40, margin: 5, alignSelf: 'flex-end' }} onClick={() => _handleAddActivity(gidx)}>Adicionar Exercício</Button>}
                                </ListGroup>

                              </CardBody>
                            </Card>
                          )
                        })}

                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
          <Row>

          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => props.toggle(false)}>Cancelar</Button>
          <FormGroup>
            {activeTab === '1' &&
              <Button color="success" onClick={() => toggle('2')}>Avançar</Button>}

            {activeTab === '2' &&
              <Button type="submit" color="success" >Salvar e Fechar</Button>}
          </FormGroup>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}
