import { format, parseISO } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, CustomInput, FormFeedback } from 'reactstrap';

function ModalEditCoupon({ coupon, showModal, toggle, action }) {
    const [code, setCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [discountType, setDiscountType] = useState('PERCENTAGE');
    const [member, setMember] = useState(false);
    const [active, setActive] = useState(true);
    const [emptyDiscount, setEmptyDiscount] = useState(false);
    const [expirationDate, setExpirationDate] = useState('');

    useEffect(() => {
        setCode(coupon.code || '')
        setDiscount(coupon.discount || 0)
        setDiscountType(coupon.discountType || 'PERCENTAGE')
        setMember(coupon.member || false)
        setActive(coupon.active || false)
        setExpirationDate(coupon.expirationDate ? format(parseISO(coupon.expirationDate), 'yyyy-MM-dd') : '');
    },[coupon])

    function handleSubmit() {
        const data = {
            code,
            discount,
            discountType,
            member,
            active,
            expirationDate: expirationDate ? format(parseISO(expirationDate), 'yyyy-MM-dd') : ''
        }

        if (!data.discount) {
            setEmptyDiscount(true)
            return
        }

        if (action) action(data);

        setEmptyDiscount(false)
    }

    return (
        <div>
            <Modal isOpen={showModal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Editar</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="discount">Desconto</Label>
                            <Input type="number" min="0" name="discount" id="discount" value={discount} onChange={e => setDiscount(parseInt(e.target.value))} invalid={emptyDiscount} />
                            <FormFeedback>Deve ser maior que 0</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="discountType">Tipo de desconto</Label>
                            <Input type="select" name="discountType" id="discountType" value={discountType} onChange={e => setDiscountType(e.target.value)}>
                                <option value="PERCENTAGE">Percentual</option>
                                <option value="ABSOLUTE">Monetário</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="expirationDate">Data de expiração</Label>
                            <Input type="date" name="expirationDate" id="expirationDate" value={expirationDate} onChange={e => setExpirationDate(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <CustomInput type="switch" id="member" name="member" label="Para membros?" checked={member} onChange={() => setMember(prev => !prev)} />
                        </FormGroup>
                        <FormGroup>
                            <CustomInput type="switch" id="active" name="active" label="Ativo" checked={active} onChange={() => setActive(prev => !prev)} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>{' '}
                    <Button color="primary" onClick={handleSubmit}>Salvar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ModalEditCoupon;