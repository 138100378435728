import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

import Task from './task'


const Container = styled.div`
  /* margin: 8px; */
  /* border: 1px solid lightgray; */
  /* border-radius: 2px; */
  /* width: 300px; */
  /* background: pink; */
  display: flex;
  flex-direction: column;
  height: '100%';
`

const TaskList = styled.div`
  transition: background-color 0.2s ease;
  background-color: ${ props => ( props.isDraggingOver ? '#f1f1f1' : 'white' )};
  flex-grow: 1;
  min-height: 100px;
`

export default class TrainingItem extends React.Component {
  render () {
    return (
        <Container>
          <Droppable droppableId={this.props.column.id}>
            { (provided, snapshot) => (
              <TaskList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}>

                {this.props.trainings.map( (training, index) => {
                  return (
                    <Task key={training.id} id={`training-${training.id}`} training={training} index={index} showTrainingDetailAction={this.props.showTrainingDetailAction} />
                  )
                })}

                {provided.placeholder}
              </TaskList>
            )}
            
          </Droppable>
        </Container>

    )
  }
}