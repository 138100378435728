import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, CustomInput, FormFeedback, Badge, ListGroup, ListGroupItem } from 'reactstrap';
import api, { api2 } from '~/services/api';
import Loader from '~/components/Loader/Loader'
import { parseISO } from 'date-fns'
import ModalDistance from '../ModalDistance';
import ModalCreateCoupon from '../ModalCreateCoupon';
import { MdEdit, MdCancel } from 'react-icons/md';
import ModalEditCoupon from '../ModalEditCoupon';

function ModalCreate({ showModal, toggle, loadRaces }) {
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [distances, setDistances] = useState([]);
    const [state, setState] = useState('');
    const [states, setStates] = useState([]);
    const [city, setCity] = useState('');
    const [url, setUrl] = useState('');
    const [coupons, setCoupons] = useState([]);
    const [structure, setStructure] = useState(false);
    const [emptyName, setEmptyName] = useState(false)
    const [emptyDate, setEmptyDate] = useState(false)
    const [emptyState, setEmptyState] = useState(false)
    const [emptyCity, setEmptyCity] = useState(false)
    const [loading, setLoading] = useState(false);
    const [modalDistance, setModalDistance] = useState(false);
    const [modalCreateCoupon, setModalCreateCoupon] = useState(false);
    const [modalEditCoupon, setModalEditCoupon] = useState(false);
    const [couponToShow, setCouponToShow] = useState(null)

    useEffect(() => {
        api.get('states').then(response => setStates(response?.data?.estados));
    }, []);

    async function handleSubmit() {
        try {
            setLoading(true)

            if (!date) setEmptyDate(true)

            const data = {
                name,
                date: parseISO(date),
                distance: distances,
                state,
                city,
                coupons,
                structure,
                url
            }

            if (!data.name) setEmptyName(true)
            if (!data.date) setEmptyDate(true)
            if (!data.state) setEmptyState(true)
            if (!data.city) setEmptyCity(true)

            if (!date || !data.name || !data.date || !data.state || !data.city) {
                setLoading(false)
                return
            }

            await api2.post('race_calendar/new_race', data)

            setEmptyName(false)
            setEmptyDate(false)
            setEmptyState(false)
            setEmptyCity(false)

            setLoading(false)

            toast.success('Corrida cadastrada com sucesso')

            loadRaces(1)

            toggle()
        } catch (error) {
            setLoading(false)

            toast.error('Ocorreu um erro ao cadastrar a corrida')
        }
    }

    const handleSetDistance = (value) => {
        if (!distances.some(distance => distance === value)) {
            const newDistances = [...distances, value]

            setDistances(newDistances)
        }

        setModalDistance(false)
    }

    const handleRemoveDistance = (value) => {
        setDistances(distances?.filter(distance => distance !== value))
    }

    const handleSetCoupon = (coupon) => {
        if (!coupons.some(c => c?.code?.toUpperCase() === coupon?.code?.toUpperCase())) {
            const newCoupons = [...coupons, coupon]

            setCoupons(newCoupons)
        }

        setModalCreateCoupon(false)
    }

    const handleRemoveCoupon = (coupon) => {
        setCoupons(coupons?.filter(item => item?.code?.toUpperCase() !== coupon?.code?.toUpperCase()))
    }

    const handleEditCoupon = (coupon) => {
        setCouponToShow(coupon)
        setModalEditCoupon(true)
    }

    const updateCoupons = (coupon) => {
        const newCoupons = coupons?.map(item => {
            if (item?.code?.toUpperCase() === coupon?.code?.toUpperCase()) {
                return coupon
            }

            return item
        })

        setCoupons(newCoupons)
        setModalEditCoupon(false)
    }

    return (
        <div>
            {
                couponToShow &&
                <ModalEditCoupon
                    coupon={couponToShow}
                    showModal={modalEditCoupon}
                    toggle={() => setModalEditCoupon(prev => !prev)}
                    action={updateCoupons}
                />
            }

            <ModalDistance
                showModal={modalDistance}
                toggle={(prev) => setModalDistance(!prev)}
                action={handleSetDistance}
            />

            <ModalCreateCoupon
                showModal={modalCreateCoupon}
                toggle={(prev) => setModalCreateCoupon(!prev)}
                action={handleSetCoupon}
            />

            <Modal isOpen={showModal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Cadastrar prova</ModalHeader>
                <ModalBody>
                    {loading && <Loader />}
                    <Form>
                        <FormGroup>
                            <Label for="name">Digite o nome</Label>
                            <Input type="text" name="name" id="name" value={name} onChange={e => setName(e.target.value)} invalid={emptyName} />
                            <FormFeedback>Nome é obrigatório</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="date">Data</Label>
                            <Input type="date" name="date" id="date" value={date} onChange={e => setDate(e.target.value)} invalid={emptyDate} />
                            <FormFeedback>Data é obrigatória</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="distances">Distâncias</Label>
                            <Badge className="add-distance-badge" onClick={() => setModalDistance(true)} style={{ cursor: 'pointer' }}>
                                Adicionar&nbsp;&nbsp;+
                            </Badge>
                            <br />
                            {
                                distances?.map(value => (
                                    <Badge key={value} className="distances-badge" onClick={() => handleRemoveDistance(value)} style={{ cursor: 'pointer' }}>
                                        {value}&nbsp;&nbsp;X
                                    </Badge>
                                ))
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label for="state">Estado</Label>
                            <Input type="select" name="state" id="state" value={state} onChange={e => setState(e.target.value)} invalid={emptyState}>
                                <option value="">Selecione</option>
                                {
                                    states?.map(state => (
                                        <option key={state?.nome} value={state?.sigla}>{state?.nome}</option>
                                    ))
                                }
                            </Input>
                            <FormFeedback>Estado é obrigatório</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="city">Cidade</Label>
                            <Input type="select" name="city" id="city" value={city} onChange={e => setCity(e.target.value)} invalid={emptyCity}>
                                <option value="">Selecione</option>
                                {
                                    states?.find(item => item?.sigla === state)?.cidades?.map(city => (
                                        <option key={city} value={city}>{city}</option>
                                    ))
                                }
                            </Input>
                            <FormFeedback>Cidade é obrigatória</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="url">Link externo</Label>
                            <Input type="text" name="url" id="url" value={url} onChange={e => setUrl(e.target.value.trim())} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="coupons">Cupons</Label>
                            <Badge className="add-coupon-badge" onClick={() => setModalCreateCoupon(true)} style={{ cursor: 'pointer' }}>
                                Adicionar&nbsp;&nbsp;+
                            </Badge>
                            <br />
                            <ListGroup>
                                {
                                    coupons?.map(coupon => (
                                        <ListGroupItem key={coupon?.code} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            {coupon?.code}

                                            <div>
                                                <Button className="mr-1" color="primary" onClick={() => handleEditCoupon(coupon)}>
                                                    <MdEdit color="white" size={20} />
                                                </Button>
                                                <Button color="danger" onClick={() => handleRemoveCoupon(coupon)}>
                                                    <MdCancel color="white" size={20} />
                                                </Button>
                                            </div>
                                        </ListGroupItem>
                                    ))
                                }
                            </ListGroup>
                        </FormGroup>
                        <FormGroup>
                            <CustomInput type="switch" id="structure" name="structure" label="Com estrutura?" checked={structure} onChange={() => setStructure(prev => !prev)} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>{' '}
                    <Button color="primary" onClick={handleSubmit}>Salvar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ModalCreate;