import styled from 'styled-components';

export const Container = styled.section`
    table {
        padding: 10px;

        th {
            padding: 10px;
        }

        td {
            padding: 10px;
        }
    }

    @media (max-width: 767px) {
        table {
            display: block;
            position: relative;

            thead,
            tbody,
            th,
            td,
            tr {
                display: block;
            }

            td,
            th {
                height: 35px;
                margin-bottom: 10px;
            }

            thead {
                float: left;
            }

            tbody {
                width: auto;
                position: relative;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                white-space: nowrap;
            }

            tbody tr {
                display: inline-block;
            }
        }
    }
`;

export const BannerContainer = styled.div`
  display: flex;
  position: relative;
  width: ${({ width }) => width || 600}px;
  border-radius: 10px;
  background: ${({ color }) => color || '#2A6AC0'} url(${({ image }) => image});
  background-repeat: no-repeat, no-repeat;
  background-position: right, left; 
  background-size: cover;
  flex-direction: row;
  margin-bottom: 8px;
  margin-right: 5px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 13px 0 13px 13px;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  width: 40%;
`;

export const Title = styled.p`
  color: ${({ color }) => color || 'white'};
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: ${({ size }) => size || 20}px;
`;

export const Text = styled.p`
  color: ${({ color }) => color || 'white'};
  font-size: ${({ size }) => size || 16}px;
  margin-bottom: 16px;
`;

export const BannerButton = styled.button`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 5px;
  color: ${({ color }) => color || '#000033'};
  background: ${({ background }) => background || 'white'};
  justify-content: center;
  align-items: center;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  pointer-events: none;
  font-size: ${({ fontSize }) => fontSize || 13}px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const CloseButton = styled.div`
  position: absolute;
  padding: 5px;
  top: 0;
  right: 0;
`;
