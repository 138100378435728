import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdAccountCircle, MdSettings, MdExitToApp } from 'react-icons/md';

import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '~/store/modules/auth/actions';

import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

import profilePic from '~/assets/img/logo-cp-y-sm.png';

export default function ProfileDropdown() {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const dispatch = useDispatch();
    const profile = useSelector(state => state.user.profile);


    function toggleDropdown() {
        setDropdownOpen(!dropdownOpen);
    }

    function handleSignOut() {
        dispatch(signOut());
    }


    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle
                data-toggle="dropdown"
                tag="button"
                className="btn btn-link nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
                onClick={toggleDropdown} aria-expanded={dropdownOpen}>
                <span className="account-user-avatar">
                    <img src={profile.s_photo ? profile.s_photo : profilePic} className="rounded-circle" alt="user" />
                </span>
                <span>
                    <span className="pro-user-name ml-1">{profile.username}  <i className="mdi mdi-chevron-down"></i> </span>
                </span>
            </DropdownToggle>
            <DropdownMenu right className="dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                <div onClick={toggleDropdown}>
                    <div className="dropdown-header noti-title">
                        <h6 className="text-overflow m-0">Bem Vindo!</h6>
                    </div>

                    <React.Fragment key={'1'}>
                        {/* {item.hasDivider ? <DropdownItem divider /> : null} */}

                        <Link to="/perfil" className="dropdown-item notify-item">
                            <MdAccountCircle color="gray" size={22} style={{ marginRight: 10 }} />
                            <span>Minha Conta</span>
                        </Link>
                    </React.Fragment>

                    <React.Fragment key={'2'}>
                        {/* {item.hasDivider ? <DropdownItem divider /> : null} */}

                        <Link to="/" className="dropdown-item notify-item">
                            <MdSettings color="gray" size={22} style={{ marginRight: 10 }} />
                            <span>Configurações</span>
                        </Link>
                    </React.Fragment>

                    <React.Fragment key={'3'}>
                        <DropdownItem divider />

                        <Link onClick={handleSignOut} className="dropdown-item notify-item" to="#">
                            <MdExitToApp color="gray" size={22} style={{ marginRight: 10 }} />
                            <span>Logout</span>
                        </Link>
                    </React.Fragment>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
}