import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Button, Row, Col, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Alert, ListGroup, ListGroupItem, Table,
  UncontrolledCollapse, Card, CardBody, InputGroup, InputGroupAddon, Label
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import UserActivitiesModal from '../../Users/show';
import MAClose from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import { formatTrainingPlanRequestStatus } from '~/services/util'
import Pagination from "react-reactstrap-pagination";
import api from '~/services/api';

export default function AthleteDetailModal(props) {
  const profile = useSelector(state => state.user.profile);
  const [show, setShow] = useState(props.open);
  const [athlete, setAthlete] = useState(props.athlete);
  const [context, setContext] = useState(props.context || 'calendar');
  const [zones, setZones] = useState([]);
  const [coach, setCoach] = useState();
  const [trainingPlan, setTrainingPlan] = useState();
  const [currentActivityType, setCurrentActivityType] = useState({ name: 'Selecionar Tipo', id: -1 });
  const [folders, setFolders] = useState([]);
  const [showDDFolder, setShowDDFolder] = useState(false);
  const [currentFolder, setCurrentFolder] = useState({ name: 'Selecionar Pasta', id: -1 });
  const [showUserActivitiesModal, setShowUserActivitiesModal] = useState(false);
  const [trainingPlanDate, setTrainingPlanDate] = useState();
  const [plansToAssociate, setPlansToAssociate] = useState([]);
  const [athletePlans, setAthletePlans] = useState([]);
  const [athletePlansRequests, setAthletePlansRequests] = useState([]);
  const [searchTrainingPlanQuery, setSearchTrainingPlanQuery] = useState();
  const [trainingPlansList, setTrainingPlansList] = useState([]);
  const [showDDStartEndDate, setShowDDStartEndDate] = useState(false);
  const [userTrainingPlansTotal, setUserTrainingPlansTotal] = useState(0)
  const [userTrainingPlansPerPage, setUserTrainingPlansPerPage] = useState(5)
  const [userTrainingPlansSelectedPage, setUserTrainingPlansSelectedPage] = useState(1)
  const [userTrainingPlansRequestsTotal, setUserTrainingPlansRequestsTotal] = useState(0)
  const [userTrainingPlansRequestsPerPage, setUserTrainingPlansRequestsPerPage] = useState(5)
  const [userTrainingPlansRequestsSelectedPage, setUserTrainingPlansRequestsSelectedPage] = useState(1)
  const [coachsList, setCoachsList] = useState([]);
  const [currentCoach, setCurrentCoach] = useState({ name: 'Selecione', id: -1 });
  const [showDDCoach, setShowDDCoach] = useState(false);

  const chooseCoach = (coach) => {
    setCurrentCoach(coach)
    api.post(`associate_coach?userId=${athlete.id}&coachId=${coach.id}`)
  }


  useEffect(() => {
    Promise.all([api.get(`/training_plans?userId=${athlete.id}&calendar=true`), api.get(`/folders`), api.get(`/training_plans?userId=${athlete.id}&page=1`), api.get(`/get_user_requests?userId=${athlete.id}&page=1`),
    api.get(`/user_zones?user_id=${athlete.id}&limit=3`), api.get(`/user_adms`)])
      .then(async ([trainingPlanResp, foldersResp, applyTPToUser, tpRequestsResp, zonesResp, coaches]) => {
        if (coaches.data && coaches.data.length > 0) {
          setCoachsList(coaches.data);
        }
        if (trainingPlanResp.data && trainingPlanResp.data.length > 0) {
          const tp = trainingPlanResp.data[0];
          setTrainingPlan(tp);
          const coat = await api.get(`/users/${tp.coach_id}`);
          setCoach(coat.data)
        }

        if (zonesResp.data && zonesResp.data.length > 0) {
          setZones(zonesResp.data)
        }
        if (athlete.coach_id) {
          const ownerCoach = await api.get(`/users/${athlete.coach_id}`);
          setCurrentCoach(ownerCoach.data)
        }

        setFolders(foldersResp.data)

        setAthletePlans(applyTPToUser.data.data)
        setUserTrainingPlansTotal(applyTPToUser.data.total)
        setUserTrainingPlansPerPage(applyTPToUser.data.perPage)
        setUserTrainingPlansSelectedPage(applyTPToUser.data.page)

        setAthletePlansRequests(tpRequestsResp.data.data)
        setUserTrainingPlansRequestsTotal(tpRequestsResp.data.total)
        setUserTrainingPlansRequestsPerPage(tpRequestsResp.data.perPage)
        setUserTrainingPlansRequestsSelectedPage(tpRequestsResp.data.page)
      });

    if (athlete.folder) {
      setCurrentFolder(athlete.folder);
    }
  }, [athlete])

  const updateTrainingPlans = async (selectedPage = null) => {
    Promise.all([api.get(`/training_plans?userId=${athlete.id}&page=${selectedPage || 1}`)])
      .then(async ([applyTPToUser]) => {
        setAthletePlans(applyTPToUser.data.data)
        setUserTrainingPlansTotal(applyTPToUser.data.total)
        setUserTrainingPlansPerPage(applyTPToUser.data.perPage)
        setUserTrainingPlansSelectedPage(applyTPToUser.data.page)
      });
  }

  const updateTrainingPlansRequests = async (selectedPage = null) => {
    Promise.all([api.get(`/get_user_requests?userId=${athlete.id}&page=${selectedPage || 1}`)])
      .then(async ([tpRequestsResp]) => {
        setAthletePlansRequests(tpRequestsResp.data.data)
        setUserTrainingPlansRequestsTotal(tpRequestsResp.data.total)
        setUserTrainingPlansRequestsPerPage(tpRequestsResp.data.perPage)
        setUserTrainingPlansRequestsSelectedPage(tpRequestsResp.data.page)
      });
  }

  const chooseFolder = async (elem) => {
    try {
      const userToUpdate = { folder_id: elem.id }

      const response = await api.put(`/users/${athlete.id}`, userToUpdate)

      if (response.status === 200) {
        setCurrentFolder(elem)
        toast.success('A pasta/grupo atualizada com sucesso')
      } else {
        toast.warn('Erro ao atualizar pasta/grupo')
      }
    } catch (error) {
      toast.error('Aconteceu um erro ao atualizar pasta/grupo')
    }
  }

  const handleUserDetailModal = async () => {
    setShowUserActivitiesModal(!showUserActivitiesModal);
  }

  const deassociate = async (planID) => {
    try {
      var result = window.confirm("Tem certeza que deseja remover esse plano do usuário?");
      if (result) {
        const resp = await api.post(`/training_plans/${planID}/deassociate`, {})

        if (resp.status === 200) {
          toast.success('Plano de treino desaplicado com sucesso')
        } else {
          toast.warn('Erro ao desaplicar plano de treino')
        }

        updateTrainingPlans()
      }
    } catch (error) {
      toast.error('Erro ao desaplicar plano de treino')
    }
  }


  const handleSentPushNotification = async (user) => {
    toast.success('Push Notification enviado com sucesso')

    const { data } = await api.post(`/sendPush/${user.id}`);
  }

  const searchTrainingPlans = async () => {
    const { data } = await api.get(`training_plans?searchQuery=${searchTrainingPlanQuery}`)
    setTrainingPlansList(data)
  }

  const selectUser = async (elem) => {
    var index = plansToAssociate.indexOf(elem);

    if (index > -1) {
      plansToAssociate.splice(index, 1);
    } else {
      plansToAssociate.push(elem)
    }
    await setPlansToAssociate(plansToAssociate)
  }

  const associateTPToUsers = async () => {
    if (!plansToAssociate || plansToAssociate.length <= 0) {
      toast.error('Selecione ao menos um plano de treino para vincular ao atleta')
      return;
    }

    if (!trainingPlanDate) {
      toast.error('Selecione uma data de início')
      return;
    }

    try {
      const tpAssociate = {
        training_plan_id: plansToAssociate[0],
        coach_id: profile.id,
        users: [athlete.id],
        start_date: trainingPlanDate,
      }

      const response = await api.post(`/training_plans/associate`, tpAssociate)

      if (response.status === 200) {
        toast.success('Plano de Treino associado com sucesso ao usuário')
      } else {
        toast.warn('Erro ao associar o plano de treino ao usuário')
      }

      updateTrainingPlans()
    } catch (error) {
      toast.error('Aconteceu um erro ao associar o plano de treino ao usuário')
    }
  }

  const loadAthleteCalendar = async () => {
    props.loadedItem(athlete, 'athlete')
    props.toggle(!show)
    props.changeTab('3');
  }

  const updateAthleteRequest = async (req, status) => {
    const updateRequestResp = await api.put(`training_plan_requests/${req.id}`, { status })

    Promise.all([api.get(`/get_user_requests?userId=${athlete.id}&page=1`)])
      .then(async ([tpRequestsResp]) => {
        setAthletePlansRequests(tpRequestsResp.data.data)
        setUserTrainingPlansRequestsTotal(tpRequestsResp.data.total)
        setUserTrainingPlansRequestsPerPage(tpRequestsResp.data.perPage)
        setUserTrainingPlansRequestsSelectedPage(tpRequestsResp.data.page)
      });
  }

  const formatDaysWeeklyWorkout = (days) => {
    const daysArr = days.split(',')
    const daysResult = []

    daysArr.forEach(day => {
      switch (day) {
        case '1':
          daysResult.push('Segunda')
          break;
        case '2':
          daysResult.push('Terça')
          break;
        case '3':
          daysResult.push('Quarta')
          break;
        case '4':
          daysResult.push('Quinta')
          break;
        case '5':
          daysResult.push('Sexta')
          break;
        case '6':
          daysResult.push('Sábado')
          break;
        case '0':
          daysResult.push('Domingo')
          break;

        default:
          daysResult.push('--')
          break;
      }
    });

    let daysStr = ''

    daysResult.forEach((dStr, dIdx) => {
      daysStr += dIdx === 0 ? dStr : ` / ${dStr}`
    });

    return daysStr
  }

  return (
    <Modal isOpen={show} toggle={() => props.toggle(!show)} style={{ maxWidth: '1600px', width: '70%', maxHeight: '800px', height: '80%' }}>
      <ModalHeader toggle={() => props.toggle(!show)}>{athlete.name} &nbsp; #{athlete.id} - {athlete.roles && athlete.roles.length > 0 ? athlete.roles[0].name : 'N/A'}</ModalHeader>

      <ModalBody>
        <Row>
          <Col lg={6}>
            <Row style={{ marginBottom: 15 }}>
              <Col lg={2}>Treinador</Col>
              <Col lg={10}>
                <Dropdown size="sm" isOpen={showDDCoach} toggle={() => setShowDDCoach(!showDDCoach)} >
                  <DropdownToggle style={{ width: 250, marginRight: 10 }}> {currentCoach?.name} </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true, order: 890, fn: (data) => {
                          return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                        }
                      }
                    }} >
                    {coachsList.map((elem, index) => {
                      return (
                        <DropdownItem key={`co_${index}`} onClick={() => chooseCoach(elem)}>{elem.name}</DropdownItem>
                      )
                    })}
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
            {/* <Row>
                            <Col lg={12}>
                                <Avatar alt={athlete.name} src={athlete.s_photo} style={{width: 80, height: 80}} />
                            </Col>
                        </Row> */}
            <Row>
              <Col lg={5}>
                <h3>Perfil do usuário</h3>
              </Col>
              <Col lg={4}>
                <Button color="secondary" onClick={() => handleUserDetailModal()}>Lista de atividades</Button>
              </Col>
              <Col lg={3}>
                <Button color="secondary" onClick={() => loadAthleteCalendar()}>Calendário</Button>
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={3}><span style={{ fontWeight: '550' }}> Sexo: <span style={{ fontWeight: '400' }}>{athlete.genre}</span></span></Col>
              <Col lg={3}><span style={{ fontWeight: '550' }}> Idade: <span style={{ fontWeight: '400' }}>{moment().diff(athlete.birth, 'years', false)}</span></span></Col>
              <Col lg={3}><span style={{ fontWeight: '550' }}> Altura: <span style={{ fontWeight: '400' }}>{athlete.height}</span></span></Col>
              <Col lg={3}><span style={{ fontWeight: '550' }}> Peso: <span style={{ fontWeight: '400' }}>{athlete.weight}</span></span></Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={12}><span style={{ fontWeight: '550' }}>Dias base p/ corrida: <span style={{ fontWeight: '400' }}>{athlete.days_weekly_workout ? formatDaysWeeklyWorkout(athlete.days_weekly_workout) : '--/--/--'}</span></span></Col>
              <Col lg={12} style={{ marginTop: 15 }}>
                <b>Desempenho:</b> <br /> <br />

                <span>5km: {athlete.km5_time || '--'} min em {athlete.km5_date ? moment(athlete.km5_date).format('DD/MM/YYYY') : '--'}</span> <br />
                <span>10km: {athlete.km10_time || '--'} min em {athlete.km10_date ? moment(athlete.km10_date).format('DD/MM/YYYY') : '--'}</span> <br />
                <span>21km: {athlete.km21_time || '--'} min em {athlete.km21_date ? moment(athlete.km21_date).format('DD/MM/YYYY') : '--'}</span> <br />
                <span>42km: {athlete.km42_time || '--'} min em {athlete.km42_date ? moment(athlete.km42_date).format('DD/MM/YYYY') : '--'}</span> <br />
              </Col>
              <Col lg={12} style={{ marginTop: 15 }}>
                <b>Testes de zonas mais recentes:</b> <br />
                {
                  zones.map(zone => (
                    <>
                      <span>{zone ? zone.pace : '--'} min/km {zone ? zone.bpm_avg : '--'} BPM em {zone && zone.execution_date ? moment(zone.execution_date).format('DD/MM/YYYY') : '--'}</span>
                      <br />
                    </>
                  ))
                }
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col>
                <b>Modelos de plano já atribuídos:</b>
                <Table size="sm" style={{ marginTop: 10 }}>
                  <thead>
                    <tr>
                      <th>Modelo</th>
                      <th>Início</th>
                      <th>Fim</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {athletePlans.map((aPlan, apIdx) => {
                      return (
                        <tr key={`ap_${apIdx}`}>
                          <th scope="row" style={{ textDecoration: aPlan?.status === 'DEACTIVATED' ? 'line-through' : 'none' }}>{aPlan.internal_name}</th>
                          <td>{moment(aPlan.start_date).format('DD/MM/YYYY')}</td>
                          <td>{moment(aPlan.end_date).format('DD/MM/YYYY')}</td>
                          <td>
                            {aPlan?.status !== 'DEACTIVATED' &&
                              <Button onClick={() => deassociate(aPlan.id)} outline color="danger" style={{ display: 'flex', borderRadius: '50%', width: 30, height: 30, justifyContent: 'center', alignItems: 'center' }}>
                                <MAClose style={{ fontSize: 15 }} />
                              </Button>
                            }
                          </td>
                        </tr>
                      )
                    })}
                    <tr>
                      <Pagination
                        size="sm"
                        firstPageText="Início"
                        lastPageText="Fim"
                        previousPageText="Anterior"
                        nextPageText="Próximo"
                        totalItems={userTrainingPlansTotal}
                        pageSize={userTrainingPlansPerPage}
                        defaultActivePage={1}
                        onSelect={updateTrainingPlans}
                      />
                    </tr>
                  </tbody>
                </Table>

              </Col>
            </Row>
          </Col>

          {/* Solicitação de treino */}
          <Col lg={6}>
            <h4>Solicitações de treino recentes</h4>

            <Table size="sm" style={{ marginTop: 5 }}>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Data</th>
                  <th>Resp</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {athletePlansRequests.map((tpR, tprIdx) => {
                  // [{id: 'OPEN', name: 'Aberto'}, {id: 'FINISHED', name: 'Concluído'},  {id: 'CREATED', name: 'Novo'}, {id: 'CANCELED', name: 'Cancelado'}]

                  const isToFinish = tpR.status === 'OPEN' || tpR.status === 'CREATED'
                  const isToReopen = tpR.status === 'CANCELED' || tpR.status === 'FINISHED'

                  let buttonLabel = '';
                  let buttonColor = '';
                  let buttonStatusToChage = ''

                  if (isToFinish) {
                    buttonLabel = 'Concluir'
                    buttonColor = 'success'
                    buttonStatusToChage = 'FINISHED'
                  } else if (isToReopen) {
                    buttonLabel = 'Reabrir'
                    buttonColor = 'secondary'
                    buttonStatusToChage = 'OPEN'
                  }


                  return (
                    <tr>
                      <th scope="row">{tpR.title} ({tpR.subtitle})</th>
                      <td>{moment(tpR.created_at).format('DD/MM/YYYY')}</td>
                      <td>{tpR.coach?.name || '--'}</td>
                      <td>{formatTrainingPlanRequestStatus(tpR.status)}</td>
                      <td>
                        <Button onClick={() => updateAthleteRequest(tpR, buttonStatusToChage)} color={buttonColor}>
                          <b>{buttonLabel}</b>
                        </Button>
                      </td>
                    </tr>
                  )
                })}
                <tr>
                  <Pagination
                    size="sm"
                    firstPageText="Início"
                    lastPageText="Fim"
                    previousPageText="Anterior"
                    nextPageText="Próximo"
                    totalItems={userTrainingPlansRequestsTotal}
                    pageSize={userTrainingPlansRequestsPerPage}
                    defaultActivePage={1}
                    onSelect={updateTrainingPlansRequests}
                  />
                </tr>
              </tbody>
            </Table>
            <Row>
              <Col lg={12}>
                <p style={{ fontWeight: 'bold', marginTop: 20 }}>Atribuir modelo de plano de treino</p>
                <div>
                  <Button color="secondary" outline id="toggler" style={{ marginBottom: '0rem', width: '100%' }}>Atribuir modelo</Button>
                  <UncontrolledCollapse toggler="#toggler">
                    <Card>
                      <CardBody style={{ padding: 0 }}>
                        <InputGroup style={{ margin: 5, width: '98%' }}>
                          <Input state={{ width: '90%' }} onChange={(e) => setSearchTrainingPlanQuery(e.target.value)} />
                          <InputGroupAddon addonType="append">
                            <Button color="info" onClick={() => searchTrainingPlans()}>pesquisar</Button>
                          </InputGroupAddon>
                        </InputGroup>

                        <PerfectScrollbar style={{ maxHeight: 200 }}>
                          <table className="table table-sm mb-0">
                            <tbody>

                              {trainingPlansList.map((tp, tpIdx) => {
                                return (
                                  <tr key={`idd_${tpIdx}`}>
                                    <td>
                                      <FormGroup check>
                                        <Label check>
                                          <Input type="checkbox" onClick={() => selectUser(tp.id)} />{' '} #{tp.id} - {tp.internal_name} ({tp.name})
                                        </Label>
                                      </FormGroup>
                                    </td>
                                  </tr>

                                )
                              })}
                            </tbody>
                          </table>
                        </PerfectScrollbar>

                        <hr style={{ borderColor: '#cccccc' }} />

                        <div style={{ display: 'flex', margin: 5, width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <b style={{ width: 100 }}>Iniciar em </b>
                            <Input type="date" size="sm" name="date" id="exampleDate" onChange={(e) => setTrainingPlanDate(e.target.value)} placeholder="date placeholder" style={{ marginLeft: 10 }} />
                          </div>

                          <div>
                            <Button color="secondary" size="sm" outline style={{ marginRight: 10 }} onClick={() => associateTPToUsers()}>Aplicar</Button>
                          </div>
                        </div>

                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>



        {/* <Row>
                        <Col lg={12}>
                            <Row>
                                <Col lg={2}>
                                    
                                </Col>

                                <Col lg={10}>
                                    <span>Nome: {athlete.name}</span> <br />
                                    <span>Email: {athlete.email}</span> <br />
                                    <span>Nascimento: {moment(athlete.birth).format('DD/MM/YYYY')}</span> <br />
                                    <span>Peso: {athlete.weight} &nbsp;&nbsp;&nbsp; Altura: {athlete.height} &nbsp;&nbsp;&nbsp; Sexo: {athlete.genre}</span> <br />
                                </Col>
                            </Row>
                            <br />  
                            <p>Bio: {athlete.bio}</p> 
                            <span>Notas: N/A</span> <br /><br /><br />

                            <span>Endereço: {athlete.addresses && athlete.addresses.length > 0 ? 
                            `${athlete.addresses[0].street}, ${athlete.addresses[0].number}, ${athlete.addresses[0].district}, ${athlete.addresses[0].city} - ${athlete.addresses[0].state}` : 'N/A'  }</span> <br />

                            <span>Telefone: N/A</span> <br />
                            <span>Celular: N/A</span> <br /><br />

                            <span>Plataforma: {athlete.platform}</span> <br />
                            <span>Versão do APP: {athlete.app_version}</span> <br />
                            <span>Push: {athlete.push_token ? 'SIM' : 'NÃO'}</span> &nbsp;&nbsp;&nbsp; 
                            {athlete.push_token &&<Button outline size="sm" color="info" onClick={() => handleSentPushNotification(athlete)}>Enviar Push Notification</Button> } <br /><br />
                            <span>Ultimo Acesso: {athlete.last_access ? moment(athlete.last_access).format('DD/MM/YYYY') : 'N/A'}</span> <br />
                            <span>Integrações: {athlete.s_username ? `Strava (@${athlete.s_username})`: 'N/A'}</span> <br /><br />

                            <span>Plano de Treino: {trainingPlan ? `#${trainingPlan.id} ${trainingPlan.name} (${trainingPlan.internal_name})` : 'N/A'}</span> &nbsp;&nbsp;
                            {trainingPlan &&<Button outline size="sm" color="danger" onClick={() => deassociate(trainingPlan.id)}>Desaplicar</Button> } <br />
                            <span>Início: {trainingPlan ? moment(trainingPlan.start_date).format('DD/MM/YYYY') : 'N/A'} &nbsp;&nbsp;&nbsp;
                            Fim: {trainingPlan ? moment(trainingPlan.end_date).format('DD/MM/YYYY') : 'N/A'}</span> <br />
                            <span>Treinador: {coach ? coach.name : 'N/A'}</span> <br /><br />

                            <p>Zonas: Z1, Z2..</p><br /><br />

                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                Pasta/Grupo:&nbsp;&nbsp;
                                <Dropdown size="sm" isOpen={showDDFolder} toggle={() => setShowDDFolder(!showDDFolder)} >
                                    <DropdownToggle style={{width: 150, marginRight: 10}}> {currentFolder.name} </DropdownToggle>
                                    <DropdownMenu
                                        modifiers={{
                                        setMaxHeight: { enabled: true, order: 890, fn: (data) => {
                                            return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, }}}}}} >
                                                {folders.map((elem, index) => {
                                                return (
                                                    <DropdownItem key={`fkey_${index}`} onClick={() => chooseFolder(elem)}>{elem.name}</DropdownItem>
                                                )
                                                })}
                                    </DropdownMenu>
                                </Dropdown>
                            </div> <br />

                            <span>Data de criação: {moment(athlete.created_at).format('DD/MM/YYYY')}</span> <br />
                        </Col>
                    </Row> */}
      </ModalBody>
      <ModalFooter>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            <Button color="danger" onClick={() => props.toggle(!show)}>Cancelar</Button>
          </div>
          <div>
            {!props.justShow && <Button color="secondary" onClick={() => {
              loadAthleteCalendar();
            }} style={{ marginRight: 10 }}>Carregar</Button>}
            {!props.justShow && <Button color="success" onClick={() => props.toggle(!show)}>Salvar & Fechar</Button>}
          </div>
        </div>
      </ModalFooter>

      {showUserActivitiesModal && <UserActivitiesModal open={showUserActivitiesModal} user={athlete} toggle={handleUserDetailModal} />}
    </Modal>
  );
}
