import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input, FormText } from 'reactstrap';
import Loader from '~/components/Loader/Loader';
import { toast } from 'react-toastify'
import { api2 } from '~/services/api'

export default function NewContentModal(props) {
  const [loading, setLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)

  const [title, setTitle] = useState('')
  const [url, setUrl] = useState('')
  const [type, setType] = useState('')
  const [highlight, setHighlight] = useState(false)
  const [image, setImage] = useState('')
  const [order, setOrder] = useState('')

  const modalTitle = props.editId ? 'Editar Conteúdo' : 'Novo Conteúdo'

  const getContent = useCallback(async()  =>{
    if (props.editId) {
      api2.get(`content/${props.editId}`)
      .then((response) => {
        const { data } = response
        
        setUrl(data.action_url)
        setHighlight(data.highlight)
        setImage(data.image)
        setTitle(data.title)
        setType(data.type)
        setOrder(data.order)
      })
      .catch(() => {
        toast.error('Erro ao editar o conteúdo')
      })
    }
  }, [props.editId])

  const handleContentImage = async (event) => {
    if (event.target.files.length) {
      const file = event.target.files[0];

      const contentImage = new FormData();

      contentImage.append('file', file);

      setImageLoading(true)

      api2.post('/file/content-image', contentImage)
      .then((response) => {
        const { data } = response;
        
        if (data.Location) {
          setImage(data.Location)
        }
      }).finally(() => {
        setImageLoading(false)
      });
    }
  };

  async function handleSaveLecture() {
    if (title === '' || url === '' || type === '' || image === '' || order === '') {
      toast.warn('Preencha todos os campos corretamente')
      return
    }

    const data = {
      title,
      action_url: url,
      type,
      highlight,
      image,
      order: Number(order)
    }

    setLoading(true)

    try {
      if (props.editId) {
        await api2.put(`/content/${props.editId}`, data)
      } else {
        await api2.post('/content', data)
      }

      props.loadContent()

      setLoading(false)

      props.toggle()
    } catch (error) {
      setLoading(false)
      if (props.editId) {
        toast.error('Erro ao editar o conteúdo')
      } else {
        toast.error('Erro ao cadastrar novo conteúdo')
      }
    }
  }

  useEffect(() => {
    if(props.editId) {
      getContent()
    }
  }, [props.editId, getContent])

  return (
    <div>
      <Modal size="xl" isOpen={props.open} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          {loading && <Loader />}
          <Form>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="title">Título</Label>
                  <Input type="text" name="title" id="title" placeholder="Título" value={title} onChange={e => setTitle(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="link">URL do Conteúdo</Label>
                  <Input type="text" name="link" id="link" placeholder="URL do Conteúdo" value={url} onChange={e => setUrl(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={3}>
                <FormGroup>
                  <Label for="content-type">Tipo: </Label>
                  <Input type="select" name="content-type" id="content-type" placeholder="Tipo do Conteúdo" value={type} onChange={e => setType(e.target.value)} >
                    <option value="">Selecione uma opção</option>
                    <option value="BLOG">Blog</option>
                    <option value="PODCAST">Podcast</option>
                    <option value="YOUTUBE">Youtube</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="order">Ordem:</Label>
                  <Input type="number" min="1" name="order" id="order" placeholder="Ordem" value={order} onChange={e => setOrder(e.target.value)} />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup
                  check
                  inline
                >
                  <Input type="checkbox" checked={highlight} onChange={() => setHighlight(!highlight)}/>
                  <Label check>
                    Conteúdo destaque
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="fileImage">
                    Imagem
                  </Label>
                  <Input
                    id="fileImage"
                    name="fileImage"
                    type="file"
                    onChange={handleContentImage}
                  />
                  <FormText>
                    Adicione a imagem relacionada ao conteúdo.
                  </FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                {imageLoading && <Loader/>}
                {image && <img alt={`${title}`} src={image} height={300}/>}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={props.toggle}>Cancelar</Button>{' '}
          <Button color="success" onClick={handleSaveLecture}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}