import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { applyColorToStatus, translateObjectKeys, translateStatus } from "./utils";
import moment from "moment";

const ReferralLogEditModal = ({ open, order, toggle, onSave }) => {
  const [orderStatus, setOrderStatus] = useState(order.status);

  const handleStatusChange = (e) => {
    setOrderStatus(e.target.value);
  };

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Gerenciar Pedido</ModalHeader>
      <ModalBody>
        <h4>Detalhes:</h4>
        <FormGroup>
          <Label for="requestDate">Data da solicitação</Label>
          <p id="requestDate">{moment(order.created_at).format("DD/MM/YYYY")}</p>
        </FormGroup>
        <FormGroup>
          <Label for="status">Status</Label>
          <p className={applyColorToStatus(orderStatus)} id="status">{translateStatus(orderStatus)}</p>
        </FormGroup>
        <FormGroup>
          <Label for="userName">Nome do usuário que solicitou</Label>
          <p id="userName">{order.user.name}</p>
        </FormGroup>
        <FormGroup>
          <Label for="userName">E-mail do usuário que solicitou</Label>
          <p id="userName">{order.user.email}</p>
        </FormGroup>
        <FormGroup>
          <Label for="deliveryAddress">Telefone para contato</Label>
          <p id="deliveryAddress">
            {order.phone?.number}
          </p>
        </FormGroup>
        <FormGroup>
          <Label for="deliveryAddress">Endereço para entrega</Label>
          <div id="deliveryAddress">
            <div>
              Logradouro: {order.address?.street}
              {''}
            </div>
            <div>
              Número:{order.address?.number}
              {''}
            </div>
            <div>
              Bairro:{order.address?.district}
              {''}
            </div>
            <div>
              Cidade{order.address?.city}
              {''}
            </div>
            <div>
              Estado:{order.address?.state}
              {''}
            </div>
            <div>
              CEP: {order.address?.zipcode}
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="totalPoints">Total de pontos gastos</Label>
          <p id="totalPoints">
            {order.items.reduce((accumulator, item) => {
              return accumulator + item.used_points * item.quantity;
            }, 0)}
          </p>
        </FormGroup>
        <h4>{`Ite${order.items.length > 1 ? 'ns' : 'm'}:`}</h4>
        {order.items.map((item) => (
          <div key={item.id}>
            <hr />
            <h4>{item.referral_reward_name}</h4>
            <FormGroup>
              <Label for="quantity">Quantidade do item</Label>
              <p id="quantity">{item.quantity}</p>
            </FormGroup>
            <FormGroup>
              <Label for="itemPoints">
                Pontos por unidade
              </Label>
              <p id="itemPoints">{item.used_points}</p>
            </FormGroup>
            {Object.keys(item).map((key) => {
              if (
                key === "id" ||
                key === "referral_reward_request_id" ||
                key === "referral_reward_id" ||
                key === "referral_reward_name" ||
                key === "created_at" ||
                key === "updated_at" ||
                key === "used_points" ||
                key === "quantity"
              ) {
                return null;
              }

              if (item[key] !== null) {
                return (
                  <FormGroup key={key}>
                    <Label for={key}>{translateObjectKeys(key)}</Label>
                    <p id={key}>{item[key]}</p>
                  </FormGroup>
                );
              }
            })}
          </div>
        ))}
        <h4>Ações:</h4>
        <FormGroup>
          <Label for="status">Alterar status</Label>
          <Input
            type="select"
            name="status"
            id="status"
            value={orderStatus}
            onChange={(e) => handleStatusChange(e)}
          >
            <option value="PENDING">Pendente</option>
            <option value="FINISHED">Finalizado</option>
            <option value="CANCELED">Cancelado</option>
          </Input>
        </FormGroup>{" "}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            onSave({ ...order, status: orderStatus },);
            toggle();
          }}
        >
          Salvar
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReferralLogEditModal;
