import React from 'react';
import styled from 'styled-components';

import {  Card, Row, Col } from 'reactstrap';
import Avatar from '@material-ui/core/Avatar';
import EventNote from '@material-ui/icons/EventNote';

import {formatTrainingIcon} from '~/services/util'

import { Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
  /* border: 1px solid lightgray; */
  /* border-radius: 2px; */
  /* padding: 8px; */
  /* margin-bottom: 8px;  */
  /* background-color: ${props => (props.isDragging ? 'green' : 'white')}; */
`

function formatTime (time) {
  var sec_num = parseInt(time, 10); // don't forget the second param
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours+':'+minutes+':'+seconds;
}

export default class Task extends React.Component {

  render () {

    const { id, tId, tName, tIName, currTraining } = this.props.training;

    const time = currTraining.duration ? formatTime(currTraining.duration*60) : '--:--:--';
    const distance = currTraining.distance ? `${currTraining.distance} km` : '-- km';

    return (
        <Draggable draggableId={id} index={this.props.index} >
          { (provided, snapshot) => (

          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging} >  

              {/* {this.props.training.content}  */}
            <Card body style={{padding: 7, margin: 5}} id={`training-${tId}`} className="shadow" onClick={() => this.props.showTrainingDetailAction({tId})}>
              <Row>
                  <Col lg={2}>
                      <Avatar alt={tName} src="" ariant="rounded" style={{ color: '#fff' }} >
                        {formatTrainingIcon(currTraining.type)}
                        {/* <EventNote /> */}
                        {/* FABERFABER */}
                    </Avatar>
                  </Col>
                  <Col lg={10} style={{paddingTop: 3}}>
                      <span style={{fontSize: 14, color: 'black'}}>{tIName}</span> <br />
                      <span style={{fontSize: 12}}>#{tId}</span> | <span style={{fontSize: 11, fontWeight: 'bold'}}>{time}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontSize: 11, fontWeight: 'bold'}}>{distance}</span>
                      {currTraining.visible_type && currTraining.visible_type.includes('2') &&
                        <>
                          &nbsp;&nbsp;<span className="badge badge-success" style={{fontSize: 11, fontWeight: 'bold'}}>Explorar</span>
                        </>}
                      {currTraining.visible_type && currTraining.visible_type.includes('1') &&
                        <>
                          &nbsp;&nbsp;<span className="badge badge-info" style={{fontSize: 11, fontWeight: 'bold'}}>ADM</span>
                        </>}
                  </Col>
              </Row>
            </Card>
          </Container>

            // <Container 
            //   {...provided.draggableProps}
            //   {...provided.dragHandleProps}
            //   ref={provided.innerRef}
            //   isDragging={snapshot.isDragging}>  
              

            //   {this.props.training.content} 
            // </Container>
          )}


        </Draggable>
    )

  }
}