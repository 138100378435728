import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import moment from 'moment';
import api from '~/services/api';
import Loader from '~/components/Loader/Loader';

export default class Courses extends Component {
    constructor(props) {
        super(props);

        this.loadData = this.loadData.bind(this);

        this.state = {
            courses: [],
            pageData: [],
            loading: true,
            courseNewModal: false,
            courseShowModal: false,
            courseToSave: { courseModules: [] },
            isCourseEdit: false,
            courseToShow: null
        }

        this.loadData();
    }

    loadData = async () => {
        const { data } = await api.get('courses');

        this.setState({ ...this.state, courses: data, loading: false });
    }

    formatTrainingVisibility = (visible, type) => {
        const has = visible.split(',').filter((item) => item === `${type}`);

        return has && has.length !== 0
    }

    handleEditCourse = (course) => {
        alert(`Editar curso: ${course.id}`)
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loader />}
                <Row>
                    <Col>
                        <div className="page-title-box">
                            <Row>
                                <Col lg={7}>
                                    <h4 className="page-title">Cursos</h4>
                                </Col>
                                <Col lg={5} className="mt-lg-3 mt-md-0">

                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card style={{ overflow: 'auto' }}>
                            <CardBody>
                                <p>Lista de Cursos</p>
                                <Button style={{ marginRight: 10, marginBottom: 10 }} color="success" onClick={() => this.setState({ ...this.state, courseNewModal: true, courseToSave: { courseModules: [] } })}>Cadastrar novo Curso</Button>
                                <>
                                    <table className="table table-centered table-hover mb-0" id="btn-editable">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Descrição</th>
                                                <th>Criação</th>
                                                <th className="tabledit-toolbar-column"></th>
                                                <th className="tabledit-toolbar-column"></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.courses.map((data, dIdx) => (
                                                <tr id="1" key={`id_${dIdx}`}>
                                                    <td><span className="tabledit-span tabledit-identifier">{data.id}</span></td>

                                                    <td className="tabledit-view-mode">
                                                        <span className="tabledit-span">
                                                            <button style={{}} className="btn btn-link" color="primary" onClick={() => this.setState({ ...this.state, courseToShow: data, courseShowModal: true })}>{data.internal_name}</button> <br />
                                                            <span style={{ fontSize: 12, color: '#a1a1a1', marginLeft: 15 }}>({data.name})</span>
                                                        </span>
                                                    </td>

                                                    <td className="tabledit-view-mode"><span className="tabledit-span">{data.description}</span></td>
                                                    <td className="tabledit-view-mode"><span className="tabledit-span">{data.created_at ? moment(data.created_at).format('DD/MM/YYYY') : '-'}</span></td>
                                                    <td className="tabledit-view-mode"><Button color="primary" onClick={() => this.handleEditCourse(data)}>Editar</Button></td>
                                                    <td className="tabledit-view-mode"><Button color="danger" onClick={() => alert(`Apagar curso: ${data.id}`)}>Apagar</Button></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* {this.state.courseNewModal && <TainingNewModal refreshTrainings={this.loadData} open={this.state.courseNewModal} course={this.state.courseToSave} isCourseEdit={this.state.isCourseEdit} toggle={() => this.setState({...this.state, courseNewModal: false, isCourseEdit: false })} /> } */}
                {/* {this.state.courseShowModal && <TrainingShowModal open={this.state.courseShowModal} training={this.state.courseToShow} toggle={() => this.setState({...this.state, courseShowModal: false })} /> } */}
            </React.Fragment>

        );

    };
}
