import React, { Component, useState } from 'react';
import { Row, Col, Card, CardBody, Button, InputGroup, InputGroupAddon, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import Pagination from "react-reactstrap-pagination";
import AthleteDetailsModal from '../../TrainingPlan/AthleteDetailModal';
import { toast } from 'react-toastify';
import api from '~/services/api';

import Loader from '~/components/Loader/Loader';

export default class UserPersonal extends Component {

  constructor(props) {
    super(props);

    this.loadUsers = this.loadUsers.bind(this);

    this.state = {
      users: [],
      coachsList: [],
      loading: true,
      showDDCoach: false,
      searchQuery: '',
      selectedPage: 1,
      totalItems: 0,
      perPage: 20,
      lastPage: 1
    }

    this.loadUsers();
  }

  loadUsers = async (selectedPage) => {
    const { data } = await api.get(`users?subscription=PERSONAL&page=${selectedPage}&limit=${this.state.perPage}`);
    const coach = await api.get('user_coaches')

    this.setState({ ...this.state, users: data.data, coachsList: coach.data, loading: false, totalItems: data.total, perPage: data.perPage, lastPage: data.lastPage, selectedPage: selectedPage });
  }

  handleSetSearchQuery = (search) => {
    this.setState({ searchQuery: search })
  }

  _chooseCoach = async (user, coach) => {
    try {
      await api.post(`associate_coach?userId=${user.id}&coachId=${coach.id}`)
      toast.success('Treinador associado com sucesso');
      this.handleSearchUser();
    } catch (e) {
      toast.error('Erro ao associar treinador');
    }
  }

  handleSearchUser = async (event) => {
    if (event) event.preventDefault()

    if (this.state.searchQuery.length === 0) {
      this.loadUsers(1)
      return
    }

    this.setState({ ...this.state, loading: true });

    const { data } = await api.get(`users?subscription=PERSONAL&searchQuery=${this.state.searchQuery}`);

    this.setState({ ...this.state, users: data, loading: false });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}

        <Row>
          <Col>
            <div className="page-title-box">
              <Row>
                <Col lg={7}>
                  <h4 className="page-title">Usuários Personal</h4>
                </Col>
                <Col lg={5} className="mt-lg-3 mt-md-0">

                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card style={{ overflow: 'auto' }}>
              <CardBody style={{ minHeight: '500px' }}>
                <form onSubmit={this.handleSearchUser}>
                  <InputGroup>
                    <Input placeholder="pesquisar por email" onChange={(e) => this.setState({ searchQuery: e.target.value })} />
                    <InputGroupAddon addonType="append">
                      <Button color="success">Pesquisar</Button>
                    </InputGroupAddon>
                  </InputGroup>
                </form>
                <>
                  <table className="table table-centered table-hover mb-0" id="btn-editable">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Treinador</th>
                        <th>Criação</th>
                      </tr>

                    </thead>

                    <tbody>
                      {this.state.users.map(user => (
                        <tr id="1" key={user.id}>
                          <td><span className="tabledit-span tabledit-identifier">{user.id}</span></td>
                          <td className="tabledit-view-mode"><span className="tabledit-span"><button style={{ marginRight: 10 }} className="btn btn-link" color="primary" onClick={() => {
                            this.setState({ ...this.state, userToShow: user, userDetailsModal: true })
                          }}>{user.name}</button></span></td>

                          <td className="tabledit-view-mode"><span className="tabledit-span">{user.email}</span></td>
                          <td className="tabledit-view-mode"><span className="tabledit-span">
                            <Dropdown size="sm" isOpen={user.toggle} toggle={() => {
                              const m = this.state.users
                                .map(u => {
                                  if (u.id === user.id) {
                                    u.toggle = !u.toggle;
                                  }
                                  return u;
                                })
                              this.setState({ ...this.state, users: m })
                            }} >
                              <DropdownToggle style={{ width: 200, marginRight: 10 }}> {user.coach?.name} </DropdownToggle>
                              <DropdownMenu
                                modifiers={{
                                  setMaxHeight: {
                                    enabled: true, order: 890, fn: (data) => {
                                      return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                                    }
                                  }
                                }} >
                                {this.state.coachsList.map((elem, index) => {
                                  return (
                                    <DropdownItem
                                      key={`co_${index}`}
                                      onClick={() => this._chooseCoach(user, elem)}>{elem.name}</DropdownItem>
                                  )
                                })}
                              </DropdownMenu>
                            </Dropdown>
                          </span></td>
                          <td className="tabledit-view-mode"><span className="tabledit-span">{moment(user.created_at).format('DD/MM/YYYY')}</span></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ overflow: 'auto' }}>
            <Pagination
              firstPageText="Início"
              lastPageText="Fim"
              previousPageText="Anterior"
              nextPageText="Próximo"
              totalItems={this.state.totalItems}
              pageSize={this.state.perPage}
              defaultActivePage={1}
              onSelect={this.loadUsers}
            />
          </Col>
        </Row>

        {this.state.userDetailsModal && <AthleteDetailsModal open={this.state.userDetailsModal} justShow={true} toggle={() => this.setState({ ...this.state, userDetailsModal: false })} athlete={this.state.userToShow} />}
      </React.Fragment>

    );

  };
}
