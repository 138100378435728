import React from 'react';
import styled from 'styled-components';
import Delete from '@material-ui/icons/DeleteOutline';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import { formatTrainingIcon } from '~/services/util'
import { Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid lightgray;
  border-radius: 3px;
  margin-bottom: 3px;
  padding: 3px;
  background-color: ${props => (props.isDragging ? 'red' : (props.executed ? 'rgba(200, 255, 200, 0.4)' : 'white'))};
`

function formatTime(time) {
  var sec_num = parseInt(time, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}

export default class TaskCalendarItem extends React.Component {
  render() {
    let time = '--:--:--';
    let distance = '-- km';
    let title = '';
    let type = '';
    let isTrainingContext = true;
    let isTrainingExecuted = false;
    let enableDeleteFromDB = false;
    let trainingID = '1';
    let usrAct = {};

    if (this.props && this.props.training) {
      const { currTraining, currUsrActivity, id } = this.props.training;

      trainingID = id;
      usrAct = currUsrActivity

      if (currTraining && currTraining.id) {
        time = currTraining.duration ? formatTime(currTraining.duration * 60) : '--:--:--';
        distance = currTraining.distance ? `${currTraining.distance} km` : '-- km';
        title = currTraining.name
        type = currTraining.type

        isTrainingExecuted = currTraining.executed_status === 'EXECUTED_HIGH'
      } else if (currUsrActivity && currUsrActivity.id) {
        isTrainingContext = false
        time = currUsrActivity.duration ? formatTime(currUsrActivity.duration * 60) : '--:--:--';
        distance = currUsrActivity.distance ? `${currUsrActivity.distance} km` : '-- km';
        title = currUsrActivity.title
        type = currUsrActivity.type
        isTrainingExecuted = true
      }

      this.props.training.toDelete = true;
    }

    return (
      <Draggable draggableId={trainingID} index={this.props.index}>
        {(provided, snapshot) => (

          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            isTraining={isTrainingContext}
            executed={isTrainingExecuted}
            onClick={() => {
            }}>

            <div style={{ display: 'flex', height: 25, flexDirection: 'row', justifyContent: 'space-between' }}>
              {formatTrainingIcon(type)}
              <div>
                {(isTrainingContext || enableDeleteFromDB) &&
                  <Delete style={{ margin: 0, fontWeight: 'bold', fontSize: 20, color: 'rgba(100, 100, 100, 0.4)' }} onClick={() => this.props.removeItemAction(this.props.training, this.props.columnID)} />}

                <Visibility style={{ margin: 0, fontWeight: 'bold', fontSize: 20, color: 'rgba(100, 100, 100, 0.4)' }} onClick={() => {
                  isTrainingContext ? this.props.showItemAction(this.props.training, 'calendar', this.props.columnID, this.props.training) : this.props.showItemAction(usrAct)
                }} />
              </div>
            </div>
            <span style={{ fontSize: '11px' }}>{title}</span><br />
            <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{time}</span><br />
            <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{distance}</span><br />
          </Container>
        )}
      </Draggable>
    )
  }
}