import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import SignIn from '~/pages/SignIn';
import SignUp from '../pages/SignUp';
import TrainingPlanRequests from '../pages/TrainingPlanRequests/index';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Users from '../pages/Users/index';
import Communications from '../pages/Users/Communications';
import NewUsers from '../pages/Users/create';
import Calendar from '../pages/Calendar';
import TrainingPlan from '../pages/TrainingPlan';
import Training from '../pages/Training';
import TrainingActivity from '../pages/TrainingActivity';
import Contracts from '../pages/Contracts';
import TrainingCategory from '../pages/TrainingCategory';
import Discount from '../pages/Discount';
import Promotions from '../pages/Promotions';
import GroupFolder from '../pages/GroupFolder';
import ExchangeToken from '../pages/ExchangeToken';
import VideoAoVivoScreen from '../pages/VideoAoVivoScreen';
import Courses from '../pages/Courses';
import VideoExtraScreen from '../pages/VideoExtraScreen'
import Coupons from '../pages/Financial/Coupons'
import Products from '~/pages/Financial/Products';
import PushesScheduled from '../pages/PushesScheduled'
import TrainingPlanBeta from '~/pages/TrainingPlanBeta';
import UserPersonal from '~/pages/UserPersonal/index';
import ContentScreen from '~/pages/Content';
import ReferralAdm from '~/pages/ReferralAdm';
import RaceCalendar from '~/pages/RaceCalendar';
import BenefitsScreen from '~/pages/Benefits';
import AdvertisingScreen from '~/pages/Advertising';
import HomeBanner from '~/pages/HomeBanner';

export default function routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/register" component={SignUp} />
      <Route path="/exchange-token/:userID/:platform" exact component={ExchangeToken} />

      <Route path="/cupons" exact component={Coupons} isPrivete />
      <Route path="/produtos" exact component={Products} isPrivete />

      <Route path="/novo-treino" component={Calendar} isPrivete />
      <Route path="/calendario" component={TrainingPlan} isPrivete />
      <Route path="/calendario-beta" component={TrainingPlanBeta} isPrivete />
      <Route path="/perfil" component={Profile} isPrivete />
      <Route path="/dashboard" component={Dashboard} isPrivete />
      <Route path="/solicitacoes-plano-treino" component={TrainingPlanRequests} isPrivete />


      <Route path="/usuarios-interno" component={Users} isPrivete />
      <Route path="/usuarios-personal" component={UserPersonal} isPrivete />
      <Route path="/novo-usuario" component={NewUsers} isPrivete />
      <Route path="/usuarios-push" component={Communications} isPrivete />
      <Route path="/agenda-pushes" component={PushesScheduled} isPrivete />


      <Route path="/clientes" component={Users} isPrivete />
      <Route path="/treinos" component={Training} isPrivete />
      <Route path="/atividades-treino" component={TrainingActivity} isPrivete />
      <Route path="/planos-treino" component={Users} isPrivete />

      <Route path="/contatos" component={Contracts} isPrivete />

      <Route path="/categorias-treino" component={TrainingCategory} isPrivete />

      <Route path="/descontos" component={Discount} isPrivete />
      <Route path="/promocoes" component={Promotions} isPrivete />
      <Route path="/grupos-pastas" component={GroupFolder} isPrivete />

      <Route path="/funcionais-ao-vivo" component={VideoAoVivoScreen} isPrivete />
      <Route path="/aulas-extras" component={VideoExtraScreen} isPrivete />
      <Route path="/cursos"  component={Courses} isPrivete />

      <Route path="/recompensas-resgatadas"  component={ReferralAdm} isPrivete />
      <Route path="/conteudos-app"  component={ContentScreen} isPrivete />
      <Route path="/corridas"  component={RaceCalendar} isPrivete />
      <Route path="/beneficios"  component={BenefitsScreen} isPrivete />
      <Route path="/propaganda"  component={AdvertisingScreen} isPrivete />
      <Route path="/home-banner"  component={HomeBanner} isPrivete />

      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
