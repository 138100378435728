import styled from 'styled-components';
import ReactSlider from 'react-slider';

export const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 25px;
`;

export const StyledThumb = styled.div`
    height: 30px;
    width: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #081B39;
    color: #fff;
    font-weight: 600;
    cursor: grab;
    line-height: 100px;
    border-radius: 7px;

    @media (max-width: 667px) {
        font-size: 12px;
        height: 30px;
    }
`;

export const StyledTrack = styled.div`
    top: 13px;
    bottom: 0;
    background: ${(props) => (props.index === 1 ? '#081B39' : '#5A6268')};
    height: 5px;
    border-radius: 7px;

    ${(props) => props.index === 2 && 'border-top-right-radius: 6px; border-bottom-right-radius: 6px;'}
    ${(props) => props.index === 0 && 'border-top-left-radius: 6px; border-bottom-left-radius: 6px;'}

    @media (max-width: 667px) {
        top: 13px;
    }
`;
