import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { MdPeopleOutline, MdAttachMoney, MdFormatListBulleted, MdDashboard, MdPermContactCalendar, MdLocalShipping, MdPinDrop, MdMonetizationOn, MdShoppingCart, MdEvent } from 'react-icons/md';
import { AddToHomeScreen } from '@material-ui/icons';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import MetisMenu from 'metismenujs/dist/metismenujs';

const SideNavContent = () => {
  return <React.Fragment>
    <div id="sidebar-menu">

      <ul className="metismenu" id="side-menu">

        <li className="menu-title">Administrar Treinos</li>

        <li>
          <Link to="/solicitacoes-plano-treino" className="waves-effect">
            <MdDashboard color="gray" size={18} style={{ marginRight: 10 }} />
            <span> Principal </span>
          </Link>
        </li>
        <li>
          <Link to="/calendario" className="waves-effect">
            <MdPermContactCalendar color="gray" size={18} style={{ marginRight: 10 }} />
            <span> Calendário - antigo </span>
          </Link>
        </li>

        <li>
          <Link to="/calendario-beta" className="waves-effect">
            <MdPermContactCalendar color="gray" size={18} style={{ marginRight: 10 }} />
            <span> Calendário</span>
          </Link>
        </li>

        <li>
          <Link to="/" className="waves-effect has-dropdown" aria-expanded="true">
            <MdPeopleOutline color="gray" size={18} style={{ marginRight: 10 }} />
            {/* <span className="badge badge-success badge-pill float-right">2</span> */}
            <span> Usuários </span>
          </Link>
          <ul className="nav-second-level" aria-expanded="false">
            {/* <li>
                            <Link to="/clientes" className="side-nav-link-ref">
                                <span className="badge badge-success badge-pill float-right">2</span>
                                <span> Clientes </span>
                            </Link>
                        </li> */}
            <li>
              <Link to="/usuarios-interno" className="side-nav-link-ref">Usuários</Link>
            </li>
            <li>
              <Link to="/usuarios-personal" className="side-nav-link-ref">Personal</Link>
            </li>
            <li>
              <Link to="/agenda-pushes" className="side-nav-link-ref">Agenda de pushes</Link>
            </li>
            <li>
              <Link to="/usuarios-push" className="side-nav-link-ref">Push Notifications</Link>
            </li>
            {/* <li>
                            <Link to="/" className="has-dropdown">Contratos
                                <span className="menu-arrow"></span>
                            </Link>
                            <ul className="nav-third-level nav" aria-expanded="false">
                                <li>
                                    <Link to="/contatos" className="side-nav-link-ref">Assinaturas</Link>
                                </li>
                                <li>
                                    <Link to="/" className="side-nav-link-ref">Pagamentos</Link>
                                </li>
                                <li>
                                    <Link to="/" className="side-nav-link-ref">Pendencias</Link>
                                </li>
                            </ul>
                        </li> */}
          </ul>
        </li>

        <li>
          <Link to="/" className="waves-effect has-dropdown" aria-expanded="true">
            <MdFormatListBulleted color="gray" size={18} style={{ marginRight: 10 }} />
            <span> Conteúdo </span>
          </Link>
          <ul className="nav-second-level" aria-expanded="false">
            <li>
              <Link to="/treinos" className="side-nav-link-ref">Treinos</Link>
            </li>
            <li>
              <Link to="/cursos" className="side-nav-link-ref">Video Aulas</Link>
            </li>
            <li>
              <Link to="/funcionais-ao-vivo" className="side-nav-link-ref">Funcionais</Link>
            </li>
            <li>
              <Link to="/aulas-extras" className="side-nav-link-ref">Aulas Extras</Link>
            </li>
            <li>
              <Link to="/conteudos-app" className="side-nav-link-ref">Conteúdos do App</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/" className="waves-effect has-dropdown" aria-expanded="true">
            <MdAttachMoney color="gray" size={18} style={{ marginRight: 10 }} />
            <span>Financeiro</span>
          </Link>
          <ul className="nav-second-level" aria-expanded="false">
            <li>
              <Link to="/cupons" className="side-nav-link-ref">Cupons</Link>
            </li>
            <li>
              <Link to="/produtos" className="side-nav-link-ref">Produtos</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/dashboard" className="waves-effect">
            <MdDashboard color="gray" size={18} style={{ marginRight: 10 }} />
            <span> Dashboard </span>
          </Link>
        </li>

        <li>
          <Link to="/recompensas-resgatadas" className="waves-effect">
            <MdLocalShipping color="gray" size={18} style={{ marginRight: 10 }} />
            <span> Controle Pedidos </span>
          </Link>
        </li>

        <li>
          <Link to="/corridas" className="waves-effect">
            <MdPinDrop color="gray" size={18} style={{ marginRight: 10 }} />
            <span> Provas de Corrida </span>
          </Link>
        </li>

        <li>
          <Link to="/beneficios" className="waves-effect">
            <MdShoppingCart color="gray" size={18} style={{ marginRight: 10 }} />
            <span> Benefícios  </span>
          </Link>
        </li>

        <li>
          <Link to="/propaganda" className="waves-effect">
            <MdEvent color="gray" size={18} style={{ marginRight: 10 }} />
            <span> Propaganda  </span>
          </Link>
        </li>

        <li>
          <Link to="/home-banner" className="waves-effect">
            <AddToHomeScreen style={{ fontSize: 18, color: 'gray', marginRight: 10 }} />
            <span> Banner da Home </span>
          </Link>
        </li>


        {/* <li className="menu-title">Configurações</li>

                <li>
                    <Link to="/" className="waves-effect has-dropdown" aria-expanded="false">
                        <MdSettings color="gray" size={18} style={{marginRight:10}} />
                        <span> Configurações</span>
                        <span className="menu-arrow"></span>
                    </Link>

                    <ul className="nav-second-level nav" aria-expanded="false">
                        <li>
                            <Link to="/categorias-treino" className="side-nav-link-ref">Categorias</Link>
                        </li>
                        <li>
                            <Link to="/descontos" className="side-nav-link-ref">Descontos</Link>
                        </li>
                        <li>
                            <Link to="/promocoes" className="side-nav-link-ref">Promoções</Link>
                        </li>
                        <li>
                            <Link to="/grupos-pastas" className="side-nav-link-ref">Grupos/Pastas</Link>
                        </li>
                    </ul>
                </li> */}
      </ul>
    </div>
    <div className="clearfix"></div>
  </React.Fragment>
}


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleOtherClick = this.handleOtherClick.bind(this);
    this.initMenu = this.initMenu.bind(this);
  }

  /**
   * Bind event
   */
  componentWillMount = () => {
    document.addEventListener('mousedown', this.handleOtherClick, false);
  }


  /**
   * 
   */
  componentDidMount = () => {
    this.initMenu();
  }

  /**
   * Component did update
   */
  componentDidUpdate = (prevProps) => {
    if (this.props.isCondensed !== prevProps.isCondensed) {
      if (prevProps.isCondensed) {
        document.body.classList.remove("sidebar-enable");
        document.body.classList.remove("enlarged");
      } else {
        document.body.classList.add("sidebar-enable");
        const isSmallScreen = window.innerWidth < 768;
        if (!isSmallScreen) {
          document.body.classList.add("enlarged");
        }
      }

      this.initMenu();
    }
  }

  /**
   * Bind event
   */
  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleOtherClick, false);
  }

  /**
   * Handle the click anywhere in doc
   */
  handleOtherClick = (e) => {
    if (this.menuNodeRef.contains(e.target))
      return;
    // else hide the menubar
    document.body.classList.remove('sidebar-enable');
  }

  /**
   * Init the menu
   */
  initMenu = () => {
    // render menu
    new MetisMenu("#side-menu");
    var links = document.getElementsByClassName('side-nav-link-ref');
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      // UNCOMMENT THIS
      // if (this.props.location.pathname === links[i].pathname) {
      //     matchingMenuItem = links[i];
      //     break;
      // }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active');
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3. 
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('in');
        }
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add('active');
          var childAnchor = parent3.querySelector('.has-dropdown');
          if (childAnchor) childAnchor.classList.add('active');
        }

        const parent4 = parent3.parentElement;
        if (parent4)
          parent4.classList.add('in');
        const parent5 = parent4.parentElement;
        if (parent5)
          parent5.classList.add('active');
      }
    }
  }

  render() {
    const isCondensed = this.props.isCondensed || false;

    return (
      <React.Fragment>
        <div className='left-side-menu' ref={node => this.menuNodeRef = node}>
          {!isCondensed && <PerfectScrollbar><SideNavContent /></PerfectScrollbar>}
          {isCondensed && <SideNavContent />}
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Sidebar);
