import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import Loader from '~/components/Loader/Loader';
import { toast } from 'react-toastify'
import api from '~/services/api'

export default function NewLectureModal(props) {
    const [loading, setLoading] = useState(false)

    const [title, setTitle] = useState('')
    const [date, setDate] = useState('')
    const [hour, setHour] = useState('')
    const [vimeoLink, setVimeoLink] = useState('')
    const [audioLink, setAudioLink] = useState('')

    async function handleSaveLecture() {
        if (title === '' || date === '' || hour === '' || vimeoLink === '') {
            toast.warn('Preencha todos os campos corretamente')
            return
        }

        const data = {
            title,
            name: title,
            level: 'todos os níveis.',
            equipments: 'colchonete ou tapete.',
            record_link: vimeoLink,
            date: `${date} ${hour}`,
            obj_type: props.modalType === 'EXTRA' ? 'LIVE' : 'LECTURE',
            audio_link: props.modalType === 'EXTRA' ? audioLink : null
        }

        setLoading(true)

        try {
            await api.post('live_lectures', data)

            props.loadLiveLectures(1)

            setLoading(false)

            props.toggle()
        } catch (error) {
            setLoading(false)
            toast.error('Erro ao cadastrar nova gravação')
        }
    }

    return (
        <div>
            <Modal size="xl" isOpen={props.open} toggle={props.toggle}>
                <ModalHeader toggle={props.toggle}>Nova gravação</ModalHeader>
                <ModalBody>
                    {loading && <Loader />}
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="title">Título</Label>
                                    <Input type="text" name="title" id="title" placeholder="Título" onChange={e => setTitle(e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="vimeo-link">Link do Player Vimeo</Label>
                                    <Input type="text" name="vimeo-link" id="vimeo-link" placeholder="Link do Player Vimeo" onChange={e => setVimeoLink(e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        {props.modalType && props.modalType === 'EXTRA' &&
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="audio-link">Link do Áudio MP3 (Opcional)</Label>
                                        <Input type="text" name="audio-link" id="audio-link" placeholder="Link do Áudio MP3" onChange={e => setAudioLink(e.target.value)} />
                                    </FormGroup>
                                </Col>
                            </Row>}
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="date">Data</Label>
                                    <Input type="date" name="date" id="date" placeholder="Data" onChange={e => setDate(e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="hour">Hora</Label>
                                    <Input type="time" name="hour" id="hour" placeholder="Hora" onChange={e => setHour(e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={props.toggle}>Cancelar</Button>{' '}
                    <Button color="success" onClick={handleSaveLecture}>Salvar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}