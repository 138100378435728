import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Card, CardBody, Table, Button } from 'reactstrap';
import moment from 'moment'
import { api2 } from '~/services/api'
import Loader from '~/components/Loader/Loader';
import { toast } from 'react-toastify'
import { Container } from './styles'
import NewBenefitModal from './NewBenefitModal';

function BenefitsScreen() {
    const [loading, setLoading] = useState(false)
    const [benefits, setBenefits] = useState([])
    const [benefitModal, setBenefitModal] = useState(false)
    const [editId, setEditId] = useState(null)


    const loadBenefits = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api2.get(`benefit`)

            setBenefits(data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Erro ao carregar os benefícios')
        }
    }, [])

    const removeBenefit = async (id) => {
        let result = window.confirm("Tem certeza que deseja remover permanentemente esse benefício?");
        if(!result) return;
        api2.delete(`benefit/${id}`)
        .then(() => {
            loadBenefits();
            toast.success('Benefício removido com sucesso!');
        })
        .catch((err) => {
            console.log(err);
            toast.error('Erro ao remover o benefício')
        })
    }

    const openEditModal = (id) => {
        setEditId(id)
        setBenefitModal(true)
    }

    const closeModal = () => {
        setEditId(null)
        setBenefitModal(false)
    }

    useEffect(() => {
        loadBenefits()
    }, [loadBenefits])

    return (
        <Container>
            {benefitModal && <NewBenefitModal loadBenefits={loadBenefits} open={benefitModal} toggle={closeModal} editId={editId}/>}
            <Row>
                <Col>
                    <div className="page-title-box">
                        <Row>
                            <Col lg={7}>
                                <h4 className="page-title">Benefícios</h4>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col>
                    <Card>
                        <CardBody>
                            {loading && <Loader />}
                            <Button style={{ marginBottom: 10 }} color="success" onClick={() => setBenefitModal(true)}>Cadastrar novo benefício</Button>
                            <Row>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nome</th>
                                            <th>Cupom</th>
                                            <th>Descrição</th>
                                            <th>Link</th>
                                            <th>Validade</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {benefits.length > 0 && benefits.map((benefit, index) => (
                                            <tr key={benefit._id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{benefit.title}</td>
                                                <td>{benefit.coupon ? benefit.coupon : ''}</td>
                                                <td>{benefit.description ?? ''}</td>
                                                <td><a href={benefit.action_url}><b>Link</b></a></td>
                                                <td>{benefit.expiration_date ? moment(benefit.expiration_date).format('DD/MM/YYYY') : ''}</td>
                                                <td className="tabledit-view-mode">
                                                    <Button color="primary" onClick={() => openEditModal(benefit._id)}>Editar</Button>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <Button color="danger" onClick={() => removeBenefit(benefit._id)}>Excluir</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default BenefitsScreen;