import React from 'react';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import Loadable from 'react-loadable';

import AdmLayout from '../pages/_layouts/adm/index';
// import DefaultLayout from '../pages/_layouts/default';
import DefaultLayout from '../pages/_layouts/old_auth';

import { store } from '~/store';


// Lazy loading and code splitting - 
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>

const AuthLayout = Loadable({
  loader: () => import('../pages/_layouts/adm/index'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading
});

const NonAuthLayout = Loadable({
  loader: () => import('../pages/_layouts/nonAuth/index'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading
});

export default function RouteWrapper({
  component: Component,
  isPrivete, 
  ...rest
}) {
  const { signed } = store.getState().auth;

  if ( !signed && isPrivete ) {
    return <Redirect to="/" />
  }

  if ( signed && !isPrivete ) {
    return <Redirect to="/solicitacoes-plano-treino" />
  }

  const Layout = signed ? AuthLayout : NonAuthLayout;

  return <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
}

RouteWrapper.protoTypes = {
  isPrivete: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
  .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivete: false,
};