import React, { useState, useEffect } from 'react';

import { Row, Col, Modal, ModalBody, ModalFooter, Button, ListGroup, ListGroupItem, Media } from 'reactstrap';

import AssignmentIcon from '@material-ui/icons/Assignment';

import Loader from '~/components/Loader/Loader'

import moment from 'moment';

import api from '~/services/api';
import { formatTrainingCategory, formatDistance, formatDuration, formatTrainingIntensity, formatZone } from '~/services/util'

const ACTIVITY_TYPE_OPTIONS = {
  DURATION: { id: 'DURATION', name: "Duração", labelType: 'Min', fieldName: 'duration' },
  DISTANCE: { id: 'DISTANCE', name: "Distância", labelType: 'Km', fieldName: 'distance' },
}

function orderTrainingGroupsAndTrainingActivities(training) {
  let ordered = training

  if (ordered.trainingGroups && ordered.trainingGroups.length > 0) {
    ordered.trainingGroups = ordered.trainingGroups.sort((a, b) => {
      if (a.group_order && b.group_order && a.group_order < b.group_order) return -1

      return 0
    })

    for (let index = 0; index < ordered.trainingGroups.length; index++) {
      if (ordered.trainingGroups[index].trainingActivities && ordered.trainingGroups[index].trainingActivities.length > 0) {
        ordered.trainingGroups[index].trainingActivities = ordered.trainingGroups[index].trainingActivities.sort((a, b) => {
          if (a.group_order && b.group_order && a.group_order < b.group_order) return -1

          return 0
        })
      }
    }
  }

  return ordered
}

export default function TrainingNewModal(props) {
  // WeightTraining, Workout, Run, Ride, Swim, Drills, Cardio, Others
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(props.open);
  const [training, setTraining] = useState({});
  const [userActivity, setUserActivity] = useState({});
  const [currentFolder, setCurrentFolder] = useState({ name: 'Selecionar', id: -1 });
  const [currentActivityType, setCurrentActivityType] = useState({ name: 'Selecionar', id: -1 });

  const [trainingType, setTrainingType] = useState(''); // NONE, EXPLORE, RUN, USR_ACT

  useEffect(() => {
    let trainingID = props.training.id
    let trainingType = null;
    if (props.training && props.training.obj_type === 'USR_ACT') {

      setTrainingType('USR_ACT')
      trainingType = 'USR_ACT'
      setUserActivity(props.training);

      if (props.training.training_id) {
        trainingID = props.training.training_id
      } else {
        setLoading(false);
      }
    }

    if (trainingID) {
      Promise.all([api.get(`trainings/${trainingID}`)])
        .then(([trainingsResp]) => {
          const train = orderTrainingGroupsAndTrainingActivities(trainingsResp.data)
          setTraining(train);

          if (train) {
            if (train.folder) {
              setCurrentFolder(train.folder);
            }

            if (train.activity_type === ACTIVITY_TYPE_OPTIONS.DURATION.id) {
              setCurrentActivityType(ACTIVITY_TYPE_OPTIONS.DURATION);
            } else {
              setCurrentActivityType(ACTIVITY_TYPE_OPTIONS.DISTANCE);
            }

            formatActivityType(train.type, trainingType)
          }

          setLoading(false);
        });
    }

  }, [props.training])


  const formatActivityType = (actType, trainingType = null) => {

    if (trainingType) {
      setTrainingType(trainingType)
      return;
    }

    if (actType === 'WeightTraining' || actType === 'Drills' || actType === 'Cardio' || actType === 'Others') {
      setTrainingType('EXPLORE')
    } else if (actType === 'Run') {
      setTrainingType('RUN')
    }
  }
  const style = {
    trainingValue: { fontWeight: 'bold', fontSize: 15, marginLeft: 10 }
  }

  const formatTrainingActSpeed = (intensity) => {
    switch (intensity) {
      case 1:
        return 'Lento'

      case 2:
        return 'Moderado'

      case 3:
        return 'Acelerado'
      default:
        return '-'
    }
  }

  function drawActivityInfos(act, groupItem, actIdx) {
    const zoneFormated = formatZone(act.zone);

    let description = ''

    if (groupItem.repetition && groupItem.repetition > 1) {

      if (currentActivityType.id === 'DISTANCE') {
        description = `${act.distance} km @ ${zoneFormated}`
      } if (currentActivityType.id === 'DURATION') {
        description = `${act.duration} min @ ${zoneFormated}`
      }
    } else {
      if (currentActivityType.id === 'DISTANCE') {
        description = `${act.distance} km @ ${zoneFormated}`
      } if (currentActivityType.id === 'DURATION') {
        description = `${act.duration} min @ ${zoneFormated}`
      }
    }

    return (
      <div>
        {groupItem.repetition && groupItem.repetition > 1 ?
          <div>
            <div style={{ marginLeft: 20, marginTop: 5, alignSelf: 'flex-start', flexDirection: 'row', }} >
              <span style={{ fontWeight: '500' }}>{actIdx + 1}. </span>
              <span style={{ fontWeight: '500' }}>{act.name}</span>
            </div>
            <div>
              <span style={{ color: 'black', marginLeft: 40 }}>{description}</span>
              {/* <span style={{ color: 'gray', fontSize: 12, marginLeft: 40 }}>{`${formatUserZone(act.zone)}`}</span> */}
            </div>
          </div>
          :
          <div>
            <div style={{ marginLeft: 20, marginTop: 5, alignSelf: 'flex-start', flexDirection: 'row' }} >
              <span style={{ fontWeight: '500' }}>{actIdx + 1}. </span>
              <span style={{ color: 'black', marginLeft: 5 }}>{description}</span>
              <div>
                {/* <span style={{ color: 'gray', fontSize: 12, marginLeft: 5 }}>{`${formatUserZone(act.zone)}`}</span> */}
              </div>
            </div>
          </div>}
      </div>
    )
  }

  const handleRemoveTraining = async () => {
    await props.removeItemAction(props.trainingToRemove, props.trainingColumn)
    props.toggle(!show)
  }

  const handleEditTraining = async () => {
    props.toggle(!show)
    if (props.openTrainingEdit) {
      props.openTrainingEdit(training)
    }
  }

  return (
    <Modal style={{ maxWidth: '2600px', width: '95%', maxHeight: '1600px', height: '95%' }} isOpen={show} toggle={() => props.toggle(!show)}>
      {loading && <Loader />}
      {!loading && <>
        <ModalBody>
          <Row>
            <Col lg={5}>
              <Row>
                <Col lg={7}>
                  {trainingType === 'USR_ACT' ?
                    <>
                      <span>Nome:</span><span style={style.trainingValue}>{userActivity.title} (#{userActivity.id})</span><br />

                      <span>Via:</span><span style={style.trainingValue}>{userActivity.platform_type === 'EXPLORER_TRAINING' ? 'APP Explorar' : userActivity.platform_type}</span><br />
                      <span>Tipo:</span><span style={style.trainingValue}>{formatTrainingCategory(userActivity.type)}</span><br />

                      <span>Distância:</span><span style={style.trainingValue}>{userActivity.distance}</span><br />
                      <span>Duração:</span><span style={style.trainingValue}>{userActivity.duration ? formatDuration(userActivity.duration, 'time') : ''}</span><br />
                      <span>Pace:</span><span style={style.trainingValue}>{userActivity.pace ? formatDuration(userActivity.pace, 'pace') : ''}</span><br />
                      <span>FC:</span><span style={style.trainingValue}>{userActivity.heart_rate}</span><br />
                      <span>FC Méd:</span><span style={style.trainingValue}>{userActivity.average_heartrate}</span><br />
                      <span>FC Máx:</span><span style={style.trainingValue}>{userActivity.max_heartrate}</span><br />
                      <span>Elevação:</span><span style={style.trainingValue}>{userActivity.altimetry}</span><br />
                      <span>Calorias:</span><span style={style.trainingValue}>{userActivity.calorie}</span><br />
                      <span>Data:</span><span style={style.trainingValue}>{moment(userActivity.start_date).format('DD/MM/YYYY')}</span><br />
                      <span>Vel. Máx:</span><span style={style.trainingValue}>{userActivity.max_speed}</span><br />
                      <span>Vel. Méd:</span><span style={style.trainingValue}>{userActivity.average_speed}</span><br />
                      <span>Cadencia Méd:</span><span style={style.trainingValue}>{userActivity.average_cadence}</span><br /><br />
                      <span>Observações:</span><br /><span style={style.trainingValue}>{userActivity.comments}</span><br /><br />

                      {training && training.id &&
                        <>
                          <span>Treino Associado:</span><span style={style.trainingValue}>{training.name} (#{training.id})</span><br />
                        </>}
                    </>
                    :
                    <>
                      <span>ID:</span><span style={style.trainingValue}>{training.id}</span><br />
                      <span>Nome:</span><span style={style.trainingValue}>{training.name}</span><br />
                      <span>Nome Interno:</span><span style={style.trainingValue}>{training.internal_name}</span><br /><br />
                      <span>Tipo:</span><span style={style.trainingValue}>{formatTrainingCategory(training.type)}</span><br />
                    </>}


                  {trainingType === 'RUN' && currentActivityType.id === 'DURATION' &&
                    <>
                      <span>Duração (min):</span><span style={style.trainingValue}>{training.duration ? formatDuration(training.duration, 'time') : ''}</span><br />
                    </>}
                  {trainingType === 'RUN' && currentActivityType.id === 'DISTANCE' &&
                    <>
                      <span>Distância (km):</span><span style={style.trainingValue}>{training.distance}</span><br />
                    </>}

                  {trainingType === 'EXPLORE' &&
                    <Row>
                      <Col lg={12}>
                        <Row>
                          <Col lg={12}>
                            <span>Intencidade:</span><span style={style.trainingValue}>{formatTrainingIntensity(training.intensity)}</span><br />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <span>Estimativa início:</span><span style={style.trainingValue}>{training.estimated_init}</span><br />
                          </Col>
                          <Col lg={6}>
                            <span>Estimativa fim:</span><span style={style.trainingValue}>{training.estimated_end}</span><br />
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={12}>
                            <span>Equipamentos:</span><span style={style.trainingValue}>{training.equipments}</span><br />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <span style={{ marginTop: 30 }}>Preview da Imagem de capa do treino:</span> <br /><br />
                            {training.cover_link && <img src={training.cover_link} alt="" height="100" />}
                            {!training.cover_link && <div style={{ width: 200, height: 100, backgroundColor: '#e3e3e3', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <p>Nenhuma cadastrada</p>
                            </div>}
                          </Col>
                        </Row>

                      </Col>
                    </Row>}
                </Col>
                <Col lg={5}>
                  {trainingType !== 'USR_ACT' &&
                    <><Button color="danger" onClick={() => handleRemoveTraining()}>Remover treino</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button color="primary" onClick={() => handleEditTraining()}>Editar</Button><br /><br /></>}
                  <span>Descrição:</span><br /><span style={style.trainingValue}>{training.description}</span><br />
                </Col>
              </Row>
            </Col>

            <Col lg={7} style={{ backgroundColor: 'white', padding: 5 }}>
              <Row style={{ marginBottom: 15 }}>
                <Col lg={3}>
                  <h4>Fases do Treino</h4>
                </Col>
              </Row>

              <div style={{ overflowY: 'scroll', borderLeft: '1px solid gray', paddingLeft: '10px', paddingRight: '5px', width: '100%', float: 'left', height: '600px', position: 'relative' }}>
                {trainingType === 'RUN' && training && training.trainingGroups &&
                  <Col lg={4}>
                    {training && training.trainingGroups.map((item, tgIDX) => {
                      return (
                        <div style={{ alignItems: 'center', marginTop: 10 }}>
                          <div style={{ alignItems: 'flex-start', width: '80%' }}>
                            <div>
                              <div style={{ flexDirection: 'row' }}>
                                <span>{tgIDX + 1}. </span>
                                <span style={{ fontWeight: '500', fontSize: 15 }}>
                                  {item.repetition > 1 ? `${item.name} | Repetir ${item.repetition} vezes` : `${item.name}`}
                                </span>
                              </div>
                              {item.trainingActivities.map((act, aIdx) => {
                                return drawActivityInfos(act, item, aIdx);
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Col>}

                {trainingType === 'EXPLORE' && training && training.trainingGroups && training.trainingGroups.map((tgroup, gidx) => {
                  return (
                    <Media list key={`key_${gidx}`}>
                      <Media tag="li">
                        <Media left>
                          <AssignmentIcon />
                        </Media>
                        <Media body>
                          <Media heading>
                            <p>{tgroup.name}</p>
                          </Media>


                          <ListGroup>
                            {tgroup.trainingActivities.map((tact, aidx) => {

                              const otherDesc = training && training.id && training.type === 'Run' ? tact.zone : formatTrainingActSpeed(tact.execution_speed)

                              return (
                                <ListGroupItem key={`id_${aidx}`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    {tact.thumb_link ?
                                      <img src={tact.thumb_link} alt="" height="40" />
                                      :
                                      <div style={{ height: 40, width: 40, backgroundColor: 'gray' }} />}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <span style={{ marginLeft: 10 }}>{tact.name} (#{tact.id})</span>
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {tact.activity_type === 'DISTANCE' && <span style={{ marginLeft: 10 }}>{formatDistance(tact.distance)}</span>}
                                        {tact.activity_type === 'DURATION' && <span style={{ marginLeft: 10 }}>{formatDuration(tact.duration)}</span>}
                                        {tact.activity_type === 'REPETITION' && <span style={{ marginLeft: 10 }}>{tact.repetition}x</span>}
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <span>{otherDesc}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <img src={tact.media_link} alt="" height="40" />
                                </ListGroupItem>
                              )
                            })}

                          </ListGroup>
                        </Media>
                      </Media>
                    </Media>
                  )
                })}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => props.toggle(!show)}>Fechar</Button>
        </ModalFooter>
      </>}
    </Modal>
  );
}
