import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, Button, ModalHeader, Table } from 'reactstrap';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '~/services/api';
import { formatDuration, formatTrainingCategory } from '~/services/util';
import { toast } from 'react-toastify';
import Loader from '~/components/Loader/Loader';
import Pagination from "react-reactstrap-pagination";

export default function NewUser(props) {
  const [userActivities, setUserActivities] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const loadUserActivities = useCallback(async (selectedPage) => {
    setLoading(true)

    try {
      const response = await api.get(`user_activities?user_id=${props?.user?.id}&page=${selectedPage}&limit=10`)

      setUserActivities(response?.data?.data)
      setTotalItems(response?.data?.total)
      setSelectedPage(response?.data?.page)
      setLastPage(response?.data?.lastPage)
      setLoading(false)
    } catch (error) {
      setLoading(false)

      toast.error('Erro ao carregar as atividades, tente novamente')
    }
  }, [props])

  useEffect(() => {
    loadUserActivities(1)
  }, [loadUserActivities])

  return (
    <Modal style={{ maxWidth: '2600px', width: '95%', maxHeight: '1600px', height: '95%' }} isOpen toggle={() => props.toggle(false)}>
      <ModalHeader>{props?.user?.name || ''}</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={12}>
            <p>Atividades do usuário</p>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Titulo</th>
                  <th>Tipo</th>
                  <th>Distância</th>
                  <th>Ritmo</th>
                  <th>Tempo</th>
                  <th>Vel. Média</th>
                  <th>Vel. Máxima</th>
                  <th>Cadência Média</th>
                  <th>FC. Média</th>
                  <th>FC. Máxima</th>
                  <th>Ganho de Elevação</th>
                  <th>Calorias</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>
                {loading && <Loader />}

                {
                  userActivities.map((userAct, aidx) => (
                    <tr>
                      <th scope="row">{userAct?.id || '-'}</th>
                      <td>{userAct?.title || '-'}</td>
                      <td>{userAct?.type ? formatTrainingCategory(userAct?.type) : '-'}</td>
                      <td>{userAct?.distance ? `${userAct?.distance?.toFixed(2)} km` : '-'}</td>
                      <td>{userAct?.pace ? `${formatDuration(userAct?.pace, 'pace')} /km` : '-'}</td>
                      <td>{userAct?.duration ? `${formatDuration(userAct?.duration, 'time')}` : '--:--:--'}</td>
                      <td>{userAct?.average_speed ? `${userAct?.average_speed?.toFixed(1)} km/h` : '-'}</td>
                      <td>{userAct?.max_speed ? `${userAct?.max_speed?.toFixed(1)} km/h` : '-'}</td>
                      <td>{userAct?.average_cadence ? userAct?.average_cadence?.toFixed(0) : '-'}</td>
                      <td>{userAct?.average_heartrate ? `${userAct?.average_heartrate?.toFixed(0)} bpm` : '-'}</td>
                      <td>{userAct?.max_heartrate ? `${userAct?.max_heartrate?.toFixed(0)} bpm` : '-'}</td>
                      <td>{userAct?.altimetry ? `${userAct?.altimetry?.toFixed(0)} m` : '-'}</td>
                      <td>{userAct?.calorie ? `${userAct?.calorie?.toFixed(0)} kcal` : '-'}</td>
                      <td>{userAct?.start_date ? format(new Date(userAct?.start_date), "dd/MM/yyyy HH:mm", { locale: pt }) : '-'}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row style={{ overflowX: 'auto' }}>
          <Col>
            <Pagination
              firstPageText="Início"
              lastPageText="Fim"
              previousPageText="Anterior"
              nextPageText="Próximo"
              totalItems={totalItems}
              pageSize={10}
              defaultActivePage={selectedPage}
              onSelect={loadUserActivities}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => props?.toggle(false)}>Fechar</Button>
      </ModalFooter>
    </Modal>
  );
}
