import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';

import Loader from '~/components/Loader/Loader';

import { Container } from './styles';

export default function Discount() {

  const loading = useSelector(state => state.auth.loading);
  // api.get('users')


  return (
    <React.Fragment>
            { /* preloader */}
            {loading && <Loader />}

            <Row>
                <Col>
                    <div className="page-title-box">
                        <Row>
                            <Col lg={7}>
                                <h4 className="page-title">Discount</h4>
                            </Col>
                            <Col lg={5} className="mt-lg-3 mt-md-0">
                                
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            Discount content
                        </CardBody>
                    </Card>
                </Col>
            </Row>
    </React.Fragment>
  );
}
